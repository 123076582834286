import { faArrowDown, faArrowUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import moment from "moment";
import { getBlockchainTransactionUrl } from "../../config";

const OpenHistoryCard = ({ item, setOpenTpSLModal }) => {
  const appCtx = useSelector((state) => state.app);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  return (
    <tr
      style={{ cursor: "pointer" }}
      onClick={(e) => {
        if (!e.target.classList.contains("view-button")) {
          navigate(`/token/${item?.tokenId}/${item?.token}`);
        }
      }}
    >
      <td>{moment(item.createdDate).format("D MMM YYYY")}</td>
      <td>{item?.token}</td>
      <td>{item?.action}</td>
      <td>{item?.tokenQuantity}</td>
      <td>{item?.tokenPrice}</td>
      {/* <td
        className=""
        onClick={() => {
          setOpenTpSLModal(true);
        }}
        style={{ cursor: "pointer" }}
      >
        <span className="view-button">View</span>
      </td> */}
      <td>{item?.transactionStatus}</td>
      <td className="py-1">
        {item?.blockchainTransactionId ? (
          <div
            style={{ cursor: "pointer" }}
            onClick={() => {
              const blockchain = appCtx?.blockchain;
              const transactionHash = item?.blockchainTransactionId;
              const blockchainLink = getBlockchainTransactionUrl(
                blockchain,
                transactionHash
              );

              if (blockchainLink !== "") {
                window.open(blockchainLink);
              }
            }}
            className="text-primary py-2"
          >
            {`${item?.blockchainTransactionId?.substring(0, 20)}...`}
          </div>
        ) : (
          <p className="py-2 mb-0">--</p>
        )}
      </td>
    </tr>
  );
};

export default OpenHistoryCard;
