import React, { useEffect, useState } from "react";
import { useAirdrop } from "./useAirdrop";
import { CircularProgress, Skeleton } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import AddAirdropModal from "./AddAirdropModal";
import AirdropModal from "./AirdropModal";
import { useSelector } from "react-redux";
import { Card } from "react-bootstrap";

const AirdropComponent = () => {
  const [openAirdropModal, setopenAirdropModal] = useState(false);
  const [success, setSuccess] = useState(false);
  const [closeClicked, setCloseClicked] = useState(false);
  const appCtx = useSelector((state) => state.app);
  const [openModal, setOpenModal] = useState(false);
  const navigate = useNavigate();
  const {
    subscribed,
    subscribeLoading,
    airDropLoading,
    airDropList,
    handleAirdropSubscribe,
    getAirDropList,

    openAddAirdropModal,
    setOpenAddAirdropModal,
    getSubscriberCount,
    subscriberCount,
    isSubscribed,
    airdropSubscribed,
    subloading,
  } = useAirdrop();

  useEffect(() => {
    getAirDropList();
    getSubscriberCount();
    isSubscribed();
  }, [closeClicked]);

  return (
    <div className="container-fluid">
      <div className="flex flex-col justify-between min-h-screen h-fit text-gray-800 home">
        <div className="w-[100%] flex flex-col justify-center">
          <div className=" w-[100%] ">
            <div className="">
              {/* banner section  */}
              {!appCtx.isLoggedIn && (
                <div
                  className="d-flex flex-column flex-md-row justify-content-between align-items-center "
                  style={{ width: "90%", margin: "0 auto" }}
                >
                  <div className="w-100 w-md-46">
                    <h1 className="h2 h1-md fw-bold text-primary">Airdrops</h1>
                    <div className="py-5">
                      <h5 className="mb-3 fw-normal">
                        Your one stop solution to finding available airdrop
                        options
                      </h5>
                      <h5 className="fw-normal">
                        We aggregate the airdrops options and bring you trusted
                        opportunities to earn free crypto tokens.
                      </h5>
                    </div>
                    <div className="d-flex flex-row gap-2 gap-md-3">
                      <div>
                        <button
                          onClick={() => setopenAirdropModal(true)}
                          disabled={airdropSubscribed}
                          className="btn px-5 py-3 fw-semibold bg-primary text-white rounded-lg"
                        >
                          {subloading ? (
                            <CircularProgress size={20} className="mx-5" />
                          ) : airdropSubscribed ? (
                            "Subscribed"
                          ) : (
                            "Subscribe Now"
                          )}
                        </button>
                        <p className="text-primary mt-2">
                          To get email for new airdrop
                        </p>
                      </div>
                      <div>
                        <button
                          onClick={() => navigate("/login")}
                          className="btn px-5 py-3 fw-semibold bg-light text-primary rounded-lg"
                        >
                          Signup Now
                        </button>
                        <p className="text-primary mt-2">
                          To find the airdrop instructions
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="w-100 w-md-46 mt-4 mt-md-0 d-flex justify-content-center">
                    <img
                      src="/images/airdropsideimage.png"
                      alt=""
                      className="w-50"
                    />
                  </div>
                </div>
              )}
            </div>

            {appCtx.isLoggedIn && (
              <div className="card">
                <div className="">
                  <h1 className="font-bold text-center">Air Drop</h1>
                  <h5 className="font-bold text-center text-slate-500">
                    Discover popular airdrops from top projects
                  </h5>
                </div>
                <div className="d-flex w-100 justify-content-between px-2 pb-4">
                  <div>
                    {appCtx.isLoggedIn && (
                      <button
                        onClick={() => {
                          setOpenAddAirdropModal(true);
                        }}
                        className="btn btn-primary"
                      >
                        Add Airdrop
                      </button>
                    )}
                  </div>

                  <div className="d-flex flex-column justify-content-center align-items-center gap-3">
                    <p className="text-lg mb-0">
                      <span className="font-weight-bold">
                        Subscriber Count:{" "}
                      </span>
                      {subscriberCount}
                    </p>
                  </div>
                </div>
              </div>
            )}

            {/* airdrop card  */}
            <h1 className="text-xl md-text-4xl font-weight-bold text-center mt-3 text-purple">
              Live Airdrops
            </h1>
            <div className="row">
              {airDropLoading ? (
                <div
                  className="d-flex justify-content-center align-items-center"
                  style={{ minHeight: "200px" }}
                >
                  <CircularProgress
                    style={{
                      fontSize: "10px",
                      width: "25px",
                      height: "25px",
                    }}
                    className="py-10"
                  />
                </div>
              ) : (
                airDropList &&
                airDropList?.map((item) => (
                  <div class="col-xl-3  col-sm-12 ">
                    <div class="card">
                      <div
                        class="card-body m-0 p-0 rounded"
                        style={{ overflow: "hidden" }}
                      >
                        <Link
                          to={`/airdrop/${item?.airdropId}`}
                          key={item?.airdropId}
                        >
                          <img
                            src={item.icon || "/images/airdrop.png"}
                            className="w-100"
                          />
                          <div className="p-2">
                            <h6 className="font-weight-bold mt-2">
                              Title:{" "}
                              <span className="font-w400">
                                {" "}
                                {item?.project}
                              </span>
                            </h6>{" "}
                            <h6 className="font-weight-bold">
                              Token:{" "}
                              <span className="font-w400">
                                {item?.tokenName}
                              </span>
                            </h6>{" "}
                            <h6 className="font-weight-bold">
                              Total Airdrop:
                              <span className="font-w400">
                                {" "}
                                {item?.totalAirdrop}
                              </span>
                            </h6>{" "}
                          </div>
                        </Link>
                      </div>
                    </div>
                  </div>
                ))
              )}
            </div>
          </div>
        </div>
      </div>
      {openAddAirdropModal && (
        <AddAirdropModal
          openModal={openAddAirdropModal}
          setOpenModal={setOpenAddAirdropModal}
        />
      )}
      <AirdropModal
        openModal={openAirdropModal}
        setOpenModal={setopenAirdropModal}
        success={success}
        setSuccess={setSuccess}
        closeClicked={closeClicked}
        setCloseClicked={setCloseClicked}
      />
    </div>
  );
};

export default AirdropComponent;
