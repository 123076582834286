import React, { useEffect } from "react";
import "./App.css";
import "@fontsource/poppins";
import "react-toastify/dist/ReactToastify.css";
import { BrowserRouter as Router } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { RouterComponent } from "./component/common/RouterComponent";
import "./css/style.css";

export default function App() {
  return (
    <>
      <Router>
        <RouterComponent />
      </Router>
    </>
  );
}
