import React, { useState } from "react";
import { useEffect } from "react";
import Modal from "react-modal";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import axios from "axios";
import { toast } from "react-toastify";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck, faEnvelope } from "@fortawesome/free-solid-svg-icons";
function LensModal({
  openModal,
  setOpenModal,
  success,
  setSuccess,
  closeClicked,
  setCloseClicked,
}) {
  const [openFinalModal, setopenFinalModal] = useState(false);
  const [continued, setContinued] = useState(false);

  const [enteredInput, setEnteredInput] = useState(false);
  const [selectedOption, setSelectedOption] = useState("email");
  const appCtx = useSelector((state) => state.app);
  const [inputValue, setInputValue] = useState("");

  const [copyId, setCopyId] = useState(false);
  const [subloading, setSubloading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const width = window.screen.width;
  const customStyles = {
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.7)",
      zIndex: 9998,
    },
    content: {
      top: "53%",
      backgroundColor: `${appCtx?.isDarkMode ? "#09153F" : "#FFFFFF"}`,
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      padding: "0",
      borderRadius: "10px",
      transform: "translate(-50%, -50%)",
      overflow: "hidden",
    },
  };
  const themedColor = appCtx?.isDarkMode ? "white" : "black";
  function closeModal() {
    setOpenModal(false);
    setContinued(false);
    setSelectedOption("");
    setInputValue("");
    setSuccess(false);
  }
  const copyToClipboard = () => {
    setCopyId(true);
    navigator.clipboard.writeText(appCtx.walletAddress[0]?.address);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    //call api here
    // if api call success
    handleSubscribe();

    console.log(`Submitted value: ${inputValue}`);
    // do something with the submitted value
  };
  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  useEffect(() => {
    if (selectedOption !== "whatsapp" && appCtx?.userDetails?.email) {
      setInputValue(appCtx?.userDetails?.email);
    }
  });

  const handleSubscribe = () => {
    setSubloading(true);
    let data = JSON.stringify({
      email: inputValue,
    });

    let config = {
      method: "post",
      url: `${process.env.REACT_APP_URL_MARKETPLACE_SERVICE}/crypto/lens/subscribe`,
      headers: {
        "Content-Type": "application/json",
        ...(appCtx.authToken
          ? { "X-Auth-Token": appCtx.authToken }
          : { "X-App-Token": process.env.REACT_APP_X_APP_TOKEN }),
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        if (response.status === 200) {
          setSuccess(true);
          setContinued(true);
          setSubloading(false);
        }
      })
      .catch((error) => {
        toast.error("failed to subscribe");
        setSubloading(false);
      });
  };

  return (
    <div>
      <Modal
        isOpen={openModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
        ariaHideApp={false}
      >
        <div className="">
          <div className="position-relative d-flex justify-content-end">
            <div
              onClick={closeModal}
              className="btn btn-link"
              style={{ cursor: "pointer" }}
            >
              <i className="fa fa-times" style={{ fontSize: "20px" }}></i>
            </div>
          </div>

          <div className="p-5">
            <div className="w-100 d-flex justify-content-center ">
              {success ? (
                <FontAwesomeIcon
                  icon={faCircleCheck}
                  style={{
                    color: themedColor,
                    fontSize: "80px",
                  }}
                />
              ) : (
                <img
                  src="/images/notificationbell.svg"
                  alt=""
                  className=""
                  style={{ width: "200px" }}
                />
              )}
            </div>
            {!selectedOption && !continued && (
              <div className="r">
                <h4 className="text-center mt-3">Get your notifications</h4>
                <p className="text-center my-3">
                  Stay Connected, Stay Informed and Never Miss a Notification
                </p>
                <div className="d-flex  justify-content-center  gap-3 ">
                  <button
                    onClick={() => {
                      setSelectedOption("whatsapp");
                    }}
                    className="btn btn-success"
                  >
                    Whatsapp
                  </button>
                  <button
                    onClick={() => {
                      setSelectedOption("email");
                    }}
                    className="btn btn-primary "
                  >
                    Email
                  </button>
                </div>
              </div>
            )}
            {!continued && selectedOption ? (
              <form
                onSubmit={handleSubmit}
                className=""
              >
                <h3 className="mt-5">
                  {selectedOption === "whatsapp"
                    ? "WhatsApp number"
                    : "Email ID"}
                </h3>
                <div>
                  <div className="input-group">
                    <input
                      className="form-control border rounded ps-3"
                      type="text"
                      placeholder={`${
                        selectedOption === "whatsapp"
                          ? "Enter your WhatsApp no"
                          : `${
                              appCtx?.userDetails?.email &&
                              selectedOption !== "whatsapp"
                                ? ""
                                : "Enter your Email"
                            }`
                      }  `}
                      defaultValue={
                        selectedOption !== "whatsapp" ? inputValue : ""
                      }
                      onChange={(e) => setInputValue(e.target.value)}
                    />
                    <span className="input-group-text">
                      {selectedOption === "whatsapp" ? (
                        <FontAwesomeIcon
                          icon={faWhatsapp}
                          style={{
                            color: themedColor,
                          }}
                        />
                      ) : (
                        <FontAwesomeIcon
                          icon={faEnvelope}
                          style={{
                            color: themedColor,
                          }}
                        />
                      )}
                    </span>
                  </div>
                </div>

                <div className="d-flex w-100 justify-content-center align-items-center mt-3 ">
                  <button
                    type="submit"
                    className="btn btn-primary d-flex gap-2 justify-content-center align-items-center"
                  >
                    Continue
                    {subloading && (
                      <CircularProgress size={20} className="ml-[5px]" />
                    )}
                  </button>
                </div>
              </form>
            ) : (
              ""
            )}
            {continued && success ? (
              <div className="flex flex-col gap-3 justify-center items-center">
                <h3 className="mt-5">Subscription successful!</h3>
                <div className="d-flex justify-content-center w-100">
                  <button
                    onClick={() => {
                      closeModal();
                      setCloseClicked(!closeClicked);
                    }}
                    className="btn btn-primary"
                  >
                    Close
                  </button>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default LensModal;
