import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { appActions } from "../../context/app-slice";
import { useLocation } from "react-router-dom";
import CryptoFundLineChart from "../cryptoFund/CryptoFundLineChart";
import { getPriceInDecimal } from "../../config";

const TokenTablesCard = ({ token }) => {
  const appCtx = useSelector((state) => state.app);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  let price = token?.usdPrice;
  let tokenprice = getPriceInDecimal(price);

  return (
    <tr
      style={{ cursor: "pointer", height: "60px !important", padding: "0px" }}
      onClick={() => {
        navigate(`/token/${token?.tokenId}/${token?.symbol}`);
        dispatch(
          appActions.setSingleTokenData({
            tokenName: token.name,
            tokenSymbol: token?.symbol,
            priceOfToken: token?.usdPrice,
          })
        );
      }}
    >
      <td>
        <img
          src={token?.icon}
          alt=""
          style={{ width: "25px", height: "25px" }}
          className="rounded-circle"
        />
      </td>
      <td>{token?.name}</td>
      <td>{tokenprice}</td>
      <td>{parseInt(token?.usdMarketcap)}</td>
      <td>{parseInt(token?.usdVolume24h)}</td>
      <td className="d-flex justify-content-end">
        <div className="" style={{ maxWidth: "250px" }}>
          <CryptoFundLineChart prices={token?.prices ? token?.prices : []} />
        </div>
      </td>
    </tr>
  );
};

export default TokenTablesCard;
