import { Grid, Skeleton } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  getBlockchainAddressUrl,
  getBlockchainTransactionUrl,
} from "../../config";

export const Transaction = ({ tokoenname }) => {
  const [transaction, setTransaction] = useState([]);
  const [newTransaction, setNewTransaction] = useState([]);
  const appCtx = useSelector((state) => state.app);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const tranInfo = async () => {
    setLoading(true);
    const address = appCtx.walletAddress.find(
      (addr) => addr.blockchain === appCtx.blockchain
    )?.address;

    let config = {
      method: "get",
      url: `${
        process.env.REACT_APP_URL_BLOCKCHAIN_SERVICE
      }/address/${address}/transaction/history?blockchain=${appCtx.blockchain}${
        tokoenname ? `&token=${tokoenname}` : ""
      }`,
      headers: {
        "X-Auth-Token": appCtx.authToken,
      },
    };

    axios
      .request(config)
      .then((response) => {
        console.log();
        setTransaction(response.data.content || []);
        setNewTransaction(response.data.content?.slice(0, 10) || []);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setTransaction([]);
        setLoading(false);
      });
  };
  useEffect(() => {
    tranInfo();
  }, []);
  const handleChange = (event, value) => {
    setPage(value);
    let lastIndex = 0;
    let firstIndex = 0 + (value * lastIndex || 0);
    lastIndex = value * 10;
    firstIndex = lastIndex - 10;
    setNewTransaction(transaction?.slice(firstIndex, lastIndex));
  };
  return (
    <>
      <div className="d-flex flex-column justify-content-center pb-5">
        <div className="flex flex-col justify-center pb-5">
          <div className="">
            <h5 className="text-center mt-4 mb-2"> Transaction History</h5>
            {loading ? (
              <Grid container spacing={2}>
                {[...Array(4)].map((_, index) => (
                  <Grid item xs={12} key={index}>
                    <Skeleton variant="rectangular" width="100%" height={200} />
                  </Grid>
                ))}
              </Grid>
            ) : newTransaction?.length === 0 ? (
              <p className="text-center">No Transaction Found</p>
            ) : (
              <div>
                {newTransaction?.map((tran, index) => (
                  <div key={index} className="my-2">
                    <div
                      style={{
                        backgroundColor: `${
                          appCtx?.isDarkMode ? "#18254F" : "white"
                        }`,
                      }}
                      className="shadow p-4  rounded"
                    >
                      <div className="row">
                        <div className="col-12 col-md-6">
                          <div className="d-flex flex-row align-items-center gap-2">
                            <p className="mb-0 font-w600">TransactionId</p>
                            <div className="font-medium">
                              {tran?.blockchainTransactionId ? (
                                <div
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    const blockchain = appCtx?.blockchain;
                                    const transactionId =
                                      tran?.blockchainTransactionId;
                                    console.log("blockchain", blockchain);
                                    console.log("transactionId", transactionId);
                                    const blockchainLink =
                                      getBlockchainTransactionUrl(
                                        blockchain,
                                        transactionId
                                      );
                                    if (blockchainLink !== "") {
                                      window.open(blockchainLink);
                                    }
                                  }}
                                  className="text-primary"
                                >
                                  {`${tran?.blockchainTransactionId?.substring(
                                    0,
                                    20
                                  )}...`}
                                </div>
                              ) : (
                                "--"
                              )}
                            </div>
                          </div>
                          <div className="d-flex flex-row align-items-center gap-2">
                            <p className="mb-0 font-w600">round-time</p>
                            <div className="font-medium">
                              {tran["round-time"]}
                            </div>
                          </div>
                          <div className="d-flex flex-row align-items-center gap-2">
                            <p className="mb-0 font-w600">Sender</p>
                            <div className="font-medium">
                              {tran?.otherAddress ? (
                                <div
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    const blockchain = appCtx?.blockchain;
                                    const transactionId = tran?.otherAddress;
                                    console.log("blockchain", blockchain);
                                    console.log("transactionId", transactionId);
                                    const blockchainLink =
                                      getBlockchainAddressUrl(
                                        blockchain,
                                        transactionId
                                      );

                                    if (blockchainLink !== "") {
                                      window.open(blockchainLink);
                                    }
                                  }}
                                  className="text-primary"
                                >
                                  {`${tran?.otherAddress?.substring(0, 20)}...`}
                                </div>
                              ) : (
                                "--"
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-md-6">
                          <div className="d-flex flex-row align-items-center gap-2">
                            <p className="mb-0 font-w600">Receiver</p>
                            <div className="font-medium">
                              {tran?.address ? (
                                <div
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    const blockchain = appCtx?.blockchain;
                                    const transactionId = tran?.address;
                                    console.log("blockchain", blockchain);
                                    console.log("transactionId", transactionId);
                                    const blockchainLink =
                                      getBlockchainAddressUrl(
                                        blockchain,
                                        transactionId
                                      );

                                    if (blockchainLink !== "") {
                                      window.open(blockchainLink);
                                    }
                                  }}
                                  className="text-primary"
                                >
                                  {`${tran?.address?.substring(0, 20)}...`}
                                </div>
                              ) : (
                                "--"
                              )}
                            </div>
                          </div>
                          <div className="d-flex flex-row align-items-center gap-2">
                            <p className="mb-0 font-w600">Amount</p>
                            <div className="font-medium">
                              {tran?.amount ? tran?.amount : "0"}
                            </div>
                          </div>
                          <div className="d-flex flex-row align-items-center gap-2">
                            <p className="mb-0 font-w600">Fee</p>
                            <div className="font-medium">
                              {tran?.fee / 1000000 || null}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
