import { faArrowDown, faArrowUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { appActions } from "../../context/app-slice";
import { red } from "@mui/material/colors";
import { useLocation } from "react-router-dom";

const AllCryptoBasketCard = ({ item }) => {
  const appCtx = useSelector((state) => state.app);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  return (
    <div
      class="d-flex justify-content-between align-items-center  market-preview py-3 py-md-0"
      style={{ cursor: "pointer" }}
      onClick={() => navigate(`/crypto-basket/${item?.basketId}`)}
    >
      <div class="w-100">
        <div class="row">
          <div className="col-xl-8 d-flex align-items-center justify-content-between flex-wrap">
            <div class="d-flex align-items-center  col-8 col-md-5">
              <span>
                {item?.icon ? (
                  <img
                    src={item?.icon}
                    alt=""
                    style={{ width: "36px", height: "36px" }}
                    className="rounded-circle m-0 p-0"
                  />
                ) : (
                  <img
                    src="/images/basketimage.svg"
                    alt=""
                    style={{ width: "36px", height: "36px" }}
                    className="rounded-circle m-0 p-0"
                  />
                )}
              </span>
              <div class="ms-3">
                <h5 class="fs-14 font-w600 mb-0">
                  {item?.name && item?.name?.length > 25
                    ? `${item?.name.substring(0, 25)}...`
                    : item?.name}
                </h5>
                <h6 class="fs-12 font-w400 mb-0">
                  by{" "}
                  {item?.wealthManagerName
                    ? item?.wealthManagerName
                    : "Tale Capital"}
                </h6>
              </div>
            </div>
            <div class="col-4 col-md-2 d-flex justify-content-end justify-content-md-start">
              {item?.tokens?.slice(0, 6)?.map((item, index) => (
                <img
                  src={item}
                  alt=""
                  className="rounded-circle mb-1"
                  key={index}
                  style={{ width: "25px", height: "25px" }}
                />
              ))}
            </div>
            <div class="d-flex  ">
              <div class="">
                <p className="fs-14 font-w600 text-center mb-0">
                  {item?.subscriberCount ? item?.subscriberCount : "--"}
                </p>
                <h5 class="fs-12 font-w400 mb-0  text-center">Subscribers</h5>
              </div>
            </div>
            <div class="d-flex  ">
              <div class="fs-14 font-w600">
                <p className={`mb-0 text-center`}>
                  {item?.minAmountCurrency} {item?.minAmount}
                </p>

                <h5 class="fs-12 font-w400 mb-0  text-center">Min Invest</h5>
              </div>
            </div>
          </div>

          <div className="col-xl-4 d-flex align-items-center justify-content-between">
            <div
              className="vertical-line-bg  d-none d-md-block"
              style={{
                height: "40px",
                width: "1px",
              }}
            ></div>

            {/* <CryptoFundLineChart prices={item?.prices ? item?.prices : []} /> */}
            <div class="">
              <p
                className={`${
                  item?.annualCAGR > 0 ? "text-success" : "text-danger"
                } mb-0 text-center fs-14 font-w600`}
              >
                {item?.annualCAGR || "--"} {item?.annualCAGR && "%"}
              </p>
              <h5 class="fs-12 font-w400 mb-0  text-center">CAGR</h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllCryptoBasketCard;
