import { useAccount, useChainId } from "wagmi";
import { ConnectButton } from "@rainbow-me/rainbowkit";
import { useEffect, useRef, useState } from "react";
import { formatUnits, parseUnits } from "ethers";
import {
  useReadContract,
  useBalance,
  useSimulateContract,
  useWriteContract,
  useSendTransaction,
  useWaitForTransactionReceipt,
} from "wagmi";
import { erc20Abi } from "viem";
import {
  POLYGON_EXCHANGE_PROXY,
  MAX_ALLOWANCE,
  AFFILIATE_FEE,
  FEE_RECIPIENT,
} from "./constants";
import qs from "qs";
import { useSelector } from "react-redux";
import { useTailCoin } from "../../../hooks/useTailCoin";
import { CircularProgress } from "@mui/material";
import { getBlockchainTransactionUrl, nativeToken } from "../../../config";
import { useWalletView } from "../../../hooks/useWalletView";
import { Popover } from "react-tiny-popover";
import TokenSelectionDropdown from "../../tokenSelectionDropdown/TokenSelectionDropdown";
import { toast } from "react-toastify";

export default function NonCustodialSwap({
  openModal,
  setOpenModal,
  allCoin,
  allCoinPrice,
  usersToken,
  setOpenTransferSuccessModal,
  setTransactionData,
  symbol,
  isMetamaskSwapPending,
  setIsMetamaskSwapPending,
  isMetamaskSwapConfirming,
  setIsMetamaskSwapConfirming,
  isMetamaskSwapConfirmed,
  setIsMetamaskSwapConfirmed,
  metmaskTransactionHash,
  setMetmaskTransactionHash,
  setOpenMetamaskSwapSuccessModal,
  sendTokenId,
  setSendTokenId,
  receiveTokenId,
  setReceiveTokenId,
  isTokenDetailsPage,
}) {
  const appCtx = useSelector((state) => state.app);
  const { address } = useAccount();

  const chainId = useChainId() || 137;
  console.log("chainId: ", chainId);
  const isWalletsRoute = window.location.pathname === "/wallet";
  let blockchain = isWalletsRoute
    ? appCtx?.walletBlockchain
    : appCtx?.blockchain;

  const [finalize, setFinalize] = useState(false);
  const [price, setPrice] = useState();
  const [sendAmount, setSendAmount] = useState(null);
  const [sendTokenPrice, setSendTokenPrice] = useState("");
  const [sendTokenContractAddress, setSendTokenContractAddress] =
    useState(null);
  const [receiveTokenContractAddress, setReceiveTokenContractAddress] =
    useState(null);

  const [parsedSellAmount, setParsedSellAmount] = useState(null);
  const [parsedBuyAmount, setParsedBuyAmount] = useState(null);

  const [quote, setQuote] = useState({});

  const [sendToken, setSendToken] = useState();

  const [sendTokenUserBalance, setSendTokenUserBalance] = useState(null);
  const [sendTokenDecimal, setSendTokenDecimal] = useState(null);
  const [receiveTokenDecimal, setReceiveTokenDecimal] = useState(null);
  const [receiveTokenPrice, setReceiveTokenPrice] = useState("");

  const [receiveToken, setReceiveToken] = useState("");

  const [receiveAmount, setReceiveAmount] = useState(null);
  const [loading, setLoading] = useState(false);
  const [quoteLoading, setQuoteLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [sendInputValue, setSendInputValue] = useState("");
  const [sendOpen, setSendOpen] = useState(false);
  const [receiveInputValue, setReceiveInputValue] = useState("");
  const [receiveOpen, setReceiveOpen] = useState(false);
  const [sendTokenName, setsendTokenName] = useState(null);
  const [receiveTokenName, setReceiveTokenName] = useState(null);
  const [timer, setTimer] = useState(20);
  const [startTimer, setStartTimer] = useState(false);
  const [nativeTokenDecimals, setNativeTokenDecimals] = useState(null);
  const [sendTokenPriceLoading, setsendTokenPriceLoading] = useState(false);
  const [sendTokenData, setSendTokenData] = useState({});
  const [nativeTokenPriceLoading, setNativeTokenPriceLoading] = useState(false);
  const [nativeTokenData, setNativeTokenData] = useState({});

  const {
    data: hash,
    isPending,
    error,
    sendTransaction,
  } = useSendTransaction();
  const { getSingleTokenBalance } = useWalletView();

  const { isLoading: isConfirming, isSuccess: isConfirmed } =
    useWaitForTransactionReceipt({
      hash,
    });

  const componentRef = useRef(null);

  useEffect(() => {
    setIsMetamaskSwapPending(isPending);
  }, [isPending]);
  useEffect(() => {
    setIsMetamaskSwapConfirming(isConfirming);
  }, [isConfirming]);
  useEffect(() => {
    setIsMetamaskSwapConfirmed(isConfirmed);
  }, [isConfirmed]);
  useEffect(() => {
    setMetmaskTransactionHash(hash);
  }, [hash, isConfirmed]);

  const exchangeProxy = (chainId) => {
    if (chainId === 137) {
      return POLYGON_EXCHANGE_PROXY;
    }
    return POLYGON_EXCHANGE_PROXY;
  };

  const usersWalletAddress = appCtx?.walletAddress?.find(
    (addr) => addr?.blockchain === blockchain
  )?.address;
  useEffect(() => {
    const parsedSellAmnt = sendAmount
      ? parseUnits(sendAmount, sendTokenDecimal).toString()
      : undefined;

    setParsedSellAmount(parsedSellAmnt);
  }, [sendToken, sendAmount]);
  useEffect(() => {
    const parsedBuyAmnt = receiveAmount
      ? parseUnits(receiveAmount, receiveTokenDecimal).toString()
      : undefined;

    setParsedBuyAmount(parsedBuyAmnt);
  }, [receiveToken, receiveAmount]);

  // Hook for fetching balance information for specified token for a specific takerAddress
  const { data, isError, isLoading } = useBalance({
    address: address,
    ...(sendToken !== nativeToken[blockchain]
      ? { token: sendTokenContractAddress }
      : {}),
  });

  console.log("taker sellToken balance: ", data);
  console.log("sendtoken: ", sendToken);

  const inSufficientBalance =
    data && sendAmount
      ? parseUnits(sendAmount, sendTokenDecimal) > data.value
      : true;

  const width = window.screen.width;
  let isMobileDevice = width < 600;

  const handleMaxButtonClick = () => {
    if (sendTokenUserBalance) {
      const sendTokenUserBalanceStr = sendTokenUserBalance?.toString();
      setSendAmount(sendTokenUserBalanceStr);
    }
  };

  useEffect(() => {
    if (sendTokenId) {
      getSingleTokenBalance(
        usersWalletAddress,
        blockchain,
        sendTokenId,
        setSendTokenData,
        setsendTokenPriceLoading
      );
    }
  }, [sendTokenId]);

  // initial token for token details swap
  useEffect(() => {

    if (isTokenDetailsPage) {
      let initialSelecteToken =
        allCoin && allCoin?.find((token) => token?.symbol === symbol);

      setSendToken(initialSelecteToken?.symbol);
      setsendTokenName(initialSelecteToken?.tokenName);
    }
  }, [appCtx?.blockchain, allCoin]);

  // get native token balance
  useEffect(() => {
    let nativeTokenId = allCoin.find(
      (token) => token?.symbol === nativeToken[blockchain]
    )?.tokenId;

    if (nativeTokenId) {
      getSingleTokenBalance(
        usersWalletAddress,
        blockchain,
        nativeTokenId,
        setNativeTokenData,
        setNativeTokenPriceLoading
      );
    }
  }, [blockchain, allCoin]);

  useEffect(() => {
    const send = allCoin.find((token) => token?.symbol === sendToken);
    setSendTokenContractAddress(
      send?.contractAddress
        ? send?.contractAddress
        : sendToken === nativeToken[blockchain]
        ? "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee"
        : null
    );
    setSendTokenDecimal(send?.decimals);

    const sendprice = allCoinPrice[sendToken]?.price;
    setSendTokenPrice(sendprice);
    console.log("logging send", send);
    setSendTokenId(send?.tokenId);

    setSendTokenUserBalance(sendTokenData?.balance);

    const receive = allCoin.find((token) => token?.symbol === receiveToken);
    setReceiveTokenContractAddress(
      receive?.contractAddress
        ? receive?.contractAddress
        : receiveToken === nativeToken[blockchain]
        ? "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee"
        : null
    );
    setReceiveTokenDecimal(receive?.decimals);
    const receiveprice = allCoinPrice[receiveToken]?.price;
    setReceiveTokenPrice(receiveprice);
    setReceiveTokenId(receive?.tokenId);
  }, [sendToken, receiveToken, sendTokenData, allCoin]);

  let zeroXEndpoint =
    blockchain === "FLOW"
      ? ``
      : blockchain === "ALGORAND"
      ? `${process.env.REACT_APP_ALGORAND_OxAPIEndpoint}`
      : blockchain === "ETHEREUM"
      ? `${process.env.REACT_APP_ETHEREUM_OxAPIEndpoint}`
      : blockchain === "POLYGON"
      ? `${process.env.REACT_APP_POLYGON_OxAPIEndpoint}`
      : blockchain === "BSC"
      ? `${process.env.REACT_APP_BSC_OxAPIEndpoint}`
      : blockchain === "SOLANA"
      ? `${process.env.REACT_APP_SOLANA_OxAPIEndpoint}`
      : blockchain === "AVALANCHE"
      ? `${process.env.REACT_APP_AVALANCHE_OxAPIEndpoint}`
      : blockchain === "NORDEK"
      ? `${process.env.REACT_APP_NORDEK_OxAPIEndpoint}`
      : "";

  useEffect(() => {
    if (sendAmount) {
      const sendTokenPrice = allCoinPrice[sendToken]?.price;
      const receiveTokenPrice = allCoinPrice[receiveToken]?.price;
      if (sendTokenPrice && receiveTokenPrice) {
        const receiveAmount = (
          (parseFloat(sendAmount) * parseFloat(sendTokenPrice)) /
          parseFloat(receiveTokenPrice)
        )?.toFixed(4);

        setReceiveAmount(receiveAmount);
      } else {
        setReceiveAmount("");
      }
    }
  }, [receiveToken, sendAmount, allCoinPrice, sendToken]);

  function closeModal() {
    setOpenModal(false);
    setSendTokenContractAddress(null);
    setReceiveTokenContractAddress(null);
    setSendAmount(null);
  }

  const fetchQuote = async () => {
    if (sendToken && sendAmount && receiveToken) {
      setQuoteLoading(true);
      try {
        const params = {
          sellToken: sendTokenContractAddress,
          buyToken: receiveTokenContractAddress,
          sellAmount: parsedSellAmount,
          takerAddress: address,
          feeRecipient: FEE_RECIPIENT,
          buyTokenPercentageFee: AFFILIATE_FEE,
          feeRecipientTradeSurplus: FEE_RECIPIENT,
        };

        // Fetch the quote from the API
        const res = await fetch(
          `${zeroXEndpoint}/swap/v1/quote?${qs.stringify(params)}`,
          {
            headers: {
              "0x-api-key": process.env.REACT_APP_ZEROEX_API_KEY,
              "0x-chain-id": chainId,
            },
          }
        );

        // Parse the response as JSON
        const data = await res.json();

        // Set the quote data
        setQuote(data);
        setQuoteLoading(false);
      } catch (error) {
        console.error("Error fetching quote:", error);
        setQuoteLoading(false);
      }
    }
  };

  useEffect(() => {
    if (startTimer) {
      // Update the timer every second
      const timerId = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);

      // Clear the timer when it reaches 0
      if (timer === 0) {
        fetchQuote();
        setTimer(20);
        // clearInterval(timerId);
      }

      return () => {
        clearInterval(timerId); // Clean up the timer on component unmount
      };
    }
  }, [
    startTimer,
    timer,
    sendTokenContractAddress,
    sendAmount,
    receiveTokenContractAddress,
  ]);

  useEffect(() => {
    if (sendTokenContractAddress && sendAmount && receiveTokenContractAddress) {
      fetchQuote();
      setStartTimer(true);
    }
  }, [sendTokenContractAddress, sendAmount, receiveTokenContractAddress]);

  const handleSwap = async () => {
    if (nativeTokenData?.balance < 0.0003) {
      toast.error(
        `You don't have enough ${nativeToken[blockchain]} to pay the gas fee`
      );
      return;
    }
    setLoading(true);

    try {
      // Perform the transaction using the fetched quote
      if (sendTransaction && !isPending) {
        sendTransaction({
          gas: quote?.gas,
          to: quote?.to,
          value: quote?.value, // only used for native tokens
          data: quote?.data,
          gasPrice: quote?.gasPrice,
        });
        setOpenMetamaskSwapSuccessModal(true);
      }
      setLoading(false);
    } catch (error) {
      console.error("Error:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    const nativeTokenDeciaml = allCoin.find(
      (token) => token?.symbol === nativeToken[blockchain]
    )?.decimals;
    setNativeTokenDecimals(nativeTokenDeciaml);
  }, [allCoin]);

  const estimatedGasInNativeToken =
    quote &&
    (quote.estimatedGas * quote.gasPrice) / Math.pow(10, nativeTokenDecimals);

  const handleClick = (event) => {
    event.preventDefault();
    // Check if the clicked element has the 'button-id' ID
    if (event.target.id === "sendButton") {
      setSendOpen(true);
    } else if (
      event.target.id === "searchbar" ||
      event.target.id === "searchIcon"
    ) {
    } else if (event.target.id === "receiveButton") {
      setReceiveOpen(true);
    } else {
      setSendOpen(false);
      setReceiveOpen(false);
    }
  };

  return (
    <div className="p-4 position-relative">
      <div className="w-100 d-flex justify-content-end m-2 position-relative">
        {
          <div
            onClick={closeModal}
            className="btn btn-link position-absolute top-0 right-2"
            style={{ cursor: "pointer" }}
          >
            <i className="fa fa-times" style={{ fontSize: "20px" }}></i>
          </div>
        }
      </div>

      <div className="w-100" ref={componentRef} onClick={handleClick}>
        {
          <>
            <h3 class="text-center mb-3">Swap Tokens</h3>
            <div class="d-flex flex-column align-items-center">
              <div class="w-100 max-w-md my-2">
                <h5 class="">Select Token</h5>
                {sendTokenUserBalance && (
                  <p>
                    I have {sendTokenUserBalance?.toFixed(6)} {sendToken}
                  </p>
                )}
                <div class="d-flex justify-content-between align-items-start">
                  <div class="flex-grow">
                    {/* dropdown  */}
                    <div className="position-relative">
                      <div
                        onClick={() => setSendOpen(!sendOpen)}
                        className={`form-select`}
                        style={{
                          width: `${isMobileDevice ? "150px" : "210px"}`,
                          border: `${
                            appCtx?.isDarkMode ? "none" : "1 px solid #F6F6F6"
                          }`,
                          borderRadius: "5px",
                          backgroundColor: `${
                            appCtx.isDarkMode ? "#18254F" : "#FFFFFF"
                          }`,
                          color: `${appCtx.isDarkMode ? "#8896b9" : "#717579"}`,
                          cursor: "pointer",
                        }}
                      >
                        <p
                          className="mb-0"
                          style={{
                            paddingTop: "2px",
                            paddingBottom: "2px",
                          }}
                          id="sendButton"
                        >
                          {sendTokenName
                            ? sendTokenName?.length > 15
                              ? sendTokenName?.substring(0, 15) + "..."
                              : sendTokenName
                            : "Select token"}
                        </p>
                      </div>
                      {sendOpen && (
                        <TokenSelectionDropdown
                          searchTerm={sendInputValue}
                          setSearchTerm={setSendInputValue}
                          allCoin={allCoin}
                          isDrodownOpen={sendOpen}
                          setIsDropdownOpen={setSendOpen}
                          setToken={setSendToken}
                          setTokenName={setsendTokenName}
                          additionalStyles={{ top: "-60px", left: "0px" }}
                        />
                      )}
                    </div>

                    {sendTokenPrice && (
                      <label htmlFor="">${sendTokenPrice?.toFixed(5)}</label>
                    )}
                  </div>
                  <div class="position-relative d-flex">
                    <div class="position-relative d-flex align-items-center">
                      <input
                        id="sendAmount"
                        placeholder="Enter amount"
                        name="sendAmount"
                        type="number"
                        min="0"
                        step="any"
                        value={sendAmount}
                        onChange={(e) => setSendAmount(e.target.value)}
                        class="form-control"
                        style={{
                          width: `${isMobileDevice ? "180px" : "210px"}`,
                        }}
                      />
                      <button
                        type="button"
                        class="position-absolute text-sm text-gray-700 bg-transparent border border-gray-400 rounded px-2 py-1"
                        onClick={handleMaxButtonClick}
                        style={{
                          top: "50%",
                          transform: "translateY(-50%)",
                          right: "5px",
                        }}
                      >
                        <h6 class="font-s12 font-w400 mb-0">max</h6>
                      </button>
                    </div>
                  </div>
                </div>

                <div className="d-flex justify-content-center my-3 w-100">
                  <img src="/images/doublearrow.svg" alt="" />
                </div>
                <div>
                  {receiveToken && <p>I want {receiveToken}</p>}

                  <div class="d-flex justify-content-between w-100 rounded-lg">
                    <div class="flex-grow  position-relative">
                      {/* receive dropdown  */}
                      <div className="position-relative">
                        <div
                          onClick={() => setReceiveOpen(!receiveOpen)}
                          className={`form-select`}
                          style={{
                            width: `${isMobileDevice ? "150px" : "210px"}`,
                            border: `${
                              appCtx?.isDarkMode ? "none" : "1 px solid #F6F6F6"
                            }`,
                            borderRadius: "5px",
                            backgroundColor: `${
                              appCtx.isDarkMode ? "#18254F" : "#FFFFFF"
                            }`,
                            color: `${
                              appCtx.isDarkMode ? "#8896b9" : "#717579"
                            }`,
                            cursor: "pointer",
                          }}
                        >
                          <p
                            className="mb-0"
                            style={{
                              paddingTop: "2px",
                              paddingBottom: "2px",
                            }}
                            id="receiveButton"
                          >
                            {receiveTokenName
                              ? receiveTokenName?.length > 15
                                ? receiveTokenName?.substring(0, 15) + "..."
                                : receiveTokenName
                              : "Select token"}
                          </p>
                        </div>
                        {receiveOpen && (
                          <TokenSelectionDropdown
                            searchTerm={receiveInputValue}
                            setSearchTerm={setReceiveInputValue}
                            allCoin={allCoin}
                            isDrodownOpen={receiveOpen}
                            setIsDropdownOpen={setReceiveOpen}
                            setToken={setReceiveToken}
                            setTokenName={setReceiveTokenName}
                            additionalStyles={{
                              top: "-150px",
                              left: "0px",
                            }}
                          />
                        )}
                      </div>

                      {receiveTokenPrice && (
                        <label htmlFor="">
                          ${receiveTokenPrice?.toFixed(5)}
                        </label>
                      )}
                    </div>
                    <div class="position-relative d-flex">
                      <input
                        id="receiveAmount"
                        placeholder="Amount you will get"
                        name="receiveAmount"
                        type="number"
                        min="0"
                        step="any"
                        value={receiveAmount}
                        disabled
                        class="form-control"
                        style={{
                          width: `${isMobileDevice ? "180px" : "210px"}`,
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div
                  className="mt-3"
                  style={{
                    minHeight: "30px",
                  }}
                >
                  {quoteLoading && (
                    <div className="">Fetching best quote for you</div>
                  )}
                  {startTimer && !quoteLoading && (
                    <div className="">New quotes in: {timer} seconds</div>
                  )}
                  {quote?.price && !quoteLoading && (
                    <div className="w-100 d-flex justify-content-between">
                      <p className="mb-1">Quote rate: </p>
                      <p className="font-w600 mb-1">
                        1 {sendToken} = {parseFloat(quote?.price)?.toFixed(6)}{" "}
                        {receiveToken}{" "}
                      </p>
                    </div>
                  )}
                  {quote?.estimatedGas &&
                    estimatedGasInNativeToken &&
                    !quoteLoading && (
                      <div className="w-100 d-flex justify-content-between">
                        <p className="mb-1"> Estimated Gas Fee: </p>
                        <div className="d-flex gap-2">
                          <p className="mb-1">
                            {estimatedGasInNativeToken?.toFixed(6)}{" "}
                            {nativeToken[blockchain]}
                          </p>
                        </div>
                      </div>
                    )}
                  {error && (
                    <div className="text-danger mt-2">
                      Error: {error.shortMessage || error.message}
                    </div>
                  )}
                </div>

                {/* swap button  */}
                <div className="d-flex justify-content-center mt-4 w-100">
                  {address ? (
                    <ApproveOrReviewButton
                      sellTokenAddress={sendTokenContractAddress}
                      takerAddress={address}
                      onClick={() => {
                        setFinalize(true);
                      }}
                      inSufficientBalance={inSufficientBalance}
                    />
                  ) : (
                    <ConnectButton.Custom>
                      {({
                        account,
                        chain,
                        openAccountModal,
                        openChainModal,
                        openConnectModal,
                        mounted,
                      }) => {
                        const ready = mounted;
                        const connected = ready && account && chain;

                        return (
                          <div
                            {...(!ready && {
                              "aria-hidden": true,
                              style: {
                                opacity: 0,
                                pointerEvents: "none",
                                userSelect: "none",
                              },
                            })}
                          >
                            {(() => {
                              if (!connected) {
                                return (
                                  <button
                                    className="btn btn-primary"
                                    onClick={openConnectModal}
                                    type="button"
                                  >
                                    Connect Wallet
                                  </button>
                                );
                              }

                              if (chain.unsupported) {
                                return (
                                  <button
                                    onClick={openChainModal}
                                    type="button"
                                    className="btn btn-danger"
                                  >
                                    Wrong network
                                  </button>
                                );
                              }

                              return (
                                <div style={{ display: "flex", gap: 12 }}>
                                  <button
                                    onClick={openChainModal}
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                    type="button"
                                  >
                                    {chain.hasIcon && (
                                      <div
                                        style={{
                                          background: chain.iconBackground,
                                          width: 12,
                                          height: 12,
                                          borderRadius: 999,
                                          overflow: "hidden",
                                          marginRight: 4,
                                        }}
                                      >
                                        {chain.iconUrl && (
                                          <img
                                            src={chain.iconUrl}
                                            alt={chain.name ?? "Chain icon"}
                                            style={{
                                              width: "35px",
                                              height: "35px",
                                            }}
                                          />
                                        )}
                                      </div>
                                    )}
                                    {chain.name}
                                  </button>

                                  <button
                                    onClick={openAccountModal}
                                    type="button"
                                  >
                                    {account.displayName}
                                    {account.displayBalance
                                      ? ` (${account.displayBalance})`
                                      : ""}
                                  </button>
                                </div>
                              );
                            })()}
                          </div>
                        );
                      }}
                    </ConnectButton.Custom>
                  )}
                </div>
                {isConfirming && (
                  <div className="text-center">
                    Waiting for confirmation ⏳ ...
                  </div>
                )}
                {isConfirmed && (
                  <div
                    className="text-center"
                    onClick={() => {
                      const blockchain = blockchain;
                      const blockchainLink = getBlockchainTransactionUrl(
                        blockchain,
                        hash
                      );
                      if (blockchainLink !== "") {
                        window.open(blockchainLink);
                      }
                    }}
                  >
                    View transaction on{" "}
                    <span
                      className="text-primary"
                      style={{
                        cursor: "pointer",
                      }}
                    >
                      {blockchain?.toLowerCase()}chain
                    </span>
                  </div>
                )}
              </div>
            </div>
          </>
        }
      </div>
    </div>
  );

  function ApproveOrReviewButton({
    takerAddress,
    onClick,
    sellTokenAddress,
    inSufficientBalance,
  }) {
    // 1. Read from erc20, does spender (0x Exchange Proxy) have allowance?
    const { data: allowance, refetch } = useReadContract({
      address: sellTokenAddress,
      abi: erc20Abi,
      functionName: "allowance",
      args: [takerAddress, exchangeProxy(chainId)],
    });

    // 2. (only if no allowance): write to erc20, approve a token allowance for 0x Exchange Proxy
    const { data } = useSimulateContract({
      address: sellTokenAddress,
      abi: erc20Abi,
      functionName: "approve",
      args: [exchangeProxy(chainId), MAX_ALLOWANCE],
    });

    // Define useWriteContract for the 'approve' operation
    const {
      data: writeContractResult,
      writeContractAsync: writeContract,
      error,
    } = useWriteContract();

    // useWaitForTransactionReceipt to wait for the approval transaction to complete
    const { data: approvalReceiptData, isLoading: isApproving } =
      useWaitForTransactionReceipt({
        hash: writeContractResult,
      });

    // Call `refetch` when the transaction succeeds
    useEffect(() => {
      if (data) {
        refetch();
      }
    }, [data, refetch]);

    if (error) {
      return <div>Something went wrong: {error.message}</div>;
    }

    if (allowance === 0n) {
      return (
        <>
          <button
            type="button"
            className="btn btn-primary"
            onClick={async () => {
              await writeContract({
                abi: erc20Abi,
                address: sellTokenAddress,
                functionName: "approve",
                args: [exchangeProxy(chainId), MAX_ALLOWANCE],
              });
              refetch();
            }}
          >
            {isApproving ? "Approving…" : "Approve"}
          </button>
        </>
      );
    }

    return (
      <button
        type="button"
        disabled={inSufficientBalance || loading || quoteLoading}
        onClick={() => {
          handleSwap();
        }}
        className="btn btn-primary d-flex align-items-centner justify-content-center gap-2 w-100"
      >
        {isPending ? "Pending..." : "Swap"}
        {loading && (
          <span>
            <CircularProgress size={20} />
          </span>
        )}
      </button>
    );
  }
}
