import React, { useEffect, useState } from "react";
import { Grid, Skeleton } from "@mui/material";
import MainWrapper from "../../component/wrapper/MainWrapper";
import { useWalletView } from "../../hooks/useWalletView";
import useAuthorizedHttp from "../../hooks/use-authorized-http";
import { useSelector } from "react-redux";
import { Transaction } from "../../component/Transactions/Transaction";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { getBlockchainTransactionUrl } from "../../config";

const History = () => {
  const [loading, setLoading] = useState(false);
  const [content, setContent] = useState([]);

  const [activeTab, setActiveTab] = useState("Onramp");
  const appCtx = useSelector((state) => state.app);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const { getAllCoin, allCoin } = useWalletView();

  useEffect(() => {
    getAllCoin();
  }, []);

  const makeAuthorizedRequest = useAuthorizedHttp();
  useEffect(() => {
    setLoading(true);
    makeAuthorizedRequest(
      {
        url: `${process.env.REACT_APP_URL_BLOCKCHAIN_SERVICE}/inhouse/onramp/transaction/history`,
      },

      (data) => {
        setContent(data?.content);
        setLoading(false);
      },
      (rej) => {
        setLoading(false);
      }
    );
  }, []);

  const themedColor = `${appCtx?.isDarkMode ? "white" : "black"}`;

  return (
    <MainWrapper>
      <div className="container mx-auto">
        <div className="d-flex justify-content-center mt-8">
          <nav>
            <div class="nav nav-pills light" id="nav-tab" role="tablist">
              <button
                className={`nav-link ${activeTab === "Onramp" ? "active" : ""}`}
                onClick={() => handleTabClick("Onramp")}
              >
                Onramp History
              </button>
              <button
                className={`nav-link ${
                  activeTab === "Transaction" ? "active" : ""
                }`}
                onClick={() => handleTabClick("Transaction")}
              >
                Transaction History
              </button>
            </div>
          </nav>
        </div>

        <div className="mt-8">
          {activeTab === "Onramp" && (
            <div className="flex flex-col justify-center pb-5">
              <div className="">
                <h5 className="text-center mt-4 mb-2">Onramp History</h5>
                {loading ? (
                  <Grid container spacing={2}>
                    {[...Array(4)].map((_, index) => (
                      <Grid item xs={12} key={index}>
                        <Skeleton
                          variant="rectangular"
                          width="100%"
                          height={200}
                        />
                      </Grid>
                    ))}
                  </Grid>
                ) : !loading && content.length === 0 ? (
                  <p className="text-center">No Transaction Found</p>
                ) : (
                  <div container>
                    {content.map((item) => (
                      <div key={item.id} className="my-2">
                        <div
                          className="shadow p-4 rounded"
                          style={{
                            backgroundColor: `${
                              appCtx?.isDarkMode ? "#18254F" : "white"
                            }`,
                          }}
                        >
                          <div className="d-flex  justify-content-between align-items-center">
                            <div className="d-flex flex-column justify-content-start gap-2    w-100 w-md-50">
                              {/* Left element content */}
                              <div className="d-flex flex-row align-items-center">
                                <img
                                  src="/images/indianflag.png"
                                  alt=""
                                  className="me-2"
                                  style={{ width: "25px" }}
                                />
                                <p className="font-weight-bold mb-0">
                                  {item?.fiatAmount} {item?.fiatCurrency}
                                </p>
                              </div>
                              {item?.tokenPrice && (
                                <p className="text-sm  font-weight-bold">
                                  {item?.tokenPrice} {item?.fiatCurrency} per{" "}
                                  {item?.cryptoToken}
                                </p>
                              )}
                              <div className="d-flex flex-row align-items-center">
                                <img
                                  src={
                                    allCoin
                                      ? allCoin.find(
                                          (obj) =>
                                            obj.tokenShortName ===
                                            item?.cryptoToken
                                        )?.tokenIcon
                                      : ""
                                  }
                                  alt=""
                                  className="me-2"
                                  style={{ width: "25px" }}
                                />
                                <p className="font-weight-bold mb-0">
                                  {item?.cryptoAmount?.toFixed(3)}{" "}
                                  {item?.cryptoToken}
                                </p>
                              </div>
                              <p className="text-sm font-weight-light ">
                                Platform Fee: {item?.platformFee} INR
                              </p>
                            </div>
                            <div className="d-flex flex-column justify-content-end align-items-end gap-2 w-100 w-md-50">
                              {/* Right element content */}
                              <div className="d-flex flex-column gap-1 ">
                                <div className="">
                                  {item?.status === null ? (
                                    <button class="btn btn-danger rounded">
                                      Processing
                                    </button>
                                  ) : (
                                    <button class="rounded btn btn-success">
                                      {item?.status}
                                    </button>
                                  )}
                                </div>
                                {item?.blockchainTransactionId && (
                                  <div className="">
                                    <button
                                      onClick={() => {
                                        const blockchain = item?.blockchain;
                                        const transactionId =
                                          item?.blockchainTransactionId;
                                        console.log("blockchain", blockchain);
                                        console.log(
                                          "transactionId",
                                          transactionId
                                        );
                                        const blockchainLink =
                                          getBlockchainTransactionUrl(
                                            blockchain,
                                            transactionId
                                          );
                                        if (blockchainLink !== "") {
                                          window.open(blockchainLink);
                                        }
                                      }}
                                      className="btn btn-primary"
                                    >
                                      View on Chain
                                      <FontAwesomeIcon
                                        icon={faArrowRight}
                                        className="ms-1"
                                        style={{
                                          color: themedColor,
                                        }}
                                      />
                                    </button>
                                  </div>
                                )}
                                <p className="text-sm font-weight-light ">
                                  Network Fee: {item?.networkFee} INR
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          )}
          {activeTab === "Transaction" && (
            <>
              <Transaction />
            </>
          )}
        </div>
      </div>
    </MainWrapper>
  );
};

export default History;
