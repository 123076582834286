import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useCryptobasket } from "./useCryptobasket";
import { CircularProgress, Skeleton } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowTrendUp, faArrowUp, faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { appActions } from "../../context/app-slice";
import MainWrapper from "../../component/wrapper/MainWrapper";
import Pricechart from "../../component/Pricechart/Pricechart";
import Piechart from "../../component/Piechart/Piechart";
import CryptoBasketAreaChart from "../../component/areachart/CryptoBasketAreaChart";
import { Row, Col } from "react-bootstrap";
import RebalanceModal from "./RebalanceModal";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import BasketModal from "./BasketModal";

const CryptoBasketDetails = () => {
  const [selectedChartTab, setSelectedChartTab] = useState(0);
  const [subscribed, setSubscribed] = useState(false);
  const [subscribeLoading, setSubscribeLoading] = useState(false);
  const [portfolio, setPortfolio] = useState("");
  const [subscribedData, setSubscribedData] = useState("");
  const [fees, setFees] = useState("");
  const [distributionProgress, setDistributionProgress] = useState(false);
  const [openEditBasketModal, setOpenEditBasketModal] = useState(false);
  const [openFeeInfoModal, setOpenFeeInfoModal] = useState(false);
  const [status, setStatus] = useState("");
  const [subscribeId, setSubscribeId] = useState(null);
  const [selectedHistoryTab, setSelectedHistoryTab] = useState("week");

  // const [closeClicked, setCloseClicked] = useState(false);
  const appCtx = useSelector((state) => state.app);
  const dispatch = useDispatch();
  const { id } = useParams();

  const [openTalewalletModal, setOpenTalewalletModal] = useState(false);
  const [openHaveAnAccountOptionModal, setOpenHaveAnAccountOptionModal] =
    useState(false);
  const [openContinueInWebModal, setOpenContinueInWebModal] = useState(false);

  useEffect(() => {
    if (appCtx?.isLoggedIn === true) {
      if (appCtx.authToken) {
        let config = {
          url: `${process.env.REACT_APP_URL_BLOCKCHAIN_SERVICE}/user/blockchain/account`,
          method: "get",
          headers: {
            "Content-Type": "application/json",
            "X-Auth-Token": appCtx.authToken,
          },
        };
        axios(config)
          .then((response) => {
            let data = response.data;
            console.log(data);
            const walletData = data?.filter(
              (account) => account.wallet === "TALEWALLET"
            );
            dispatch(appActions.setCustodial(walletData[0]?.custodial));
            if (walletData?.length > 0) {
              dispatch(appActions.setWalletAddress(walletData));
            }
          })
          .catch((error) => {});
      }

      setOpenContinueInWebModal(false);
      setOpenHaveAnAccountOptionModal(false);
      setOpenTalewalletModal(false);
      dispatch(appActions.setBlockChain("BSC"));
      // window.location.reload();
    }
  }, [appCtx?.isLoggedIn]);

  const createAccount = async () => {
    setOpenTalewalletModal(true);
  };

  const {
    fetchBasketById,
    singleBasket,
    singleBasketLoading,
    setopenBasketModal,
    openBasketModal,
    closeClicked,
    setCloseClicked,
    successcloseClicked,
    fetchBasketOwner,
    ownerDataLoading,
    basketOwner,
    transactionSuccess,
    setTransactionSuccess,
    setSuccessCloseClicked,
    openSuccessModal,
    setopenSuccessModal,
  } = useCryptobasket();

  const navigate = useNavigate();

  const Data = [
    {
      id: 0,
      value: "w",
      range: "week",
    },
    {
      id: 1,
      value: "m",
      range: "month",
    },
    {
      id: 2,
      value: "6m",
      range: "6month",
    },
    {
      id: 3,
      value: "y",
      range: "year",
    },
  ];

  useEffect(() => {
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_URL_BLOCKCHAIN_SERVICE}/crypto/basket/${id}/fees`,
      headers: {
        ...(appCtx.authToken
          ? { "X-Auth-Token": appCtx.authToken }
          : { "X-App-Token": process.env.REACT_APP_X_APP_TOKEN }),
        "Content-Type": "application/json",
      },
    };

    const fetchData = async () => {
      try {
        const response = await axios.request(config);
        setFees(response.data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    fetchBasketById(id)
      .then((response) => {
        const userId = response.data.userId;
        return fetchBasketOwner(userId);
      })

      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    if (appCtx.isLoggedIn) {
      setSubscribeLoading(true);

      let config = {
        method: "get",
        url: `${process.env.REACT_APP_URL_BLOCKCHAIN_SERVICE}/crypto/basket/${id}/subscribed`,
        headers: {
          "X-Auth-Token": appCtx.authToken,
          "Content-Type": "application/json",
        },
      };

      axios
        .request(config)
        .then((response) => {
          if (response.data.subscribed === true) {
            setSubscribed(true);

            setSubscribeLoading(false);
          } else {
            setSubscribed(false);
            setSubscribeLoading(false);
            setStatus(response?.data?.status);
            setSubscribeId(response?.data?.id);
          }
          setSubscribedData(JSON.parse(response?.data?.tokenDistribution));
        })
        .catch((error) => {
          console.log(error);
          setSubscribeLoading(false);
        });
    }
  }, [successcloseClicked, transactionSuccess]);

  useEffect(() => {
    if (appCtx.isLoggedIn) {
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_URL_BLOCKCHAIN_SERVICE}/crypto/basket/${id}/portfolio`,
        headers: {
          "X-Auth-Token": appCtx.authToken,
          "Content-Type": "application/json",
        },
      };

      axios
        .request(config)
        .then((response) => {
          if (response.status === 200) {
            setPortfolio(response.data);
          }
        })
        .catch((error) => {
          if (error.response.data.status === 500) {
            setDistributionProgress(true);
          } else {
            console.log(error);
          }
        });
    }
  }, [subscribed]);

  const description = singleBasket?.description ?? "";
  const sections = description.split(".").reduce((acc, curr, i, arr) => {
    if (i % 2 === 0) {
      acc.push(arr.slice(i, i + 2).join(".") + ".");
    }
    return acc;
  }, []);

    const tokenPercentArray = singleBasket?.cryptoBasketTokens?.map(
      (token) => token?.tokenPercent
    );
    const tokenArray = singleBasket?.cryptoBasketTokens?.map(
      (token) => token?.token
    );

  return (
    <MainWrapper>
      <div class={`${appCtx?.isDarkMode ? "text-white" : ""}`}>
        <div class="container-fluid ">
          {singleBasketLoading ? (
            <div className="pt-[5%] w-75 mx-auto">
              {Array.from({ length: 12 }).map((_, index) => (
                <Skeleton
                  key={index}
                  variant="text"
                  sx={{ fontSize: index === 11 ? "2rem" : "1rem" }}
                  className={
                    index === 2 || index === 9 || index === 10 || index === 11
                      ? "w-[60%] mx-auto mt-3"
                      : index === 3
                      ? "w-[70%] mx-auto mt-5"
                      : "w-[80%] mx-auto"
                  }
                  height={index === 3 ? 300 : undefined}
                />
              ))}
            </div>
          ) : (
            <div class="row ">
              <div class="col-xl-12">
                <button
                  className="btn btn-primary mb-2"
                  onClick={() => navigate("/crypto-basket")}
                >
                  <FontAwesomeIcon
                    icon={faArrowLeft}
                    style={{ fontSize: "12px" }}
                  />
                </button>
                <div>
                  <div>
                    <div class="row align-items-start  gx-3">
                      <div class="col-xl-9   ">
                        <div className="row">
                          <div className="col-xl-12">
                            <div class="card coin-content">
                              <div class="card-header border-0 flex-wrap pb-0">
                                <div class="mb-xl-0 mb-2">
                                  <h4 class="card-title">
                                    {singleBasket?.name}
                                  </h4>
                                  {basketOwner?.name && (
                                    <span class="fs-12">
                                      by {basketOwner?.name}
                                    </span>
                                  )}
                                  {singleBasket?.annualCAGR && (
                                    <span class="fs-12">
                                      1 Y CAGR {singleBasket?.annualCAGR}
                                    </span>
                                  )}
                                </div>

                                <button
                                  onClick={() => {
                                    setopenBasketModal(true);
                                  }}
                                  type="button"
                                  className="btn btn-success d-flex align-items-center justify-content-between"
                                >
                                  Invest
                                  <FontAwesomeIcon
                                    icon={faArrowTrendUp}
                                    style={{ transform: "rotate(90deg)" }}
                                  />
                                </button>
                              </div>
                              <div class="card-body">
                                <div class="d-flex align-items-center justify-content-between flex-wrap">
                                  <div class="d-flex align-items-end justify-content-between flex-wrap">
                                    <div class="price-content">
                                      <span class="d-block mb-2">
                                        Subscription Cost
                                      </span>
                                      <h4 class="fs-20 font-w600 mb-0">
                                        {singleBasket?.subscriptionCost ===
                                          null ||
                                        singleBasket?.subscriptionCost <= 0
                                          ? "Free"
                                          : `${singleBasket?.subscriptionCost} /month`}
                                        {singleBasket?.subscriptionCostUnit}
                                      </h4>
                                    </div>
                                    <div class="price-content">
                                      <span class="fs-14 d-block mb-2">
                                        Min.Amount
                                      </span>
                                      <h4 class="font-w600 text-success mb-0">
                                        {singleBasket?.minAmount}{" "}
                                        {singleBasket?.minAmountCurrency}
                                        {/* <i class="fa-solid fa-caret-up ms-1 text-success"></i> */}
                                      </h4>
                                    </div>
                                    <div class="price-content">
                                      <span class="fs-14 d-block mb-2">
                                        Total Fee
                                      </span>
                                      <h4 class="font-w600 mb-0">
                                        {fees?.total?.toFixed(3)}
                                        {singleBasket?.minAmountCurrency}
                                      </h4>
                                    </div>
                                    <div class="price-content">
                                      <span class="fs-14 d-block mb-2">
                                        Blockchain
                                      </span>
                                      <h4 class="font-w600 mb-0">
                                        {singleBasket?.blockchain}
                                      </h4>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          {status && (
                            <>
                              <div className="col-xl-12">
                                <div class="card pb-0">
                                  <div class="card-body">
                                    <div className="">
                                      {
                                        // <button
                                        //   onClick={() => {
                                        //     setopenBasketModal(true);
                                        //   }}
                                        //   className={`w-[100%] text-center p-4 mb-4 text-lg font-semibold  rounded-lg ${
                                        //     status === "INITIATED"
                                        //       ? "bg-blue-100"
                                        //       : "bg-red-100"
                                        //   } `}
                                        //   role="alert"
                                        // >
                                        //   {status === "INITIATED"
                                        //     ? "Payment initiated. Try again if you couldn't complete the payment"
                                        //     : status === "FAILED"
                                        //     ? "Payment failed. Try again"
                                        //     : ""}
                                        // </button>
                                        <>
                                          {status === "INITIATED" && (
                                            <div class="alert alert-primary alert-dismissible fade show">
                                              <svg
                                                viewBox="0 0 24 24"
                                                width="24"
                                                height="24"
                                                stroke="currentColor"
                                                stroke-width="2"
                                                fill="none"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                class="me-2 alert-icon"
                                              >
                                                <circle
                                                  cx="12"
                                                  cy="12"
                                                  r="10"
                                                ></circle>
                                                <path d="M8 14s1.5 2 4 2 4-2 4-2"></path>
                                                <line
                                                  x1="9"
                                                  y1="9"
                                                  x2="9.01"
                                                  y2="9"
                                                ></line>
                                                <line
                                                  x1="15"
                                                  y1="9"
                                                  x2="15.01"
                                                  y2="9"
                                                ></line>
                                              </svg>
                                              Payment initiated. Try again if
                                              you couldn't complete the payment
                                            </div>
                                          )}
                                          {status === "FAILED" && (
                                            <div class="alert alert-danger alert-dismissible fade show">
                                              <svg
                                                viewBox="0 0 24 24"
                                                width="24"
                                                height="24"
                                                stroke="currentColor"
                                                stroke-width="2"
                                                fill="none"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                class="me-2 alert-icon"
                                              >
                                                <polygon points="7.86 2 16.14 2 22 7.86 22 16.14 16.14 22 7.86 22 2 16.14 2 7.86 7.86 2"></polygon>
                                                <line
                                                  x1="15"
                                                  y1="9"
                                                  x2="9"
                                                  y2="15"
                                                ></line>
                                                <line
                                                  x1="9"
                                                  y1="9"
                                                  x2="15"
                                                  y2="15"
                                                ></line>
                                              </svg>
                                              Payment failed. Try again
                                            </div>
                                          )}
                                          {status === "SUBSCRIBED" && (
                                            <div class="alert alert-success alert-dismissible fade show">
                                              <div class="d-flex flex-column flex-md-row justify-content-between gap-2">
                                                <div class="">
                                                  {distributionProgress ? (
                                                    <div>
                                                      <p class=" text-center my-2">
                                                        Token distribution in
                                                        progress
                                                      </p>
                                                    </div>
                                                  ) : (
                                                    <div class="d-flex flex-column gap-2">
                                                      <div class="d-flex justify-content-between">
                                                        <div class="d-flex flex-column align-items-start">
                                                          <p class="text-base font-medium">
                                                            Invested
                                                          </p>
                                                          <h3 class="text-lg font-normal">
                                                            Rs{" "}
                                                            {
                                                              portfolio?.invested
                                                            }
                                                          </h3>
                                                        </div>
                                                        <div class="d-flex flex-column align-items-end">
                                                          <h4 class="text-base font-medium">
                                                            Current
                                                          </h4>
                                                          <h3 class="text-lg font-normal">
                                                            Rs{" "}
                                                            {portfolio?.current?.toFixed(
                                                              5
                                                            )}
                                                          </h3>
                                                        </div>
                                                      </div>
                                                      <div class="d-flex justify-content-between">
                                                        <div class="d-flex flex-column align-items-start">
                                                          <h4 class="text-base font-medium">
                                                            Return
                                                          </h4>
                                                          Rs{" "}
                                                          {portfolio?.pnl?.toFixed(
                                                            5
                                                          )}
                                                        </div>
                                                        <div class="d-flex flex-column align-items-end">
                                                          <h4 class="text-base font-medium">
                                                            Return in %
                                                          </h4>
                                                          Rs{" "}
                                                          {portfolio?.pnlPercent?.toFixed(
                                                            5
                                                          )}
                                                          %
                                                        </div>
                                                      </div>
                                                    </div>
                                                  )}
                                                </div>
                                              </div>
                                            </div>
                                          )}
                                        </>
                                      }

                                      <div className="flex flex-row justify-center w-[80%] md:w-[40%] mx-auto ">
                                        {singleBasket?.gasFee && (
                                          <div className="flex flex-col justify-center items-center">
                                            <h5 className="font-semibold text-sm">
                                              Estimated gas fees
                                            </h5>
                                            <h4 className="font-bold text-md">
                                              {singleBasket?.gasFeeUnit}
                                              {singleBasket?.gasFee}
                                            </h4>
                                          </div>
                                        )}

                                        {/* <div className="flex flex-col justify-center items-center">
                      <img src="/images/overvalued.svg" alt="" />
                      <h4>Overvalued</h4>
                    </div> */}
                                      </div>

                                      <div className="flex justify-center flex-row gap-5 md:gap-10 w-[100%] md:w-[50%] mx-auto my-10">
                                        {singleBasket?.volatility && (
                                          <div
                                            className="flex gap-4 rounded-lg py-2 px-5"
                                            style={{
                                              background:
                                                "linear-gradient(180deg, #E4D3FF 0%, rgba(171, 139, 224, 0.67) 100%)",
                                            }}
                                          >
                                            <img
                                              src="/images/volatile1.svg"
                                              alt=""
                                            />
                                            <h5>{singleBasket?.volatility}</h5>
                                          </div>
                                        )}

                                        {singleBasket?.risk && (
                                          <div
                                            className="flex gap-4 rounded-lg py-2 px-5"
                                            style={{
                                              background:
                                                "linear-gradient(180deg, #E4D3FF 0%, rgba(171, 139, 224, 0.67) 100%)",
                                            }}
                                          >
                                            <img
                                              src="/images/volatile2.svg"
                                              alt=""
                                            />
                                            <h5>{singleBasket?.risk}</h5>
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          )}

                          <div className="col-xl-12">
                            <div class="card coin-content">
                              <div class="card-body">
                                <div className="d-flex flex-direction-row justify-content-between ">
                                  <h4 class="card-title">History</h4>
                                  <ul class="nav nav-pills d-flex justify-content-end">
                                    {Data?.map((item) => (
                                      <li
                                        class="nav-link"
                                        role="presentation"
                                        key={item?.id}
                                      >
                                        <button
                                          onClick={() => {
                                            setSelectedHistoryTab(item?.range);
                                          }}
                                          class={`nav-link   ${
                                            selectedHistoryTab === item?.range
                                              ? "active"
                                              : ""
                                          }`}
                                          role="tab"
                                          aria-selected="true"
                                        >
                                          {item?.value}
                                        </button>
                                      </li>
                                    ))}
                                  </ul>
                                </div>
                                <div id="bitcoinhChart">
                                  <CryptoBasketAreaChart
                                    selecteddate={selectedHistoryTab}
                                    id={id}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-xl-12">
                            <div class="card coin-content">
                              <div class="card-body">
                                <div className="">
                                  <h4 class="card-title">Description</h4>
                                  {/* <p className="py-3">{singleBasket?.description ?? ""}</p> */}

                                  {sections.map((section, index) => (
                                    <p
                                      key={index}
                                      style={{ marginBottom: "1em" }}
                                    >
                                      {section}
                                    </p>
                                  ))}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-xl-3  col-sm-12 ">
                        <div class="card ">
                          <div class="card-header border-0 pb-3">
                            <h4 class="card-title">Token Allocation</h4>
                          </div>
                          <div class="card-body px-0 pt-0 dlab-scroll">
                            {singleBasket?.cryptoBasketTokens?.map((token) => (
                              <div
                                key={token?.tokenId}
                                class="d-flex justify-content-between align-items-center market-preview"
                              >
                                <div class="d-flex align-items-center">
                                  <span>
                                    <img
                                      src={token?.icon}
                                      alt=""
                                      style={{ width: "36px", height: "36px" }}
                                      className="rounded-circle me-1"
                                    />
                                  </span>
                                  <div class="ms-3">
                                    <a href="javascript:void(0);">
                                      <h5 class="fs-14 font-w600 mb-0">
                                        {token?.token} ({token?.symbol})
                                      </h5>
                                    </a>
                                  </div>
                                </div>
                                <div class="d-flex align-items-center">
                                  <div class="ms-3">
                                    <span class="text-success">
                                      {token?.tokenPercent}%
                                    </span>
                                  </div>
                                </div>
                              </div>
                            ))}

                            <div className="d-flex justify-content-center mt-3">
                              <button
                                onClick={() => {
                                  setopenBasketModal(true);
                                }}
                                type="button"
                                className="btn btn-success d-flex align-items-center justify-content-between"
                              >
                                Invest
                                <FontAwesomeIcon
                                  icon={faArrowTrendUp}
                                  style={{ transform: "rotate(90deg)" }}
                                />
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      <BasketModal
        openModal={openBasketModal}
        setOpenModal={setopenBasketModal}
        closeClicked={closeClicked}
        setCloseClicked={setCloseClicked}
        singleBasket={singleBasket}
        tokenPercentArray={tokenPercentArray}
        tokenArray={tokenArray}
        successcloseClicked={successcloseClicked}
        setSuccessCloseClicked={setSuccessCloseClicked}
        openSuccessModal={openSuccessModal}
        setopenSuccessModal={setopenSuccessModal}
        transactionSuccess={transactionSuccess}
        setTransactionSuccess={setTransactionSuccess}
        fees={fees}
        setOpenContinueInWebModal={setOpenContinueInWebModal}
        subscribeId={subscribeId}
        status={status}
        basketId={id}
        basketBlockchain={singleBasket?.blockchain}
      />
    </MainWrapper>
  );
};

export default CryptoBasketDetails;
