import React from "react";

const PortfolioTopCard = ({ data }) => {
  let TotalReturn;
  let Return;
  let current = 0;
  let investment = 0;

  console.log("portfolio data", data);

  if (data?.length > 0) {
    data.map((item) => (current += item.balance));
    data.map((item) => (investment += item.investment));
  }

  const CagrValue =
    data?.length > 0 && investment > 0
      ? (((current - investment) / investment) * 100)?.toFixed(2)
      : "0";
  const returnsValue =
    data?.length > 0 && investment > 0
      ? (current - investment)?.toFixed(2)
      : "0";
  return (
    <div class="market-coin flex-wrap">
      <div class="d-flex align-items-center coin-box">
        <div class="">
          <span class="fs-14 font-w400">Balance</span>
          <a href="javascript:void(0);">
            <h4 class="font-w600 mb-0"> INR {current?.toFixed(2)}</h4>
          </a>
        </div>
      </div>
      <div class="coin-box">
        <span class="mb-1 d-block">Invested</span>
        <div class="d-flex align-items-center">
          <h5 class="font-w600 m-0 "> INR {investment?.toFixed(2)}</h5>
        </div>
      </div>
      <div class="coin-box">
        <span class="mb-1 d-block">return %</span>
        <h5
          class={`font-w600 m-0 ${
            CagrValue > 0 ? "text-success" : "text-danger"
          }`}
        >
          {CagrValue} %
        </h5>
      </div>
      <div class="coin-box">
        <span class="mb-1 d-block">return</span>
        <h5
          class={`font-w600 m-0 ${
            returnsValue > 0 ? "text-success" : "text-danger"
          }`}
        >
          {" "}
          Rs {returnsValue}
        </h5>
      </div>
    </div>
  );
};

export default PortfolioTopCard;
