import { faArrowDown, faArrowUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { appActions } from "../../context/app-slice";
import { red } from "@mui/material/colors";
import { useLocation } from "react-router-dom";
import { Line } from "peity-react";
import CryptoFundLineChart from "./CryptoFundLineChart";

const AllCryptoFundCard = ({ item, allFundsStats }) => {
  const appCtx = useSelector((state) => state.app);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  let fundsCagr = allFundsStats[item?.fundId]?.cagr;
  let fundsOneDayGrowth = allFundsStats[item?.fundId]?.oneDayGrowth;
  let fundsSubscriberCount = allFundsStats[item?.fundId]?.subscriberCount;
  let fundsTokenPrice = allFundsStats[item?.fundId]?.tokenPrice;
  let fundstvl = allFundsStats[item?.fundId]?.tvl;

  return (
    <div
      class="d-flex justify-content-between align-items-center  market-preview"
      style={{ cursor: "pointer" }}
      onClick={() => navigate(`/crypto-fund/${item?.fundId}`)}
    >
      <div class="w-100">
        <div class="row">
          <div className="col-12 col-md-8 d-flex align-items-center justify-content-between flex-wrap pt-2 pt-md-0">
            <div class="d-flex align-items-center  col-12 col-md-5">
              <span>
                <img
                  src={item?.icon}
                  alt=""
                  style={{ width: "36px", height: "36px" }}
                  className="rounded-circle m-0 p-0"
                />
              </span>
              <div class="ms-3">
                <h5 class="fs-14 font-w600 mb-0">
                  {item?.fundName && item?.fundName?.length > 25
                    ? `${item?.fundName.substring(0, 25)}...`
                    : item?.fundName}
                </h5>
                <h6 class="fs-12 font-w400 mb-0">
                  by {item?.managedBy ? item?.managedBy : "--"}
                </h6>
              </div>
            </div>
            <div class="d-flex  ">
              <div class="">
                <p className=" fs-14 font-w600 text-center mb-0">
                  {fundstvl ? `$${fundstvl}` : "--"}
                </p>
                <h5 class="fs-12 font-w400 mb-0  text-center">AUM</h5>
              </div>
            </div>
            <div class="d-flex  ">
              <div class="">
                <p className="fs-14 font-w600 text-center mb-0">
                  {fundsSubscriberCount || "--"}
                </p>
                <h5 class="fs-12 font-w400 mb-0  text-center">Subscribers</h5>
              </div>
            </div>
            <div class="d-flex  ">
              <div class="fs-14 font-w600">
                <p className={`mb-0 text-center`}>
                  {fundsTokenPrice ? `$${fundsTokenPrice}` : "--"}
                </p>

                <h5 class="fs-12 font-w400 mb-0  text-center">Price</h5>
              </div>
            </div>
          </div>

          <div className="col-12 col-md-4 d-flex align-items-center justify-content-between">
            <div
              className="vertical-line-bg  d-none d-md-block"
              style={{
                height: "40px",
                width: "1px",
              }}
            ></div>

            <CryptoFundLineChart prices={item?.prices ? item?.prices : []} />
            <div class="">
              <p
                className={`${
                  fundsCagr > 0 ? "text-success" : "text-danger"
                } mb-0 text-center fs-14 font-w600`}
              >
                {fundsCagr || "--"}%
              </p>
              <h5 class="fs-12 font-w400 mb-0  text-center">CAGR</h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllCryptoFundCard;
