import axios from "axios";
import { useCallback, useState } from "react";
import { useSelector } from "react-redux";

export const usePortfolio = () => {
  const appCtx = useSelector((state) => state.app);

  const defaultHeaders = {
    "X-App-Token": process.env.REACT_APP_X_APP_TOKEN,
    "Content-Type": "application/json",
  };

  if (appCtx.authToken !== "") {
    defaultHeaders["X-Auth-Token"] = appCtx.authToken;
  }

  const GetPortfolio = (setPortfolioLoading, setPortfolioData) => {
    setPortfolioLoading(true);
    let config = {
      url: `${process.env.REACT_APP_URL_BLOCKCHAIN_SERVICE}/user/portfolio`,
      method: "get",
      headers: defaultHeaders,
    };

    axios(config)
      .then((response) => {
        console.log("Portfolio Fetched");
        console.log(response.data);
        setPortfolioData(response.data);
        setPortfolioLoading(false);
      })
      .catch(function (error) {
        console.log("Error in fetching portfolio: ", error);
        setPortfolioLoading(false);
      });
  };

  const GetSubscribedBasketList = (setBasketList, setBasketLoading) => {
    setBasketLoading(true);
    let config = {
      url: `${process.env.REACT_APP_URL_BLOCKCHAIN_SERVICE}/crypto/basket/subscribed/list`,
      method: "get",
      headers: defaultHeaders,
    };

    axios(config)
      .then(function (response) {
        setBasketLoading(false);
        if (response.data) {
          setBasketList(response.data);
        }
      })
      .catch(function (error) {
        console.log("Error in fetching public basket: ", error);
        setBasketLoading(false);
      });
  };
  const GetSubscribedCryptoFundList = (
    setSubscribedCryptoFundList,
    setCryptoFundLoading
  ) => {
    setCryptoFundLoading(true);
    let config = {
      url: `${process.env.REACT_APP_URL_BLOCKCHAIN_SERVICE}/crypto/fund/subscribed/list`,
      method: "get",
      headers: defaultHeaders,
    };

    axios(config)
      .then(function (response) {
        setCryptoFundLoading(false);
        if (response.data) {
          setSubscribedCryptoFundList(response.data);
        }
      })
      .catch(function (error) {
        console.log("Error in fetching public basket: ", error);
        setCryptoFundLoading(false);
      });
  };
  const isUserSubscribed = (
    fundId,
    setSubscribeStatus,
    setIsSubscribedToCryptoFundLoading,
    setSubscribedFundData
  ) => {
    setIsSubscribedToCryptoFundLoading(true);
    let config = {
      url: `${process.env.REACT_APP_URL_BLOCKCHAIN_SERVICE}/crypto/fund/${fundId}/subscribed`,
      method: "get",
      headers: defaultHeaders,
    };

    axios(config)
      .then(function (response) {
        setIsSubscribedToCryptoFundLoading(false);
        console.log(
          "loggin the response from issubscribed api",
          response?.data
        );

        setSubscribeStatus(response?.data?.status);
        setSubscribedFundData(response?.data);
      })
      .catch(function (error) {
        console.log("Error in calling subscribe api: ", error);
        setIsSubscribedToCryptoFundLoading(false);
      });
  };

  return {
    GetPortfolio,
    GetSubscribedBasketList,
    GetSubscribedCryptoFundList,
    isUserSubscribed,
  };
};
