import { faArrowDown, faArrowUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { appActions } from "../../context/app-slice";
import { red } from "@mui/material/colors";
import { useLocation } from "react-router-dom";
import { getPriceInDecimal } from "../../config";

const TokenCard = ({ coin, allCoinPrice, usersToken, showUSDTPair }) => {
  const appCtx = useSelector((state) => state.app);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const isWalletRoute = location.pathname === "/wallet";
  const isTokensRoute = location.pathname === "/tokens";

  let price = allCoinPrice[coin?.symbol]?.price;
  let tokenprice = getPriceInDecimal(price);

  let usersCryptTokens =
    usersToken &&
    usersToken.some(
      (singletoken) =>
        singletoken.token === coin.symbol &&
        singletoken.blockchain === coin.blockchain
    )
      ? usersToken
          .filter(
            (singletoken) =>
              singletoken.blockchain === coin.blockchain &&
              singletoken.token === coin.symbol
          )
          .map(
            (singletoken) =>
              `(${singletoken?.balance?.toFixed(4)})
                                  `
          )
      : 0;

  return (
    <div
      class="d-flex justify-content-between align-items-center market-preview  py-3"
      style={{ cursor: "pointer" }}
      onClick={() => {
        navigate(`/token/${coin?.tokenId}/${coin?.symbol}`);
        dispatch(
          appActions.setSingleTokenData({
            tokenName: coin.tokenName,
            tokenSymbol: coin?.symbol,
            priceOfToken: tokenprice,
          })
        );
      }}
    >
      <div class="d-flex align-items-center">
        <span>
          <img
            src={coin.tokenIcon}
            alt=""
            style={{ width: "36px", height: "36px" }}
          />
        </span>
        <div class="ms-3">
          <a href="javascript:void(0);">
            <h5 class="fs-14 font-w600 mb-0">
              {coin.symbol}
              {showUSDTPair && (
                <span className="" style={{ color: "#A9A9A9" }}>
                  .USDT
                </span>
              )}
            </h5>
          </a>
          <h6 class="fs-12 font-w400">
            {(usersCryptTokens <= 0 && isWalletRoute) || isTokensRoute ? (
              coin?.tokenName
            ) : (
              <>
                {" "}
                {"$"}
                {allCoinPrice && usersToken
                  ? isNaN(allCoinPrice[coin.symbol]?.price)
                    ? "0.0000"
                    : (
                        allCoinPrice[coin.symbol]?.price *
                        usersToken
                          .filter(
                            (singletoken) =>
                              singletoken.blockchain === coin.blockchain &&
                              singletoken.token === coin.symbol
                          )
                          .map((singletoken) => singletoken?.balance)
                      )?.toFixed(4)
                  : "0.0000"}
                {usersCryptTokens}
              </>
            )}
          </h6>
        </div>
      </div>
      <div class="d-flex  ">
        <div class="">
          <h5 class="fs-14 font-w600 mb-0  text-end">$ {tokenprice}</h5>

          {allCoinPrice && (
            <span
              className={`${
                allCoinPrice[coin.symbol]?.priceChange > 0
                  ? "text-success"
                  : "text-danger"
              }  text-end`}
            >
              <FontAwesomeIcon
                icon={
                  allCoinPrice[coin.symbol]?.priceChange > 0
                    ? faArrowUp
                    : faArrowDown
                }
              />
              {allCoinPrice[coin.symbol]?.priceChange?.toFixed(5)}
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

export default TokenCard;
