import React, { useEffect, useState } from "react";
import { useCryptolens } from "./useCryptolens";
import { CircularProgress } from "@mui/material";
import PermissionModal from "./PermissionModal";
import { Button } from "react-bootstrap";

const Banner = ({ openlensModal, setopenlensModal }) => {
  const [openPermissionModal, setOpenPermissionModal] = useState(false);
  const [permissionGranted, setPermissionGranted] = useState(false);
  const {
    subscribed,
    setSubscribed,
    success,
    setSuccess,
    closeClicked,
    setCloseClicked,
    loading,
    setLoading,
    postloading,
    setPostLoading,
    content,
    setContent,
    isSubscribed,
    handleUnSubscribe,
    fetchPostContent,
  } = useCryptolens();

  useEffect(() => {
    isSubscribed();
  }, [openlensModal]);
  useEffect(() => {
    if (permissionGranted) {
      handleUnSubscribe();
    }
  }, [openPermissionModal]);
  return (
    <>
      {!subscribed && !loading ? (
        <div className="d-flex flex-column flex-md-row">
          <div
            className="w-100 w-md-50 text-white"
            style={{
              backgroundColor: "#c74eda",
              backgroundImage:
                "linear-gradient(43deg, #c74eda 0%, #8655ae 46%, #a063d6 100%)",
            }}
          >
            <div className="px-5 py-5">
              <h1 className="mb-5 text-white">Crypto Lens</h1>
              <h3 className="mb-5 text-white">
                Learn How to Evaluate Crypto Assets
              </h3>
              <h5 className="mb-4 text-white">
                Become an Expert on the Crypto assets and follow the market with
                our quick updates
              </h5>

              <div>
                <button
                  onClick={() => {
                    setopenlensModal(true);
                  }}
                  className=" rounded-pill text-white btn btn-primary px-5"
                >
                  {loading ? (
                    <CircularProgress size={20} className="ms-1" />
                  ) : (
                    "Subscribe"
                  )}
                </button>
              </div>
            </div>
          </div>
          <div
            className="w-100 w-md-50"
            style={{
              background: "url(/images/cryptolens/btc.jpg)",
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              // backgroundAttachment: "fixed",
            }}
          >
            <></>
          </div>
        </div>
      ) : (
        <div className="d-flex justify-content-end ">
          <button
            onClick={() => {
              setOpenPermissionModal(true);
            }}
            className=" rounded-pill text-white btn btn-danger "
          >
            {loading ? (
              <CircularProgress size={20} className="ms-1" />
            ) : (
              "Unsubscribe"
            )}
          </button>
        </div>
      )}

      <PermissionModal
        openModal={openPermissionModal}
        setOpenModal={setOpenPermissionModal}
        setPermissionGranted={setPermissionGranted}
      />
    </>
  );
};

export default Banner;
