import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector } from "react-redux";
import React from "react";

const SocialButtons = ({ icon, name, url }) => {
  const appCtx = useSelector((state) => state.app);
  const themedColor = {
    color: `${appCtx?.isDarkMode ? "white" : "black"}`,
  };
  return (
    <div
      className="rounded-pill px-4 "
      style={{
        backgroundColor: "#EFF2F5",
        color: "black",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: "5px",
        cursor: "pointer",
      }}
      onClick={() => {
        window.open(url, "__self");
      }}
    >
      <FontAwesomeIcon icon={icon} className=""
    //    style={themedColor} 
       />
      <p className="mb-0">{name}</p>
    </div>
  );
};

export default SocialButtons;
