import { useNavigate } from "react-router-dom";
import "../navbar/Navbar.css";
import { useDispatch, useSelector } from "react-redux";

import useAuthorizedHttp from "../../hooks/use-authorized-http";
import { appActions } from "../../context/app-slice";
import { persistor } from "../../context/store";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClipboardQuestion,
  faLink,
  faRightFromBracket,
} from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import { getBlockchainAddressUrl } from "../../config";

const ProfileDropdown = ({
  currencyvisible,
  setCurrencyVisible,
  setOpenSupportModal,
  setOpenAccountDetailsModal,
  setOpenPrivateKeyLoginModal,
  setOpenPrivateKeyPasswordModal,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const makeRequest = useAuthorizedHttp();

  useState(false);
  const appCtx = useSelector((state) => state.app);
  const dark = false;
  const label = { inputProps: { "aria-label": "Color switch demo" } };

  const address = appCtx.walletAddress.find(
    (addr) => addr.blockchain === appCtx.blockchain
  )?.address;

  const handleLogout = () => {
    dispatch(appActions.logout(undefined));
    dispatch(appActions.updateAuthToken(undefined));
    dispatch(appActions.setWalletAddress([{ address: "" }]));
    dispatch(
      appActions.updateUserDetails({
        userId: "",
        email: "",
        name: "",
      })
    );

    dispatch(appActions.setCountry(""));
    dispatch(appActions.setDocument(""));
    dispatch(appActions.setFrontSide(""));
    dispatch(appActions.setBackSide(""));
    dispatch(appActions.setPicture(""));
    dispatch(appActions.setVerified(false));

    dispatch(appActions.setPassword(""));
    dispatch(appActions.setRewardData({}));
    dispatch(appActions.setMetaData(""));
    dispatch(appActions.setCustodial(""));

    dispatch(appActions.setMnemonicCode('""'));
    dispatch(appActions.setAlgoMnemonicCode(""));
    dispatch(appActions.setEvmMnemonicCode(""));
    dispatch(appActions.setSolanaMnemonicCode(""));
    dispatch(appActions.setEncrypt(""));
    dispatch(appActions.setAlgoEncrypt(""));
    dispatch(appActions.setEvmEncrypt(""));
    dispatch(appActions.setSolanaEncrypt(""));
    // localStorage.clear();
    persistor.purge().then(() => {
      navigate(`/`);
    });
    navigate("/login");
  };
  const themedColor = `${appCtx?.isDarkMode ? "white" : "black"}`;
  return (
    <div
      className={`d-flex flex-column gap-4 border `}
      style={{
        backgroundColor: `${appCtx?.isDarkMode ? "#09153F" : "#FFFFFF"}`,
        color: themedColor,
      }}
    >
      <div className="d-flex flex-column">
        <div className="px-1 pt-4 pb-2">
          <div className="d-flex flex-column rounded-lg overflow-hidden">
            <div className="d-flex flex-column ">
              <>
                <div
                  className="d-flex gap-2 align-items-center px-3 "
                  onClick={() => {
                    const blockchain = appCtx?.blockchain;

                    const blockchainLink = getBlockchainAddressUrl(
                      blockchain,
                      address
                    );
                      
                    if (blockchainLink !== "") {
                      window.open(blockchainLink);
                      setCurrencyVisible(false);
                    }
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <FontAwesomeIcon
                    icon={faLink}
                    style={{
                      color: themedColor,
                    }}
                  />

                  <p
                    className="mb-0"
                    onMouseOver={(e) => {
                      e.target.style.color = "blue";
                    }}
                    onMouseOut={(e) => {
                      e.target.style.color = "";
                    }}
                  >
                    View account on {appCtx.blockchain} chain
                  </p>
                </div>
                <div className="border-bottom border-gray-400 my-2"></div>
              </>

              {/* support  */}

              <>
                <div
                  className="d-flex gap-2 align-items-center px-3"
                  onClick={() => {
                    setOpenSupportModal(true);
                    setCurrencyVisible(false);
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <FontAwesomeIcon
                    icon={faClipboardQuestion}
                    style={{
                      color: themedColor,
                    }}
                  />

                  <p
                    className="mb-0"
                    onMouseOver={(e) => {
                      e.target.style.color = "blue";
                    }}
                    onMouseOut={(e) => {
                      e.target.style.color = "";
                    }}
                  >
                    Support
                  </p>
                </div>
                <div className="border-bottom border-gray-400 my-2"></div>
              </>

              {/* account details  */}

              <>
                <div
                  className="d-flex gap-2 align-items-center px-3"
                  onClick={() => {
                    setOpenAccountDetailsModal(true);
                    setCurrencyVisible(false);
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <FontAwesomeIcon
                    icon={faClipboardQuestion}
                    style={{
                      color: themedColor,
                    }}
                  />

                  <p
                    className="mb-0"
                    onMouseOver={(e) => {
                      e.target.style.color = "blue";
                    }}
                    onMouseOut={(e) => {
                      e.target.style.color = "";
                    }}
                  >
                    Account Details
                  </p>
                </div>
                <div className="border-bottom border-gray-400 my-2"></div>
              </>

              {/* Private Keys  */}
              <>
                <div
                  className="d-flex gap-2 align-items-center px-3"
                  onClick={() => {
                    if (appCtx.custodial === true) {
                      setOpenPrivateKeyLoginModal(true);
                      setCurrencyVisible(false);
                    } else {
                      setOpenPrivateKeyPasswordModal(true);
                      setCurrencyVisible(false);
                    }
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <FontAwesomeIcon
                    icon={faRightFromBracket}
                    style={{
                      color: themedColor,
                    }}
                  />

                  <p
                    className="mb-0"
                    onMouseOver={(e) => {
                      e.target.style.color = "blue";
                    }}
                    onMouseOut={(e) => {
                      e.target.style.color = "";
                    }}
                  >
                    Private key
                  </p>
                </div>
                <div className="border-bottom border-gray-400 my-2"></div>
              </>
              {/* logout  */}
              <>
                <div
                  className="d-flex gap-2 align-items-center px-3"
                  onClick={() => {
                    setCurrencyVisible(false);
                    handleLogout();
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <FontAwesomeIcon
                    icon={faRightFromBracket}
                    style={{
                      color: themedColor,
                    }}
                  />

                  <p
                    className="mb-0"
                    onMouseOver={(e) => {
                      e.target.style.color = "blue";
                    }}
                    onMouseOut={(e) => {
                      e.target.style.color = "";
                    }}
                  >
                    Logout
                  </p>
                </div>
                <div className="border-bottom border-gray-400 my-2"></div>
              </>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileDropdown;
