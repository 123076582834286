import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Navigate, Route, Routes } from "react-router-dom";

import { ToastContainer } from "react-toastify";
import Market from "../../pages/Market";
import Login from "../../pages/Login";
import CryptoBasket from "../../pages/cryptobasket/CryptoBasket";
import CryptoBasketDetails from "../../pages/cryptobasket/CryptoBasketDetails";
import CryptoFundDetails from "../../pages/cryptoFund/CryptoFundDetails";
import Tokens from "../../pages/Tokens/Tokens";
import { TokenDetailsPage } from "../../pages/Tokens/TokenDetailsPage";
import Wallets from "../../pages/Wallets/Wallets";
import UsersDashboard from "../../pages/dashboard/UsersDashboard";
import UsersCryptoFund from "../../pages/cryptoFund/UsersCryptoFund";
import PortFolio from "../../pages/portfolio/PortFolio";
import History from "../../pages/History/History";
import CryptolensComponet from "../../pages/cryptoLens/CryptolensComponet";
import Airdrop from "../Airdrop/Airdrop";
import AirdropDetails from "../Airdrop/AirdropDetails";

export const RouterComponent = () => {
  const appCtx = useSelector((state) => state.app);

  useEffect(() => {
    let link = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement("link");
      link.rel = "shortcut icon";
      document.getElementsByTagName("head")[0].appendChild(link);
    }
    link.href = "/images/talelogo.png";
  }, []);

  console.log("appCtx.isLoggedIn", appCtx.isLoggedIn);

  return (
    <>
      <Routes>
        <Route
          path="/"
          element={
            !appCtx.isLoggedIn ? <Navigate to={"/login"} /> : <UsersDashboard />
          }
        />
        <Route
          path="/crypto-fund"
          element={
            !appCtx.isLoggedIn ? (
              <Navigate to={"/login"} />
            ) : (
              <UsersCryptoFund />
            )
          }
        />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Login />} />

        {/* Market Route */}
        <Route
          path="/market"
          element={!appCtx.isLoggedIn ? <Navigate to="/login" /> : <Market />}
        />

        {/* Crypto Basket Route */}
        <Route
          path="/crypto-basket"
          element={
            !appCtx.isLoggedIn ? <Navigate to="/login" /> : <CryptoBasket />
          }
        />

        {/* Portfolio Route */}
        <Route
          path="/portfolio"
          element={
            !appCtx.isLoggedIn ? <Navigate to="/login" /> : <PortFolio />
          }
        />

        {/* Transactions History Route */}
        <Route
          path="/transactions"
          element={!appCtx.isLoggedIn ? <Navigate to="/login" /> : <History />}
        />

        {/* Wallet Route */}
        <Route
          path="/wallet"
          element={!appCtx.isLoggedIn ? <Navigate to="/login" /> : <Wallets />}
        />

        {/* Crypto Basket Details Route with parameters */}
        <Route
          path="/crypto-basket/:id"
          element={
            !appCtx.isLoggedIn ? (
              <Navigate to="/login" />
            ) : (
              <CryptoBasketDetails />
            )
          }
        />

        {/* Crypto Fund Details Route with parameters */}
        <Route
          path="/crypto-fund/:id"
          element={
            !appCtx.isLoggedIn ? (
              <Navigate to="/login" />
            ) : (
              <CryptoFundDetails />
            )
          }
        />

        {/* Cryptolens Route */}
        <Route
          path="/crypto-lens"
          element={
            !appCtx.isLoggedIn ? (
              <Navigate to="/login" />
            ) : (
              <CryptolensComponet />
            )
          }
        />

        {/* Cryptolens Route (probably intended path) */}
        <Route
          path="/cryptoLens"
          element={
            !appCtx.isLoggedIn ? (
              <Navigate to="/login" />
            ) : (
              <CryptolensComponet />
            )
          }
        />

        {/* Airdrop Route */}
        <Route
          path="/airdrop"
          element={!appCtx.isLoggedIn ? <Navigate to="/login" /> : <Airdrop />}
        />

        {/* Airdrop Details with parameters */}
        <Route
          path="/airdrop/:id"
          element={
            !appCtx.isLoggedIn ? <Navigate to="/login" /> : <AirdropDetails />
          }
        />
        <Route
          path="/tokens"
          element={!appCtx.isLoggedIn ? <Navigate to={"/login"} /> : <Tokens />}
        ></Route>
        <Route
          path="/token/:tokenid/:tokenname"
          element={
            !appCtx.isLoggedIn ? (
              <Navigate to={"/login"} />
            ) : (
              <TokenDetailsPage />
            )
          }
        ></Route>
      </Routes>
      <ToastContainer
        position="bottom-left"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable={false}
        pauseOnHover
        theme={appCtx.isDarkMode ? "dark" : "light"}
        toastStyle={
          appCtx.isDarkMode
            ? {
                backgroundColor: "#27272A",
                color: "#E2E8F0",
              }
            : { backgroundColor: "#F8FAFC", color: "#1F2937" }
        }
      />
    </>
  );
};
