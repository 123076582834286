import React, { useEffect, useState } from "react";

import MainWrapper from "../../component/wrapper/MainWrapper";
import { useWalletView } from "../../hooks/useWalletView";
import { CircularProgress, Skeleton, Stack } from "@mui/material";
import { useDispatch } from "react-redux";
import { appActions } from "../../context/app-slice";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import useAuthorizedHttp from "../../hooks/use-authorized-http";
import { useCryptobasket } from "../cryptobasket/useCryptobasket";
import { useCryptoFund } from "../cryptoFund/useCryptoFund";
import HomeCryptoFundCard from "../cryptoFund/HomeCryptoFundCard";
import HomeCryptoBasketCard from "../cryptobasket/HomeCryptoBasketCard";
import HomeTokenCard from "./HomeTokenCard";
import { usePortfolio } from "../portfolio/usePortfolio";
import PortfolioTopCard from "../portfolio/PortfolioTopCard";
import TaleWalletOnRampModal from "../../component/TaleWalletOnRamp/TaleWalletOnRampModal";
import SendAlgosModal from "../../component/BuyAndSellAlgos/sendAlgos/SendAgosModal";
import PaymentStatusLoader from "../../component/paymentStatusLoader/PaymentStatusLoader";
import ModalPaymentFailure from "../../component/modal-payment-failure/ModalPaymentFailure";
import ModalPaymentSuccess from "../../component/modal-payment-success/ModalPaymentSuccess";
import SwapModal from "../../component/swap/SwapModal";
import TaleWalletOffRampModal from "../../component/TaleWalletOffRamp/TaleWalletOffRampModal";
import { nativeToken } from "../../config";
import { useCirclePayment } from "../../component/paymnetgateways/CircleCli/useCirclePayment";
import HomeBannerCard from "../../component/homeBannerCard/HomeBannerCard";
import { useMetamask } from "../../hooks/useMetamask";
import MetamaskSwapSuccessModal from "../../component/metamaskSwapSuccess/MetamaskSwapSuccessModal";
// import SwapSuccessModal from "../../component/swap/SwapSuccessModal";
import SwapSuccessModal from "../../component/orderbook/SwapSuccessModal";
import { useCryptolens } from "../cryptoLens/useCryptolens";

const UsersDashboard = () => {
  const [wallet, setWallet] = useState([{ address: "" }]);
  const [selectedFund, setSelectedFund] = useState();
  const [selectedBasket, setSelectedBasket] = useState();

  const [selectedHistoryTab, setSelectedHistoryTab] = useState("week");
  const [selectedFundTab, setSelectedFundTab] = useState("w");
  const [composition, setComposition] = useState([]);
  const [portfolioLoading, setPortfolioLoading] = useState(false);
  const [openTransferSuccessModal, setOpenTransferSuccessModal] =
    useState(false);
  const [transactionData, setTransactionData] = useState(null);
  const [pausePriceApiCall, setPausePriceApiCall] = useState(false);
  const [openAddTokenModal, setOpenAddTokenModal] = useState(false);
  const [openSendAlgosModal, setOpenSendAlgosModal] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);
  const [openTalewalletOnrampModal, setOpenTalewalletOnrampModal] =
    useState(false);
  const [openOfframpModal, setOpenOfframpModal] = useState(false);
  const [portfolioData, setPortfolioData] = useState([]);

  const [isMetamaskSwapPending, setIsMetamaskSwapPending] = useState(null);
  const [isMetamaskSwapConfirming, setIsMetamaskSwapConfirming] =
    useState(null);
  const [isMetamaskSwapConfirmed, setIsMetamaskSwapConfirmed] = useState(null);
  const [metmaskTransactionHash, setMetmaskTransactionHash] = useState(null);
  const [openMetamaskSwapSuccessModal, setOpenMetamaskSwapSuccessModal] =
    useState(false);
  const [sendTokenId, setSendTokenId] = useState("");
  const [receiveTokenId, setReceiveTokenId] = useState("");

  useEffect(() => {
    if (
      isMetamaskSwapPending ||
      isMetamaskSwapConfirming ||
      isMetamaskSwapConfirmed
    ) {
      setOpenMetamaskSwapSuccessModal(true);
    }
  }, [
    isMetamaskSwapPending,
    isMetamaskSwapConfirming,
    isMetamaskSwapConfirmed,
  ]);

  const {
    allCryptoFund,
    allCryptoFundLoading,
    fetchAllCryptoFund,
    allCryptoExperts,
    allCryptoExpertsLoading,
    getAllCrypotExperts,
    fetchAllCryptoFundsStats,
    allFundsStats,
    allCryptoFundsStatsLoading,
  } = useCryptoFund();
  const {
    openlensModal,
    setopenlensModal,
    subscribed,
    setSubscribed,
    success,
    setSuccess,
    closeClicked,
    setCloseClicked,
    loading,
    setLoading,
    postloading,
    setPostLoading,
    content,
    setContent,
    isSubscribed,
    handleUnSubscribe,
    fetchPostContent,
  } = useCryptolens();

  const {
    token,
    accountAsset,
    setAccountAsset,
    amount,
    setAmount,
    assetUrl,
    setAssetUrl,
    handleOptIn,
    showAssets,
    appCtx,
    optedIn,
    taleAmount,
    showTaleData,
    optInSuccessfull,
    setOptInSuccessfull,
    handleCustodialOptIn,
    minBalance,
    getAssetsById,
    assetLoader,
    allCoin,
    allCoinPrice,
    usersToken,
    isAssetLoading,
    setIsAssetLOading,
    isCoinLoading,
    isCoinPriceLOading,
    otheraccountAsset,
    otherassetLoader,
    openSwapModal,
    setopenSwapModal,
    openSellModal,
    setopenSellModal,
    getAllCoin,
    getAllCoinPrice,
    getUsersToken,
    totalpage,
    handleRefresh,
    setAllcoinPrice,
    listedToken,
    listedTokenLoading,
    getListedTokens,
  } = useWalletView();
  const {
    openModalPaymentFailure,
    openModalPaymentSuccess,
    setOpenModalPaymentFailure,
    setOpenModalPaymentSuccess,
    paymentStatusLoader,
    handleCirclePayment,
    getUserCardList,
    handleCountryCode,
  } = useCirclePayment();

  const {
    fetchPublicBasket,
    publicBasket,
    publicBasketLoading,
    openBasketModal,
    setopenBasketModal,
  } = useCryptobasket();

  const {
    GetPortfolio,
    GetSubscribedBasketList,
    GetSubscribedCryptoFundList,
    isUserSubscribed,
  } = usePortfolio();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const makeAuthorizedRequest = useAuthorizedHttp();
  const { switchChainandOpenModal } = useMetamask();

  useEffect(() => {
    getAllCoin(0, 60);
    getAllCoinPrice();
    getUsersToken();
    fetchAllCryptoFund();
    fetchPublicBasket();
    GetPortfolio(setPortfolioLoading, setPortfolioData);
    getListedTokens();
  }, [appCtx.blockchain]);

  // fetching usd price
  useEffect(() => {
    makeAuthorizedRequest(
      {
        url: `${process.env.REACT_APP_URL_BLOCKCHAIN_SERVICE}/token/quote`,
        data: {
          fiatAmount: 5000,
          token: "USDC",
          network: "BSC",
        },
        method: "post",
        headers: {
          "X-App-Token": process.env.REACT_APP_X_APP_TOKEN,
          "Content-Type": "application/json",
        },
      },
      (data) => {
        dispatch(appActions.setUsdPrice(data?.data?.rate));
      },
      () => {
        //  setReceiveAmountLoading(false);
      },
      (error) => {
        //  setReceiveAmountLoading(false);
      }
    );
  }, []);

  // wallet setup
  // wallet setup
  useEffect(() => {
    // if (appCtx.walletAddress[0]?.address!==='loading') {
    if (appCtx.authToken) {
      let config = {
        url: `${process.env.REACT_APP_URL_BLOCKCHAIN_SERVICE}/user/blockchain/account`,
        method: "get",
        headers: {
          "Content-Type": "application/json",
          "X-Auth-Token": appCtx.authToken,
          // "X-App-Token": process.env.REACT_APP_X_APP_TOKEN
        },
      };
      axios(config)
        .then((response) => {
          let data = response.data;
          const walletData = data?.filter(
            (account) => account.wallet === "TALEWALLET"
          );
          let blockchain =
            appCtx?.blockchain === "ALL" ? "BSC" : appCtx?.blockchain;
          let isCustodialWallet = walletData?.find(
            (obj) => obj.blockchain === blockchain
          )?.custodial;
          dispatch(appActions.setCustodial(isCustodialWallet));
          setWallet(walletData);
          dispatch(appActions.setWalletAddress(walletData));
          // if (walletData?.length === 0) {
          //   setOpenFirstUSerWalletScreen(true);
          // }

          // setLoading(false);
        })
        .catch((error) => {
          if (error?.response?.status === 401) {
            navigate("/login");
          }
          // setLoading(false);
        });
    }
  }, [appCtx.authToken, dispatch, makeAuthorizedRequest]);

  useEffect(() => {
    if (wallet && wallet.length === 0) {
      makeAuthorizedRequest(
        {
          url: `${process.env.REACT_APP_URL_BLOCKCHAIN_SERVICE}/user/blockchain/wallet/setup`,
          method: "post",
          data: {
            blockchain: "BSC",
            wallet: "TALEWALLET",
            marketplaceAddress: "0",
          },
        },
        (data) => {
          setTimeout(() => {
            setWallet([data]);
            dispatch(appActions.setCustodial(data.custodial));
            dispatch(appActions.setWalletAddress([data]));
            // dispatch(appActions.setBlockChain(data.blockchain));
            dispatch(appActions.setBlockChain("ALL"));
            navigate("/welcome");
          }, 1000);
        },
        (data) => {},
        () => {
          // setWalletCreationProgress(2);
        }
      );
    } else {
      setTimeout(() => {
        //  dispatch(appActions.setBlockChain("ALL"));
      }, 1000);
    }
  }, [makeAuthorizedRequest, wallet]);

  useEffect(() => {
    let fundIds = allCryptoFund?.map((item) => item.fundId);
    console.log("fundIds", fundIds);
    if (fundIds?.length !== 0) {
      fetchAllCryptoFundsStats(fundIds);
    }
  }, [allCryptoFund?.length]);
  console.log("allFundsStats", allFundsStats);

  console.log("allCryptoExperts", allCryptoExperts);

  // setting role for test
  useEffect(() => {
    // dispatch(appActions.setRole("user"));
    // dispatch(appActions.setRole("expert"));
  }, []);

  // console.log("isMetamaskSwapPending", isMetamaskSwapPending);
  // console.log("isMetamaskSwapConfirming", isMetamaskSwapConfirming);
  // console.log("isMetamaskSwapConfirmed", isMetamaskSwapConfirmed);

  return (
    <MainWrapper>
      <div className={`${appCtx?.isDarkMode ? "text-white" : "text-black"}`}>
        <div class="">
          {/* <!-- row --> */}
          <div class="container-fluid">
            {/* <!-- Row --> */}
            <div class="row">
              {/* banner card  */}
              <div class="col-xl-12">
                <div class="">
                  {allCryptoFundLoading ? (
                    <div className="d-flex flex-row justify-between gap-2 mb-4">
                      {[...Array(4)].map((_, index) => (
                        <div className="flex-grow-1" key={index}>
                          <Stack spacing={1}>
                            <Skeleton height={130} variant="rounded" />
                          </Stack>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <HomeBannerCard />
                  )}
                </div>
              </div>
              {/* portfolio card  */}
              <div className="col-12">
                <div className="card">
                  <div className="card-header border-0 pb-3">
                    <h4 className="card-title">My Portfolio</h4>
                  </div>
                  <div className="card-body px-0 pt-0">
                    {portfolioLoading ? (
                      <div
                        className="d-flex justify-content-center align-items-center"
                        style={{ minHeight: "100px" }}
                      >
                        <CircularProgress
                          style={{
                            fontSize: "10px",
                            width: "25px",
                            height: "25px",
                          }}
                          className="py-10"
                        />
                      </div>
                    ) : (
                      <>
                        <PortfolioTopCard data={portfolioData} />
                      </>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div className="card">
                  <div className="card-header border-0 "></div>
                  <div className="card-body px-0 pt-0">
                    <div className="d-flex gap-5 justify-content-center my-10">
                      <div
                        onClick={() => {
                          setOpenTalewalletOnrampModal(true);
                        }}
                        className="d-flex flex-column  justify-content-center align-items-center"
                        style={{ cursor: "pointer" }}
                      >
                        <img
                          src="/images/buyicon.svg"
                          alt="Button Image"
                          className="w-15"
                        />
                        <h5>Buy</h5>
                      </div>
                      <div
                        onClick={() => {
                          if (appCtx?.custodial) {
                            showTaleData();
                            allCoin &&
                              allCoinPrice &&
                              usersToken &&
                              setOpenSendAlgosModal(true);
                          } else {
                            showTaleData();
                            switchChainandOpenModal(setOpenSendAlgosModal);
                          }
                        }}
                        className="d-flex flex-column  justify-content-center align-items-center"
                        style={{ cursor: "pointer" }}
                      >
                        <img
                          src="/images/sendicon.svg"
                          alt="Button Image"
                          className="w-15"
                        />
                        <h5>Send</h5>
                      </div>
                      <div
                        onClick={() => {
                          setOpenOfframpModal(true);
                        }}
                        className="d-flex flex-column  justify-content-center align-items-center"
                        style={{ cursor: "pointer" }}
                      >
                        <img
                          src="/images/sellicon.svg"
                          alt="Button Image"
                          className="w-15"
                        />
                        <h5>Sell</h5>
                      </div>
                      <div
                        onClick={() => {
                          setopenSwapModal(true);
                        }}
                        className="d-flex flex-column  justify-content-center align-items-center"
                        style={{ cursor: "pointer" }}
                      >
                        <img
                          src="/images/swap.svg"
                          alt="Button Image"
                          className="w-15"
                        />
                        <h5>Swap</h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Crypto fund card  */}
              <div class="col-xl-6 col-sm-12" style={{ minHeight: "300px" }}>
                {allCryptoFundLoading ? (
                  <div class="d-flex justify-content-center align-items-center card crypto-chart">
                    <CircularProgress
                      style={{
                        color: "#BBFF00",
                        fontSize: "10px",
                        width: "25px",
                        height: "25px",
                      }}
                      className="py-10"
                      fontSize="small"
                    />
                  </div>
                ) : (
                  <>
                    {allCryptoFund?.length === 0 ? (
                      <div className="card crypto-chart">
                        <div className="mt-5">
                          <p className="text-center  ">no fund found</p>
                        </div>
                      </div>
                    ) : (
                      <div class="card crypto-chart">
                        <div class="card-header pb-0 border-0 flex-wrap">
                          <div class="mb-0 d-flex justify-content-between align-items-center w-100">
                            <h4 class="card-title">Crypto Fund</h4>
                            <button
                              onClick={() => {
                                navigate("/crypto-fund");
                              }}
                              className="btn btn-primary"
                            >
                              View more
                            </button>
                          </div>
                        </div>
                        <div class="card-body pt-2">
                          {!allCryptoFundsStatsLoading &&
                            !allCryptoFundLoading &&
                            allCryptoFund
                              ?.slice(0, 5)
                              ?.map((item) => (
                                <HomeCryptoFundCard
                                  item={item}
                                  allFundsStats={allFundsStats}
                                />
                              ))}
                        </div>
                      </div>
                    )}
                  </>
                )}
              </div>
              {/* Crypto basket card  */}
              <div class="col-xl-6 col-sm-12" style={{ minHeight: "300px" }}>
                {publicBasketLoading ? (
                  <div class="d-flex justify-content-center align-items-center card crypto-chart">
                    <CircularProgress
                      style={{
                        color: "#BBFF00",
                        fontSize: "10px",
                        width: "25px",
                        height: "25px",
                      }}
                      className="py-10"
                      fontSize="small"
                    />
                  </div>
                ) : (
                  <>
                    {publicBasket?.length === 0 ? (
                      <div className="card crypto-chart">
                        <div className="mt-5">
                          <p className="text-center  ">No basket found</p>
                        </div>
                      </div>
                    ) : (
                      <div class="card crypto-chart">
                        <div class="card-header pb-0 border-0 flex-wrap">
                          <div class="mb-0 d-flex justify-content-between align-items-center w-100">
                            <h4 class="card-title">Crypto Basket</h4>
                            <button
                              onClick={() => {
                                navigate("/crypto-basket");
                              }}
                              className="btn btn-primary"
                            >
                              View more
                            </button>
                          </div>
                        </div>
                        <div class="card-body pt-2">
                          {publicBasket?.slice(0, 5)?.map((basket) => (
                            <HomeCryptoBasketCard basket={basket} />
                          ))}
                        </div>
                      </div>
                    )}
                  </>
                )}
              </div>

              {/*Crypto lens  */}
              <div class="col-xl-12">
                <div class="card">
                  <div class="card-header pb-0 border-0 flex-wrap">
                    <div class="mb-0">
                      {!subscribed ? (
                        <h4 class="card-title">Crypto Lens</h4>
                      ) : (
                        <h4 class="card-title">Crypto Lens Update</h4>
                      )}
                    </div>
                  </div>
                  <div class="card-body">
                    {!subscribed && (
                      <div>
                        <p className="">
                          Stay updated with the latest changes in defi industry
                          with our live commentary & reporting
                        </p>

                        <div>
                          {subscribed === true ? (
                            <button
                              onClick={() => {
                                handleUnSubscribe();
                              }}
                              className=" "
                            >
                              {loading ? (
                                <CircularProgress
                                  size={20}
                                  className="ml-[5px]"
                                />
                              ) : (
                                "unsubscribe"
                              )}
                            </button>
                          ) : (
                            <button
                              onClick={() => {
                                setopenlensModal(true);
                              }}
                              className="btn btn-primary"
                            >
                              {loading ? (
                                <CircularProgress
                                  size={20}
                                  className="ml-[5px]"
                                />
                              ) : (
                                "subscribe"
                              )}
                            </button>
                          )}
                        </div>
                      </div>
                    )}
                    {subscribed && (
                      <div>
                        <div className="container py-3">
                          <div className="row row-cols-1 row-cols-md-3 g-4">
                            {postloading ? (
                              <div class="d-flex justify-content-center align-items-center card crypto-chart">
                                <CircularProgress
                                  style={{
                                    color: "#BBFF00",
                                    fontSize: "10px",
                                    width: "25px",
                                    height: "25px",
                                  }}
                                  className="py-10"
                                  fontSize="small"
                                />
                              </div>
                            ) : (
                              content &&
                              content.map((item) => (
                                <Link
                                  to={`/cryptolens_updates/${item.postId}`}
                                  className="col text-decoration-none"
                                >
                                  <div className="card h-100">
                                    <img
                                      src={item.coverImage}
                                      className="card-img-top"
                                      alt="Cover Image"
                                    />
                                    <div className="card-body">
                                      <p className="card-text text-gray">
                                        {item.title}
                                      </p>
                                    </div>
                                    <div className="card-footer">
                                      <span className="badge bg-secondary">{`#${item?.category}`}</span>
                                    </div>
                                  </div>
                                </Link>
                              ))
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              {/*Token card  */}

              <div class="col-xl-12" style={{ minHeight: "300px" }}>
                {listedTokenLoading ? (
                  <div class="d-flex justify-content-center align-items-center card crypto-chart">
                    <CircularProgress
                      style={{
                        color: "#BBFF00",
                        fontSize: "10px",
                        width: "25px",
                        height: "25px",
                      }}
                      className="py-10"
                      fontSize="small"
                    />
                  </div>
                ) : (
                  <>
                    {listedToken?.length === 0 ? (
                      <div className="card crypto-chart">
                        <div className="mt-5">
                          <p className="text-center  ">No Token found</p>
                        </div>
                      </div>
                    ) : (
                      <div class="card">
                        <div class="card-header pb-0 border-0 flex-wrap">
                          <div class="mb-0">
                            <h4 class="card-title">Tokens</h4>
                          </div>
                        </div>
                        <div class="card-body pt-2">
                          {listedToken?.slice(0, 10)?.map((token) => (
                            <HomeTokenCard token={token} />
                          ))}
                          <div className="d-flex justify-content-center my-3">
                            <button
                              onClick={() => {
                                navigate("/tokens");
                              }}
                              className="btn btn-primary"
                            >
                              View more
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {openTalewalletOnrampModal && (
        <TaleWalletOnRampModal
          openModal={openTalewalletOnrampModal}
          setOpenModal={setOpenTalewalletOnrampModal}
          // amount={amount}
          // basketId={basketId}
          // tokenPercentArray={tokenPercentArray}
          // tokenArray={tokenArray}
          // basketBlockchain={basketBlockchain}
          symbol={nativeToken[appCtx?.blockchain]}
        />
      )}
      {openSendAlgosModal && (
        <SendAlgosModal
          openModal={openSendAlgosModal}
          setOpenModal={setOpenSendAlgosModal}
          allCoin={allCoin}
          allCoinPrice={allCoinPrice}
          usersToken={usersToken}
        />
      )}

      {paymentStatusLoader && (
        <PaymentStatusLoader openModal={paymentStatusLoader} />
      )}

      {openModalPaymentFailure && (
        <ModalPaymentFailure
          openModalPaymentFailure={openModalPaymentFailure}
          setOpenModalPaymentFailure={setOpenModalPaymentFailure}
        />
      )}
      {openModalPaymentSuccess && (
        <ModalPaymentSuccess
          openModalPaymentSuccess={openModalPaymentSuccess}
          setOpenModalPaymentSuccess={setOpenModalPaymentSuccess}
        />
      )}
      {openSwapModal && (
        <SwapModal
          openModal={openSwapModal}
          setOpenModal={setopenSwapModal}
          allCoinPrice={allCoinPrice}
          usersToken={usersToken}
          allCoin={allCoin}
          symbol={nativeToken[appCtx?.blockchain]}
          setTransactionData={setTransactionData}
          setOpenTransferSuccessModal={setOpenTransferSuccessModal}
          isMetamaskSwapPending={isMetamaskSwapPending}
          setIsMetamaskSwapPending={setIsMetamaskSwapPending}
          isMetamaskSwapConfirming={isMetamaskSwapConfirming}
          setIsMetamaskSwapConfirming={setIsMetamaskSwapConfirming}
          isMetamaskSwapConfirmed={isMetamaskSwapConfirmed}
          setIsMetamaskSwapConfirmed={setIsMetamaskSwapConfirmed}
          metmaskTransactionHash={metmaskTransactionHash}
          setMetmaskTransactionHash={setMetmaskTransactionHash}
          setOpenMetamaskSwapSuccessModal={setOpenMetamaskSwapSuccessModal}
          sendTokenId={sendTokenId}
          setSendTokenId={setSendTokenId}
          receiveTokenId={receiveTokenId}
          setReceiveTokenId={setReceiveTokenId}
        />
      )}
      {openOfframpModal && (
        <TaleWalletOffRampModal
          openModal={openOfframpModal}
          setOpenModal={setOpenOfframpModal}
          // closeBuyModal={closeModal}

          allCoin={allCoin}
          allCoinPrice={allCoinPrice}
          usersToken={usersToken}
          // amount={amount}
          // basketId={basketId}
          // tokenPercentArray={tokenPercentArray}
          // tokenArray={tokenArray}
          // basketBlockchain={basketBlockchain}
          symbol={nativeToken[appCtx?.blockchain]}
        />
      )}

      {openTransferSuccessModal && (
        <SwapSuccessModal
          openModal={openTransferSuccessModal}
          setOpenModal={setOpenTransferSuccessModal}
          transactionData={transactionData}
          isPerformingSwap={true}
        />
      )}
      {(isMetamaskSwapPending ||
        isMetamaskSwapConfirming ||
        metmaskTransactionHash) && (
        <MetamaskSwapSuccessModal
          openModal={openMetamaskSwapSuccessModal}
          setOpenModal={setOpenMetamaskSwapSuccessModal}
          isMetamaskSwapPending={isMetamaskSwapPending}
          isMetamaskSwapConfirming={isMetamaskSwapConfirming}
          isMetamaskSwapConfirmed={isMetamaskSwapConfirmed}
          metmaskTransactionHash={metmaskTransactionHash}
          setopenSwapModal={setopenSwapModal}
          sendTokenId={sendTokenId}
          receiveTokenId={receiveTokenId}
        />
      )}
    </MainWrapper>
  );
};

export default UsersDashboard;
