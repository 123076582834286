import axios from "axios";
import { useCallback, useState } from "react";
import { useSelector } from "react-redux";

export const useCryptoFund = () => {
  const [allCryptoFund, setAllCryptoFund] = useState([]);
  const [expertsCryptoFund, setExpertsCryptoFund] = useState([]);
  const [usersCryptoFund, setUsersCryptoFund] = useState([]);
  const [allFundsStats, setAllFundsStats] = useState({});
  const [allCryptoExperts, setAllCryptoExperts] = useState([]);
  const [allCryptoExpertsLoading, setAllCryptoExpertsLoading] = useState(false);
  const [allCryptoFundLoading, setAllCryptoFundLoading] = useState(false);
  const [allCryptoFundsStatsLoading, setAllCryptoFundsStatsLoading] =
    useState(false);
  const [expertsCryptoFundLoading, setExpertsCryptoFundLoading] =
    useState(false);
  const [usersCryptoFundLoading, setUsersCryptoFundLoading] = useState(false);

  const appCtx = useSelector((state) => state.app);

  const defaultHeaders = {
    "X-App-Token": process.env.REACT_APP_X_APP_TOKEN,
    "Content-Type": "application/json",
  };

  if (appCtx.authToken !== "") {
    defaultHeaders["X-Auth-Token"] = appCtx.authToken;
  }

  const getAllCrypotExperts = () => {
    setAllCryptoExpertsLoading(true);

    let config = {
      method: "get",
      url: `${process.env.REACT_APP_NFTVERSE_DEV_API}/expert/list`,
      headers: defaultHeaders,
    };

    axios
      .request(config)
      .then((response) => {
        if (response.data) {
          setAllCryptoExperts(response.data?.content);
        }
        setAllCryptoExpertsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setAllCryptoExpertsLoading(false);
      });
  };
  const fetchAllCryptoFundsStats = (fundIds) => {
    setAllCryptoFundsStatsLoading(true);

    let config = {
      method: "post",
      url: `${process.env.REACT_APP_URL_BLOCKCHAIN_SERVICE}/crypto/fund/stats`,
      headers: defaultHeaders,
      data: fundIds,
    };

    axios
      .request(config)
      .then((response) => {
        if (response.data) {
          setAllFundsStats(response.data);
        }
        setAllCryptoFundsStatsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setAllCryptoFundsStatsLoading(false);
      });
  };
  const fetchAllCryptoFund = () => {
    setAllCryptoFundLoading(true);
    let data = "";

    let config = {
      method: "get",
      url: `${process.env.REACT_APP_URL_BLOCKCHAIN_SERVICE}/crypto/fund/list`,
      headers: defaultHeaders,
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        if (response.data) {
          setAllCryptoFund(response.data);
        }
        setAllCryptoFundLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setAllCryptoFundLoading(false);
      });
  };
  const fetchUsersCryptoFund = () => {
    setUsersCryptoFundLoading(true);
    let data = "";

    let config = {
      method: "get",
      url: `${process.env.REACT_APP_URL_BLOCKCHAIN_SERVICE}/crypto/fund/subscribed/list`,
      headers: defaultHeaders,
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        if (response.data) {
          setUsersCryptoFund(response.data);
        }
        setUsersCryptoFundLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setUsersCryptoFundLoading(false);
      });
  };
  const fetchExpertsCryptoFund = () => {
    setExpertsCryptoFundLoading(true);
    let data = "";

    let config = {
      method: "get",
      url: `${process.env.REACT_APP_URL_BLOCKCHAIN_SERVICE}/user/${appCtx?.userDetails?.userId}/crypto/fund/list`,
      headers: defaultHeaders,
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        if (response.data) {
          setExpertsCryptoFund(response.data);
        }
        setExpertsCryptoFundLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setExpertsCryptoFundLoading(false);
      });
  };

  const isUserSubscribed = (
    fundId,
    setSubscribeStatus,
    setIsSubscribedToCryptoFundLoading,
    setSubscribedFundData
  ) => {
    setIsSubscribedToCryptoFundLoading(true);
    let config = {
      url: `${process.env.REACT_APP_URL_BLOCKCHAIN_SERVICE}/crypto/fund/${fundId}/subscribed`,
      method: "get",
      headers: defaultHeaders,
    };

    axios(config)
      .then(function (response) {
        setIsSubscribedToCryptoFundLoading(false);
        console.log("login the response from issubscribed api", response?.data);

        setSubscribeStatus(response?.data?.status);
        setSubscribedFundData(response?.data);
      })
      .catch(function (error) {
        console.log("Error in calling subscribe api: ", error);
        setIsSubscribedToCryptoFundLoading(false);
      });
  };

  return {
    expertsCryptoFund,
    expertsCryptoFundLoading,
    fetchExpertsCryptoFund,
    isUserSubscribed,
    fetchUsersCryptoFund,
    usersCryptoFund,
    usersCryptoFundLoading,
    allCryptoFund,
    setAllCryptoFund,
    fetchAllCryptoFund,
    allCryptoFundLoading,
    fetchAllCryptoFundsStats,
    allFundsStats,
    allCryptoFundsStatsLoading,
    allCryptoExperts,
    allCryptoExpertsLoading,
    getAllCrypotExperts,
  };
};
