import { makeApplicationCallTxnFromObject } from "algosdk";
import axios from "axios";
import React, { useState } from "react";
import { toast } from "react-toastify";

const ContactUs = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [mobile, setMobile] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    let data = JSON.stringify({
      email: email,
      mobile: mobile,
      message: message,
      name: `${firstName} ${lastName}`,
      product: "Crypto Lens",
    });

    let config = {
      method: "post",
      url: `${process.env.REACT_APP_NFTVERSE_DEV_API}/demo/request`,
      headers: {
        "Content-Type": "application/json",
        "X-App-Token": process.env.REACT_APP_X_APP_TOKEN,
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        if (response.status === 200) {
          setFirstName("");
          setLastName("");
          setEmail("");
          setMessage("");
          setMobile("");
        }
        toast.success("Message sent successfully. Team will get in touch with you soon");
      })
      .catch((error) => {
        toast.error("Failed to send messege");
      });
  };

  return (
    <div class="col-xl-12">
      <div class="card">
        <div class="card-header pb-0 border-0 flex-wrap">
          <div class="mb-0">
            <h4 class="card-title">Contact Us</h4>
          </div>
        </div>
        <div class="card-body">
          <div
            className="d-flex flex-column flex-md-row justify-content-around align-items-center"
            id="contact"
          >
            <form className="w-90 md:w-30 " onSubmit={handleSubmit}>
              <div className="d-flex flex-row justify-content-between mt-3 gap-2">
                <div className="mb-4 w-50">
                  <label htmlFor="firstname" className="form-label">
                    First Name
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    id="firstName"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                  />
                </div>
                <div className="mb-4 w-50">
                  <label htmlFor="lastName" className="form-label">
                    Last Name
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    id="lastName"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                  />
                </div>
              </div>
              <div className="mb-4 text-lg">
                <label className="form-label" htmlFor="email">
                  Email
                </label>
                <input
                  className="form-control"
                  type="email"
                  id="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="mb-4 text-lg">
                <label className="form-label" htmlFor="email">
                  Mobile
                </label>
                <input
                  className="form-control"
                  type="number"
                  id="email"
                  value={mobile}
                  onChange={(e) => setMobile(e.target.value)}
                />
              </div>
              <div className="mb-4 text-lg">
                <label className="form-label" htmlFor="message">
                  Write a message
                </label>
                <textarea
                  className="form-control"
                  id="message"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                />
              </div>

              <button type="submit" className="btn btn-primary">
                Submit
              </button>
            </form>

            <div className="w-25">
              <p>Need Help?</p>
              <p className="mb-10 underline"> info@onnftverse.com</p>
              <p>
                Contact us at <br />
                #224, Bannerghatta Main Rd, near Arekere Gate, Omkar Nagar,
                Bilekahalli, Bengaluru, Karnataka 560076
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ContactUs;
