import { faArrowDown, faArrowUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { appActions } from "../../context/app-slice";
import { red } from "@mui/material/colors";
import { useLocation } from "react-router-dom";
import { Line } from "peity-react";

const HomeCryptoBasketCard = ({ basket }) => {
  const appCtx = useSelector((state) => state.app);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  return (
    <div
      class="d-flex justify-content-between align-items-center  market-preview"
      style={{ cursor: "pointer" }}
      onClick={() => navigate(`/crypto-basket/${basket?.basketId}`)}
    >
      <div class="w-100">
        <div class="row">
          <div className="col-xl-12 py-4 d-flex align-items-center justify-content-between">
            <div class="d-flex align-items-center">
              <span>
                {basket?.icon ? (
                  <img
                    src={basket?.icon}
                    alt=""
                    style={{
                      width: "36px",
                      height: "36px",
                      objectFit: "cover",
                      borderRadius: "50%",
                    }}
                  />
                ) : (
                  <img
                    src="/images/basketimage.svg"
                    alt=""
                    style={{
                      width: "36px",
                      height: "36px",
                      objectFit: "cover",
                      borderRadius: "50%",
                    }}
                  />
                )}
              </span>
              <div class="ms-3">
                <h5 class="fs-14 font-w600 mb-0">
                  {basket?.name && basket?.name.length > 20
                    ? `${basket?.name.substring(0, 20)}...`
                    : basket?.name}
                </h5>
                <h6 class="fs-12 font-w400 mb-0">
                  by{" "}
                  {basket?.wealthManagerName
                    ? basket?.wealthManagerName
                    : "Tale Capital"}
                </h6>
              </div>
            </div>
            <div className="d-flex flex-column align-items-end justify-content-end">
              <div className="">
                {basket?.tokens?.map((token, index) => (
                  <img
                    src={token}
                    alt=""
                    className="rounded-circle mb-1"
                    key={index}
                    style={{ width: "25px", height: "25px" }}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeCryptoBasketCard;
