import React, { useEffect } from "react";
import { useCryptolens } from "./useCryptolens";
import { CircularProgress, Skeleton } from "@mui/material";
import { Link } from "react-router-dom";

const Update = () => {
  const { content, postloading, fetchPostContent } = useCryptolens();
  useEffect(() => {
    fetchPostContent();
  }, []);
  return (
    <div class="col-xl-12 mt-4">
      <div class="card">
        <div class="card-header pb-0 border-0 flex-wrap">
          <div class="mb-0">
            <h4 class="card-title">Crypto Lens Update</h4>
          </div>
        </div>
        <div class="card-body">
          <div>
            <div className="container py-3">
              {postloading ? (
                <div
                  className="d-flex justify-content-center align-items-center"
                  style={{ minHeight: "100px" }}
                >
                  <CircularProgress
                    style={{
                      fontSize: "10px",
                      width: "25px",
                      height: "25px",
                    }}
                    className="py-10"
                  />
                </div>
              ) : (
                <div className="row row-cols-1 row-cols-md-3 g-4">
                  {content &&
                    content.map((item) => (
                      <Link
                        to={`/cryptolens_updates/${item.postId}`}
                        className="col text-decoration-none"
                        key={item.postId}
                      >
                        <div className="card h-100">
                          <img
                            src={item.coverImage}
                            className="card-img-top"
                            alt="Cover Image"
                          />
                         
                          <div className="card-body">
                            <p className="card-text">{item.title}</p>
                          </div>
                          <div className="card-footer">
                            <span className="badge bg-secondary">{`#${item?.category}`}</span>
                          </div>
                        </div>
                      </Link>
                    ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Update;
