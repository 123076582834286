import React, { useState } from "react";
import { useEffect } from "react";
import Modal from "react-modal";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { CircularProgress } from "@mui/material";

import styles from "./AirdropModal.module.css";
import axios from "axios";
import { toast } from "react-toastify";
function AirdropModal({
  openModal,
  setOpenModal,
  success,
  setSuccess,
  closeClicked,
  setCloseClicked,
}) {
  const [openFinalModal, setopenFinalModal] = useState(false);
  const [continued, setContinued] = useState(false);

  const [enteredInput, setEnteredInput] = useState(false);
  const [selectedOption, setSelectedOption] = useState("email");
  const appCtx = useSelector((state) => state.app);
  const [inputValue, setInputValue] = useState("");

  const [copyId, setCopyId] = useState(false);
  const [subloading, setSubloading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const width = window.screen.width;
  const customStyles = {
    content: {
      top: "53%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      padding: "0",
      borderRadius: "10px",
      transform: "translate(-50%, -50%)",
      overflow: "inherit",
      width: width > 1200 ? "600px" : "370px",
    },
  };
  function closeModal() {
    setOpenModal(false);

    setInputValue("");
    setCloseClicked(true);
  }
  const copyToClipboard = () => {
    setCopyId(true);
    navigator.clipboard.writeText(appCtx.walletAddress[0]?.address);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    //call api here
    // if api call success
    handleSubscribe();

    console.log(`Submitted value: ${inputValue}`);
    // do something with the submitted value
  };
  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  useEffect(() => {
    if (appCtx?.userDetails?.email) {
      setInputValue(appCtx?.userDetails?.email);
    }
  });

  const handleSubscribe = () => {
    setSubloading(true);
    let data = JSON.stringify({
      email: inputValue,
    });

    let config = {
      method: "post",
      url: `${process.env.REACT_APP_URL_BLOCKCHAIN_SERVICE}/airdrop/subscribe`,
      headers: {
        "Content-Type": "application/json",
        ...(appCtx.authToken
          ? { "X-Auth-Token": appCtx.authToken }
          : { "X-App-Token": process.env.REACT_APP_X_APP_TOKEN }),
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        if (response.status === 200) {
          setSuccess(true);
          setContinued(true);
          setSubloading(false);
        }
      })
      .catch((error) => {
        toast.error("failed to subscribe");
        setSubloading(false);
      });
  };

  return (
    <div>
      <Modal
        isOpen={openModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
        ariaHideApp={false}
        className={`${styles.popup} text-white `}
      >
        <div className="w-[100%] flex justify-end mr-[20px] pt-[10px]">
          <button
            className=" font-medium text-2xl flex items-center leading-[10px] mr-[10px] mt-[10px]"
            onClick={closeModal}
          >
            X
          </button>
        </div>
        <div>
          {success ? (
            <img
              src="/images/tickmark.svg"
              alt=""
              className="w-[130px] mx-auto"
            />
          ) : (
            <img
              src="/images/notificationbell.svg"
              alt=""
              className="w-[200px] mx-auto"
            />
          )}
        </div>

        {!continued ? (
          <form
            onSubmit={handleSubmit}
            className="flex flex-col gap-3 mb-10  items-center"
          >
            <h3 className="mt-5">Email ID</h3>
            <div className="relative flex items-center">
              <img
                className="absolute h-full"
                src="/images/gmailicon.svg"
                alt="icon"
              />
              <input
                className="text-black pl-[70px] pr-4 h-10 w-[400px] rounded-full border-2 border-purple-700 focus:outline-none focus:border-purple-900"
                type="text"
                placeholder={`${
                  selectedOption === "whatsapp"
                    ? "Enter your WhatsApp no"
                    : `${
                        appCtx?.userDetails?.email &&
                        selectedOption !== "whatsapp"
                          ? ""
                          : "Enter your Email"
                      }`
                }  `}
                defaultValue={selectedOption !== "whatsapp" ? inputValue : ""}
                onChange={(e) => setInputValue(e.target.value)}
              />
            </div>
            <button
              type="submit"
              className="bg-gradient-to-r from-purple-700 to-purple-900 rounded-full text-white px-20 py-2 md:py-2 mt-2"
            >
              Continue
              {subloading && (
                <CircularProgress size={20} className="ml-[5px]" />
              )}
            </button>
          </form>
        ) : (
          ""
        )}
        {continued && success ? (
          <div className="flex flex-col gap-3 justify-center items-center mb-10">
            <h3 className="mt-5">Subscription successful!</h3>
            <div>
              <button
                onClick={() => {
                  closeModal();
                  setCloseClicked(!closeClicked);
                }}
                className="bg-gradient-to-r from-purple-700 to-purple-900 rounded-full  text-white px-20  py-2 md:py-2 "
              >
                Close
              </button>
            </div>
          </div>
        ) : (
          ""
        )}
      </Modal>
    </div>
  );
}

export default AirdropModal;
