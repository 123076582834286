import React from "react";

function MinUserInfo({ userName, userProfilePic, userPost }) {
    return (
        <div className="flex gap-5 justify-center items-center">
            <div className="w-5 h-5">
                <img src={"/images/nftlogo.png"} alt="userimg"  className="w-full h-full object-contain"/>
            </div>

            <div className="flex flex-col">
                <span className=" text-xs font-light">{userPost} </span>
                <span className=" text-base font-bold">{userName} </span>
            </div>
        </div>
    );
}

export default MinUserInfo;
