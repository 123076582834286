import { useEffect, useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useCryptobasket } from "./useCryptobasket";
import { CircularProgress } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleChevronDown, faX } from "@fortawesome/free-solid-svg-icons";

import styles from "./CreateBasketModal.module.css";
import Modal from "react-modal";
import { toast } from "react-toastify";
import { nativeToken } from "../../config";
import { useWalletView } from "../../hooks/useWalletView";
import "./CreateBasketModal.module.css";
function CreateBasketModal({
  openModal,
  setOpenModal,

  closeClicked,
  setCloseClicked,
}) {
  const [confirmClicked, setConfirmClicked] = useState(false);
  const [orderPlaced, setOrderPlaced] = useState(false);
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [token, setToken] = useState("");
  const [tokenPercent, setTokenPercent] = useState("");
  const [cryptoBasketTokens, setCryptoBasketTokens] = useState([]);
  const [minAmount, setMinAmount] = useState("");
  const appCtx = useSelector((state) => state.app);
  const [blockchain, setBlockchain] = useState(appCtx.blockchain);

  const navigate = useNavigate();

  const { getAllCoin, allCoin, isCoinLoading } = useWalletView();

  const chains = Object.keys(nativeToken)
    .filter((key) => key !== "ALGORAND")
    .reduce((obj, key) => {
      obj[key] = nativeToken[key];
      return obj;
    }, {});

  useEffect(() => {
    getAllCoin(0, 60, blockchain);
  }, [appCtx.blockchain, blockchain]);

  const handleAddBasketToken = () => {
    // Check if the selected token already exists in the array
    const existingTokenIndex = cryptoBasketTokens.findIndex(
      (t) => t.tokenId === token[0]
    );

    if (existingTokenIndex !== -1) {
      // If the token exists, update the tokenPercent value
      const updatedTokens = [...cryptoBasketTokens];
      updatedTokens[existingTokenIndex].tokenPercent = tokenPercent;
      setCryptoBasketTokens(updatedTokens);
    } else {
      // If the token doesn't exist, add it to the array
      setCryptoBasketTokens([
        ...cryptoBasketTokens,
        {
          tokenId: token[0],
          token: token[1],
          tokenPercent: tokenPercent,
        },
      ]);
    }

    // Reset the input fields
    setToken([]);
    setTokenPercent("");
  };
  console.log("cryptoBasketTokens", cryptoBasketTokens);

  const handleRemoveBasketToken = (tokenToRemove) => {
    setCryptoBasketTokens(
      cryptoBasketTokens.filter((token) => token.token !== tokenToRemove)
    );
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    setLoading(true);

    const tokenPercentages = cryptoBasketTokens.map((token) =>
      parseInt(token.tokenPercent)
    );

    const sum = tokenPercentages.reduce(
      (total, percentage) => total + percentage
    );

    if (sum !== 100) {
      toast.warning("Summation of all token percentage should be 100");
      setLoading(false);
      return;
    }

    const data = {
      name,
      description,
      minAmount,
      blockchain,
      minAmountCurrency: "INR",
      cryptoBasketTokens,
    };

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_URL_BLOCKCHAIN_SERVICE}/crypto/basket`,
        data,
        {
          headers: {
            "X-Auth-Token": appCtx.authToken,
            "Content-Type": "application/json",
          },
        }
      );
      console.log(response.data);
      if (response.data.basketId) {
        navigate(`/crypto-basket/${response.data.basketId}`);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const customStyles = {
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.7)",
      zIndex: 9998,
    },
    content: {
      top: "53%",
      backgroundColor: `${appCtx?.isDarkMode ? "#09153F" : "#FFFFFF"}`,
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      padding: "0",
      borderRadius: "10px",
      transform: "translate(-50%, -50%)",
      overflow: "hidden",
    },
  };
  function closeModal() {
    setOpenModal(false);
  }

  console.log("token", token);

  return (
    <div>
      <Modal
        isOpen={openModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
        ariaHideApp={false}
        className={`${styles.popup}  `}
      >
        <div className="d-flex justify-content-end m-2 position-relative">
          <div
            onClick={closeModal}
            className="btn btn-link position-absolute top-0 right-2"
            style={{ cursor: "pointer" }}
          >
            <i className="fa fa-times" style={{ fontSize: "20px" }}></i>
          </div>

          <div className="p-3">
            <form className="">
              <h5 className="text-center">Create Basket</h5>
              <div className="mb-3">
                <label htmlFor="name" className="form-label">
                  Name of Basket
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="name"
                  placeholder="Enter Basket Name"
                  value={name}
                  onChange={(event) => setName(event.target.value)}
                />
              </div>
              <div className="mb-3">
                <label htmlFor="name" className="form-label">
                  Description
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="description"
                  placeholder="Enter description"
                  value={description}
                  onChange={(event) => setDescription(event.target.value)}
                />
              </div>
              <div className="mb-3">
                <label htmlFor="name" className="form-label">
                  Blockchain
                </label>

                <div className="d-flex flex-direction-row  gap-3">
                  <select
                    className="p-1 ms-2"
                    style={{
                      border: `${
                        appCtx?.isDarkMode ? "none" : "1 px solid #F6F6F6"
                      }`,
                      borderRadius: "5px",
                      backgroundColor: `${
                        appCtx.isDarkMode ? "#18254F" : "#FFFFFF"
                      }`,
                      color: `${appCtx.isDarkMode ? "#8896b9" : "#717579"}`,
                      padding: "5px",
                    }}
                    id="blockchain"
                    value={blockchain}
                    onChange={(event) => setBlockchain(event.target.value)}
                  >
                    {Object.keys(chains).map((chain) => (
                      <option key={chain} value={chain.toUpperCase()}>
                        {chain.toUpperCase()}
                      </option>
                    ))}
                    <span>
                      <FontAwesomeIcon icon={faCircleChevronDown} />
                    </span>
                  </select>
                </div>
              </div>

              <div className="mb-4">
                <label htmlFor="name" className="form-label">
                  Coins
                </label>
                <div className="d-flex flex-direction-row  gap-3 ">
                  <select
                    className="p-1 ms-2"
                    style={{
                      border: `${
                        appCtx?.isDarkMode ? "none" : "1 px solid #F6F6F6"
                      }`,
                      borderRadius: "5px",
                      backgroundColor: `${
                        appCtx.isDarkMode ? "#18254F" : "#FFFFFF"
                      }`,
                      color: `${appCtx.isDarkMode ? "#8896b9" : "#717579"}`,
                    }}
                    id="token"
                    value={JSON.stringify(token)}
                    onChange={(event) =>
                      setToken(JSON.parse(event.target.value))
                    }
                  >
                    <option value="">
                      <span> Select token</span>
                      <span>
                        <FontAwesomeIcon icon={faCircleChevronDown} />
                      </span>
                    </option>
                    {allCoin.map((coin) => (
                      <option
                        key={coin?.tokenId}
                        value={JSON.stringify([
                          coin?.tokenId,
                          coin?.tokenShortName,
                        ])}
                      >
                        {coin?.tokenName}
                      </option>
                    ))}
                  </select>
                  <input
                    className="form-control"
                    id="tokenPercent"
                    type="number"
                    placeholder="Token percentage"
                    value={tokenPercent}
                    onChange={(event) => setTokenPercent(event.target.value)}
                  />
                  <button
                    className="btn btn-primary"
                    type="button"
                    onClick={handleAddBasketToken}
                  >
                    Add
                  </button>
                </div>
              </div>

              {cryptoBasketTokens.length > 0 && (
                <div className="mb-4">
                  <label htmlFor="name" className="form-label">
                    Tokens
                  </label>
                  <ul className="d-flex  justify-content-start align-items-center gap-5">
                    {cryptoBasketTokens.map((cryptoBasketToken, index) => {
                      const token = allCoin.find(
                        (coin) =>
                          coin.tokenShortName === cryptoBasketToken.token
                      );
                      return (
                        <li
                          key={index}
                          className="list-none flex flex-col items-center justify-between"
                          onMouseEnter={(e) =>
                            e.currentTarget.firstChild.classList.remove(
                              "invisible"
                            )
                          }
                          onMouseLeave={(e) =>
                            e.currentTarget.firstChild.classList.add(
                              "invisible"
                            )
                          }
                        >
                          <div className="text-gray-400 invisible rounded-md px-1 mb-n12 ml-3 bg-purple-700 shadow-sm">
                            <FontAwesomeIcon
                              className=" text-white"
                              style={{ width: "8px" }}
                              onClick={() =>
                                handleRemoveBasketToken(token?.tokenShortName)
                              }
                              icon={faX}
                            />
                          </div>
                          {token && (
                            <div className="flex flex-col items-center z-10">
                              <img
                                src={token.tokenIcon}
                                alt={token.tokenShortName}
                                className=""
                                style={{ width: "30px" }}
                              />
                              <p>{cryptoBasketToken.tokenPercent}%</p>
                            </div>
                          )}
                        </li>
                      );
                    })}
                  </ul>
                </div>
              )}
              <div className="mb-4">
                <label htmlFor="name" className="form-label">
                  Amount
                </label>
                <input
                  className="form-control"
                  id="minAmount"
                  type="number"
                  placeholder="Enter amount"
                  value={minAmount}
                  onChange={(event) => setMinAmount(event.target.value)}
                />
              </div>
              <div className="d-flex justify-content-center ">
                <button
                  className="btn btn-primary  "
                  type="submit"
                  onClick={handleSubmit}
                >
                  Continue{" "}
                  <span>
                    {loading && (
                      <CircularProgress size={20} className="ml-[5px]" />
                    )}
                  </span>
                </button>
              </div>
            </form>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default CreateBasketModal;
