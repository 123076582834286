import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import moment from "moment";
import axios from "axios";
import { useSelector } from "react-redux";
import { Skeleton, Stack } from "@mui/material";

const CryptoTokenLineChart = ({ selecteddate, tokenid }) => {
  const [prices, setPrices] = useState([]);
  const [minMax, setMinMax] = useState([]);
  const [chartLoading, setChartLoading] = useState(false);
  const appCtx = useSelector((state) => state.app);

  const [aspectRatio, setAspectRatio] = useState(1);

  useEffect(() => {
    setChartLoading(true);

    let config = {
      method: "get",
      url: `${process.env.REACT_APP_URL_BLOCKCHAIN_SERVICE}/token/price/history?tokenId=${tokenid}&range=${selecteddate}`,
      headers: {
        ...(appCtx.authToken
          ? { "X-Auth-Token": appCtx.authToken }
          : { "X-App-Token": process.env.REACT_APP_X_APP_TOKEN }),
      },
    };

    axios
      .request(config)
      .then((response) => {
        setPrices(response.data.prices);
        setChartLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [selecteddate]);

  useEffect(() => {
    let min = prices.length > 0 ? prices[0][1] : 0;
    let max = prices.length > 0 ? prices[0][1] : 0;

    for (let i = 1; i < prices.length; i++) {
      const price = prices[i][1];
      if (price < min) {
        min = price;
      }
      if (price > max) {
        max = price;
      }
    }

    const padding = (max - min) * 0.1;
    // Adjust the domain of the Y-axis to include the empty space
    const yMin = min - padding;
    const yMax = max + padding;

    setMinMax([yMin, yMax]);
  }, [prices]);

  const chartData = prices?.map((price) => ({
    x: price[0],
    y: price[1],
  }));

const options = {
  series: [
    {
      data: chartData,
    },
  ],
  chart: {
    height: 300,
    type: "area",
    toolbar: {
      show: false,
    },
  },
  colors: ["#7F00FF"],
  dataLabels: {
    enabled: false,
  },
  stroke: {
    curve: "smooth",
    width: 2,
  },
  legend: {
    show: false,
  },
  markers: {
    strokeWidth: 2,
    strokeColors: "#fff",
    hover: {
      size: 5,
    },
  },
  grid: {
    show: true,
    strokeDashArray: 6,
    borderColor: "#E0E0E0",
    xaxis: {
      lines: {
        show: true,
      },
    },
    yaxis: {
      lines: {
        show: true,
        numberOfTicks: 5,
      },
    },
  },
  yaxis: {
    show: true,
    labels: {
      style: {
        colors: "#333",
        fontSize: "12px",
        fontFamily: "Poppins",
        fontWeight: 400,
      },
      formatter: function (value) {
        return value?.toFixed(2);
      },
    },
    tickAmount: 5,
  },

  xaxis: {
    type: "datetime",
    tickAmount: 6,
    labels: {
      style: {
        colors: "#B5B5C3",
        fontSize: "12px",
        fontFamily: "Poppins",
        fontWeight: 400,
      },
      formatter: function (value) {
        return moment.unix(value).format("D MMM");
      },
    },
    axisBorder: {
      show: false,
    },
    tooltip: {
      enabled: false,
    },
  },
  tooltip: {
    custom: function ({ series, seriesIndex, dataPointIndex, w }) {
      const dataPoint = w.config.series[seriesIndex].data[dataPointIndex];
      const date = moment.unix(dataPoint.x).format("D MMM YYYY");
      const price = dataPoint.y.toFixed(2);

      return (
        '<div class="tooltip_box">' +
        '<div class="tooltip-data">' +
        '<span class="tooltip-date">' +
        date +
        "</span>" +
        '<span class="tooltip-price">' +
        price +
        " USD</span>" +
        "</div>" +
        "</div>"
      );
    },
  },
  fill: {
    type: "solid",
    opacity: 0,
  },
};

  console.log("chartData", chartData);

  return (
    <div>
      {!chartLoading ? (
        <ReactApexChart
          options={options}
          series={options.series}
          type="area"
          height={options.chart.height}
        />
      ) : (
        <Stack className="w100 mx-auto">
          {/* For variant="text", adjust the height via font-size */}
          <Skeleton variant="text" sx={{ fontSize: "3rem" }} />
          <Skeleton variant="text" sx={{ fontSize: "3rem" }} />
          <Skeleton variant="text" sx={{ fontSize: "3rem" }} />
          <Skeleton variant="text" sx={{ fontSize: "3rem" }} />
          <Skeleton variant="text" sx={{ fontSize: "3rem" }} />
        </Stack>
      )}
    </div>
  );
};
export default CryptoTokenLineChart;
