import React, { useState } from "react";
import { useEffect } from "react";
import Modal from "react-modal";
import { useSelector } from "react-redux";

import axios from "axios";
import { toast } from "react-toastify";
import { nativeToken } from "../../config";
import TaleWalletOnRampModal from "../../component/TaleWalletOnRamp/TaleWalletOnRampModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
function BasketModal({
  openModal,
  setOpenModal,
  closeClicked,
  setCloseClicked,
  singleBasket,
  tokenPercentArray,
  tokenArray,
  successcloseClicked,
  setSuccessCloseClicked,
  openSuccessModal,
  setopenSuccessModal,
  transactionSuccess,
  setTransactionSuccess,
  fees,
  setOpenContinueInWebModal,
  subscribeId,
  status,
  basketId,
  basketBlockchain,
}) {
  const [investmentAmount, setInvestmentAmount] = useState(null);
  const [totalInvestment, setTotalInvestment] = useState(null);
  const appCtx = useSelector((state) => state.app);

  const [editable, setEditable] = useState(false);
  const [nativeTokenQuantity, setNativeTokenQuantity] = useState(1);
  const [allCoinPrice, setAllcoinPrice] = useState(null);
  const [openTalewalletOnrampModal, setOpenTalewalletOnrampModal] =
    useState(false);

  useEffect(() => {
    setInvestmentAmount(singleBasket?.minAmount);
  }, [singleBasket?.minAmount, openModal]);
  useEffect(() => {
    let config = {
      method: "get",
      url: `${process.env.REACT_APP_URL_BLOCKCHAIN_SERVICE}/token/price?blockchain=${singleBasket?.blockchain}`,
      headers: {
        "X-Auth-Token": appCtx.authToken,
      },
    };
    axios
      .request(config)
      .then((response) => {
        setAllcoinPrice(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    setTotalInvestment(investmentAmount + fees?.total);
  }, [investmentAmount, openModal, allCoinPrice, fees?.total]);
  useEffect(() => {
    if (totalInvestment && allCoinPrice) {
      const inrToUSDExchangeRate = 1 / appCtx?.usdPrice;
      const usdToNativeExchangeRate =
        allCoinPrice[nativeToken[singleBasket?.blockchain]]?.price;

      const usdAmount = totalInvestment * inrToUSDExchangeRate;
      const convertedAmount = usdAmount / usdToNativeExchangeRate;

      setNativeTokenQuantity(convertedAmount);
    }
  }, [investmentAmount, totalInvestment, openModal, allCoinPrice, fees?.total]);

  const customStyles = {
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.7)",
      zIndex: 9998,
    },
    content: {
      top: "53%",
      backgroundColor: `${appCtx?.isDarkMode ? "#09153F" : "#FFFFFF"}`,
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      padding: "0",
      borderRadius: "10px",
      transform: "translate(-50%, -50%)",
      overflow: "hidden",
    },
  };
  const themedColor = {
    color: `${appCtx?.isDarkMode ? "white" : "black"}`,
  };
  function closeModal() {
    setOpenModal(false);
  }

  const handleOrderPlaced = () => {
    CallSubscribeApi(subscribeId);
    let fiatAmount = parseInt(totalInvestment);
    if (fiatAmount < singleBasket?.minAmount) {
      toast.error(
        "Investment Amount should be greater or equal to minimum amount"
      );
      return;
    }

    setOpenTalewalletOnrampModal(true);
  };

  console.log("appCtx", appCtx);

  const handlePlaceOrder = () => {
    if (appCtx.isLoggedIn) {
      handleOrderPlaced();

      closeModal();
    } else {
      setOpenContinueInWebModal(true);
      closeModal();
    }
  };

  const CallSubscribeApi = (subscribeId) => {
    let data = {};

    if (subscribeId) {
      data = JSON.stringify({
        id: subscribeId,
        basketId: singleBasket?.basketId,
        totalAmount: parseInt(totalInvestment),
        currency: singleBasket?.minAmountCurrency,
        status: status,
      });
    } else {
      data = JSON.stringify({
        basketId: singleBasket?.basketId,
        totalAmount: parseInt(totalInvestment),
        currency: singleBasket?.minAmountCurrency,
      });
    }

    console.log(data);
    let config = {
      method: "post",
      url: `${
        process.env.REACT_APP_URL_BLOCKCHAIN_SERVICE
      }/crypto/basket/subscribe?nativeToken=${
        nativeToken[singleBasket?.blockchain]
      }&nativeTokenQuantity=${nativeTokenQuantity}&distributeToken=${false}`,
      headers: {
        "X-Auth-Token": appCtx.authToken,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const hardCodeGasFee = {
    POLYGON: 10,
    BSC: 30,
    ETHEREUM: 300,
    ALGORAND: 0,
    SOLANA: 10,
  };
  let platformFee = (investmentAmount * 0.4) / 100;
  let networkFee = hardCodeGasFee[appCtx?.blockchain] / appCtx?.usdPrice;
  console.log("platformFee", platformFee);
  console.log("networkFee", networkFee);
  let amount = Math.ceil(investmentAmount + platformFee + networkFee);

  return (
    <div>
      <Modal
        isOpen={openModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
        ariaHideApp={false}
      >
        <div className="p-4" style={{ minWidth: "400px" }}>
          <div className="w-100 d-flex justify-content-end mr-3 pt-2 position-relative">
            <div
              onClick={closeModal}
              className="btn btn-link position-absolute top-0 end-2"
              style={{ cursor: "pointer" }}
            >
              <i className="fa fa-times" style={{ fontSize: "20px" }}></i>
            </div>
          </div>
          <div className="m-4">
            {
              <div>
                <div className="d-flex gap-3 align-items-center mt-2">
                  <h5 className="font-weight-semibold mb-0">
                    Investment Amount :
                  </h5>{" "}
                  <div className="d-flex gap-2 align-items-center">
                    {editable ? (
                      <input
                        type="number"
                        value={investmentAmount}
                        onChange={(e) => setInvestmentAmount(e.target.value)}
                      />
                    ) : (
                      <h4 className="mb-0" onClick={() => setEditable(true)}>
                        {singleBasket?.minAmountCurrency}{" "}
                        {singleBasket?.minAmount}
                      </h4>
                    )}
                    <div
                      className="d-flex align-items-center"
                      style={{ cursor: "pointer" }}
                      onClick={() => setEditable(true)}
                    >
                      <FontAwesomeIcon
                        icon={faEdit}
                        className=""
                        style={themedColor}
                      />
                    </div>
                  </div>
                </div>

                <div className="">
                  <h5 className="font-weight-semibold">Review Order</h5>{" "}
                  <table>
                    <thead>
                      <tr className="">
                        <th className="py-2">Constituents</th>
                        <th className="py-2 ps-5">Percentage</th>
                      </tr>
                    </thead>
                    <tbody>
                      {singleBasket?.cryptoBasketTokens?.map((token, index) => (
                        <tr className="bg-gray-100" key={index}>
                          <td className="py-2">
                            <img
                              src={token?.icon}
                              alt=""
                              style={{ width: "30px", height: "30px" }}
                              className="rounded-circle me-2"
                            />
                            {token?.token} ({token?.symbol})
                          </td>
                          <td className="py-2 ps-5">{token?.tokenPercent}%</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <div className="d-flex gap-3 align-items-center">
                    <h5 className="font-weight-semibold">
                      Total Payable Amount :
                    </h5>
                    <div className="d-flex gap-2 align-items-center">
                      <h4 className="">
                        {singleBasket?.minAmountCurrency} {amount}
                      </h4>
                    </div>
                  </div>
                  <p>
                    Total Payable = Investment Amount + platformFee <br />+
                    networkFee
                  </p>
                  <div className="d-flex justify-content-center mt-1">
                    <button
                      onClick={() => {
                        handlePlaceOrder();
                      }}
                      className="btn btn-primary"
                    >
                      Place Orders
                    </button>
                  </div>
                </div>
              </div>
            }
          </div>
        </div>
      </Modal>

      {openTalewalletOnrampModal && (
        <TaleWalletOnRampModal
          openModal={openTalewalletOnrampModal}
          setOpenModal={setOpenTalewalletOnrampModal}
          amount={investmentAmount}
          basketId={basketId}
          tokenPercentArray={tokenPercentArray}
          tokenArray={tokenArray}
          basketBlockchain={basketBlockchain}
          singleBasket={singleBasket}
          totalInvestment={totalInvestment}
        />
      )}
    </div>
  );
}

export default BasketModal;
