const algosdk = require("algosdk");

const token = process.env.REACT_APP_ALGORAND_TOKEN;
const server = process.env.REACT_APP_ALGORAND_SERVER;
const port = process.env.REACT_APP_ALGORAND_PORT;

// algod client
const AlgorandClient = new algosdk.Algodv2(token, server, port);
// const indexerClient = new algosdk.Indexer(token, server, port);
export default AlgorandClient;
