import { createSlice } from "@reduxjs/toolkit";

const appSlice = createSlice({
  name: "nftverse-app",
  initialState: {
    isLoggedIn: false,
    usdPrice: null,
    singleTokenData: {},
    userDetails: {
      name: null,
      email: null,
      userId: null,
    },
    paymentData: {
      usdToEthereumPrice: "",
      marketId: "",
      nftSrc: "",
      collectionId: "",
      nftPrice: "",
      nftId: "",
      title: "",
      blockchainNftId: "",
      blockchain: "",
      type: "",
      ipfsHash: "",
      assetId: "",
      s3url: "",
      assetType: "",
      scale: "",
      price: "",
      role: "user",
    },
    mnemonic: '""',
    algomnemonic: "",
    evmmnemonic: "",
    solanamnemonic: "",
    isDarkMode: true,
    authToken: null,
    signInAndLoginImage: "/images/Signupwallet.png",
    waitlist: {},
    walletAddress: [{ address: "loading ..." }],
    metaData: {},
    country: "",
    page: "",
    frontSide: "",
    frontSideFile: "",
    backSide: "",
    backSideFile: "",
    picture: "",
    document: "",
    reward: {},
    verified: false,
    tailCoin: 0,
    password: "",
    rejection: null,
    lock: false,
    network: true,
    custodial: true,
    encrypt: "",
    evmncrypt: "",
    algoencrypt: "",
    solanaencrypt: "",
    marketplaceId: 0,
    blockchain: "BSC",
    productDetails: null,
    isSideBarOpen: true,
  },

  reducers: {
    login(state) {
      state.isLoggedIn = true;
    },
    logout(state) {
      state.isLoggedIn = false;
    },
    toggleDarkMode(state) {
      state.isDarkMode = !state.isDarkMode;
    },
    setDarkMode(state, action) {
      state.isDarkMode = action.payload;
    },
    updateUserDetails(state, action) {
      const email = action.payload.email;
      const name = action.payload.name;
      const userId = action.payload.userId;

      state.userDetails = {
        userId,
        email,
        name,
      };
    },
    paymentData(state, action) {
      const usdToEthereumPrice = action.payload.usdToEthereumPrice;
      const marketId = action.payload.marketId;
      const nftSrc = action.payload.nftSrc;
      const collectionId = action.payload.collectionId;
      const nftPrice = action.payload.nftPrice;
      const nftId = action.payload.nftId;
      const title = action.payload.title;
      const blockchainNftId = action.payload.blockchainNftId;
      const blockchain = action.payload.blockchain;
      const type = action.payload.type;
      const ipfsHash = action.payload.ipfsHash;
      const assetType = action.payload.assetType;
      const s3url = action.payload.s3url;
      const assetId = action.payload.assetId;
      const scale = action.payload.scale;
      const price = action.payload.price;
      state.paymentData = {
        usdToEthereumPrice,
        marketId,
        nftSrc,
        collectionId,
        nftPrice,
        nftId,
        title,
        blockchainNftId,
        blockchain,
        type,
        ipfsHash,
        assetId,
        s3url,
        assetType,
        scale,
        price,
      };
    },
    // setKycData(state, action) {
    //     const country = action.payload.country ? action.payload.country : state.kyc.email;
    //     const frontSide = action.payload.frontSide ? action.payload.frontSide : state.kyc.frontSide;
    //     const backSide = action.payload.backSide ? action.payload.backSide : state.kyc.backSide;
    //     const frontSideFile = action.payload.frontSideFile ? action.payload.frontSideFile : state.kyc.frontSideFile;
    //     const backSideFile = action.payload.backSideFile ? action.payload.backSideFile : state.kyc.backSideFile;
    //     state.kyc = {
    //         country,
    //         frontSide,
    //         backSide,
    //         frontSideFile,
    //         backSideFile
    //     };
    // },
    setBlockChain(state, action) {
      state.blockchain = action.payload;
    },
    updateAuthToken(state, action) {
      state.authToken = action.payload;
    },
    toggleNewSignup(state) {
      state.newSignup = !state.newSignup;
    },
    togglePasswordCreated(state) {
      state.passwordCreated = !state.passwordCreated;
    },
    toggleUpdateProfile(state) {
      state.updateProfile = !state.updateProfile;
    },
    setWaitlist(state, action) {
      state.waitlist = action.payload;
    },
    setWalletAddress(state, action) {
      state.walletAddress = action.payload;
    },
    setMetaData(state, action) {
      state.metaData = action.payload;
    },
    setMnemonicCode(state, action) {
      state.mnemonic = action.payload;
    },
    setAlgoMnemonicCode(state, action) {
      state.algomnemonic = action.payload;
    },
    setEvmMnemonicCode(state, action) {
      state.evmmnemonic = action.payload;
    },
    setSolanaMnemonicCode(state, action) {
      state.solanamnemonic = action.payload;
    },
    setCountry(state, action) {
      state.country = action.payload;
    },
    setFrontSide(state, action) {
      state.frontSide = action.payload;
    },
    setFrontSideFile(state, action) {
      state.frontSideFile = action.payload;
    },
    setBackSide(state, action) {
      state.backSide = action.payload;
    },
    setBackSideFile(state, action) {
      state.backSideFile = action.payload;
    },
    setPicture(state, action) {
      state.picture = action.payload;
    },
    setDocument(state, action) {
      state.document = action.payload;
    },
    setRewardData(state, action) {
      state.reward = action.payload;
    },
    setVerified(state, action) {
      state.verified = action.payload;
    },
    setTailCoin(state, action) {
      state.tailCoin = action.payload;
    },
    setPassword(state, action) {
      state.password = action.payload;
    },
    setRejection(state, action) {
      state.rejection = action.payload;
    },
    setLock(state, action) {
      state.lock = action.payload;
    },
    setNetwork(state, action) {
      state.network = action.payload;
    },
    setCustodial(state, action) {
      state.custodial = action.payload;
    },
    setEncrypt(state, action) {
      state.encrypt = action.payload;
    },
    setAlgoEncrypt(state, action) {
      state.algoencrypt = action.payload;
    },
    setEvmEncrypt(state, action) {
      state.evmncrypt = action.payload;
    },
    setSolanaEncrypt(state, action) {
      state.solanaencrypt = action.payload;
    },
    selectPage(state, action) {
      state.page = action.payload;
    },
    setMarketplaceId(state, action) {
      state.marketplaceId = action.payload;
    },
    setProductDetails(state, action) {
      state.productDetails = action.payload;
    },
    setUsdPrice(state, action) {
      state.usdPrice = action.payload;
    },
    setSingleTokenData(state, action) {
      state.singleTokenData = action.payload;
    },
    setIsSideBarOpen(state, action) {
      state.isSideBarOpen = action.payload;
    },
    setRole(state, action) {
      state.role = action.payload;
    },
  },
});

const appActions = appSlice.actions;

export { appActions };
export default appSlice;
