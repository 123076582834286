import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { CircularProgress, Skeleton } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useDispatch, useSelector } from "react-redux";


import { useAirdrop } from "./useAirdrop";
import AirdropModal from "./AirdropModal";
import axios from "axios";
import { faDollarSign } from "@fortawesome/free-solid-svg-icons";
import MainWrapper from "../wrapper/MainWrapper";

const AirdropDetails = () => {
  const appCtx = useSelector((state) => state.app);
  const dispatch = useDispatch();
  const { id } = useParams();

  const [openAirdropModal, setopenAirdropModal] = useState(false);

  const [success, setSuccess] = useState(false);
  const [closeClicked, setCloseClicked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [airdropData, setAirdropData] = useState("");
  const [airdropDataLodaing, setAirdropDataLodaing] = useState("");

  const { subscribeLoading, isSubscribed, airdropSubscribed, subloading } =
    useAirdrop();

  useEffect(() => {
    isSubscribed();
  }, [openAirdropModal]);

  useEffect(() => {
    setAirdropDataLodaing(true);
    let config = {
      method: "get",
      url: `${process.env.REACT_APP_URL_BLOCKCHAIN_SERVICE}/airdrop/${id}/detail`,
      headers: {
        "X-Auth-Token": appCtx.authToken,
        "Content-Type": "application/json",
      },
    };

    axios
      .request(config)
      .then((response) => {
        setAirdropData(response.data);
        setAirdropDataLodaing(false);
      })
      .catch((error) => {
        console.log(error);
        setAirdropDataLodaing(false);
      });
  }, []);
  return (
    <MainWrapper>
      <div className="container-fluid">
        <div className="flex flex-col justify-between min-h-screen h-fit  home">
          <div className="w-100 flex flex-col justify-center">
            <div className="card">
              {airdropDataLodaing ? (
                <div
                  className="d-flex justify-content-center align-items-center"
                  style={{ minHeight: "100vh" }}
                >
                  <CircularProgress
                    style={{
                      fontSize: "10px",
                      width: "25px",
                      height: "25px",
                    }}
                    className="py-10"
                  />
                </div>
              ) : (
                <div className="">
                  <div className="d-flex flex-column gap-2 align-items-center">
                    <div className="">
                      <h3 className="mt-3">{airdropData?.title}</h3>
                    </div>
                    {airdropData?.icon ? (
                      <img
                        src={airdropData?.icon}
                        alt=""
                        style={{ width: "400px" }}
                      />
                    ) : (
                      <img
                        src="/images/airdrop.png"
                        alt=""
                        style={{ width: "400px" }}
                      />
                    )}
                  </div>
                  <div className="d-flex flex-row flex-wrap w-75  mx-auto my-5">
                    <div className="w-25">
                      <h4>Token Name</h4>
                      <p>{airdropData?.tokenName}</p>
                    </div>
                    <div className="w-25">
                      <h4>Token Symbol</h4>
                      <p>{airdropData?.tokenSymbol}</p>
                    </div>
                    <div className="w-25">
                      <div className="">
                        <h4>Total Airdrop</h4>
                        <p>{airdropData?.totalAirdrop}</p>
                      </div>
                    </div>
                    <div className="w-25">
                      <h4>Total Airdrop Worth</h4>
                      <p>
                        <FontAwesomeIcon icon={faDollarSign} className="" />
                        {airdropData?.totalAirdropWorth}
                      </p>
                    </div>
                    <div className="w-25 mt-4">
                      <h4>Project</h4>
                      <p>{airdropData?.project}</p>
                    </div>
                  </div>
                  <div className="mt-3 w-75  mx-auto ">
                    <h3 className="font-w600">Description</h3>
                    <p
                      className="py-3"
                      dangerouslySetInnerHTML={{
                        __html: airdropData?.description,
                      }}
                    ></p>
                  </div>
                  <div className="d-flex justify-content-center w-100p my-5">
                    <button
                      className="btn btn-primary px-5"
                      onClick={() => {
                        window.open(airdropData?.projectUrl);
                      }}
                    >
                      Visit Project
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <AirdropModal
        openModal={openAirdropModal}
        setOpenModal={setopenAirdropModal}
        success={success}
        setSuccess={setSuccess}
        closeClicked={closeClicked}
        setCloseClicked={setCloseClicked}
      />
    </MainWrapper>
  );
};

export default AirdropDetails;
