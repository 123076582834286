import React from "react";

const Footer = () => {
  return (
    <div class="text-center p-3">
      <div>
        <p>
          Copyright © Designed &amp; Developed by{" "}
          <a href="https://dexignlab.com/" target="_blank">
            DexignLab
          </a>{" "}
          <span class="current-year">2024</span>
        </p>
      </div>
    </div>
  );
};

export default Footer;
