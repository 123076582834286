import React from "react";
import Chart from "react-apexcharts";

const CryptoFundLineChart = ({ prices }) => {
  let reversedPrices = prices?.slice().reverse();
  const chartOptions = {
    chart: {
      id: "line-chart",
      toolbar: {
        show: false,
      },
    },
    xaxis: {
      labels: {
        show: false,
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      labels: {
        show: false,
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    stroke: {
      width: 2,
    },
    grid: {
      show: false,
    },
    tooltip: {
      enabled: false,
    },
  };

  const chartSeries = [
    {
      name: "line-chart",
      data: reversedPrices,
    },
  ];

  return (
    <Chart
      options={chartOptions}
      series={chartSeries}
      type="line"
      width="80%"
      height="90px"
    />
  );
};

export default CryptoFundLineChart;
