import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import moment from "moment";
import axios from "axios";
import { useSelector } from "react-redux";
import { Skeleton, Stack } from "@mui/material";

const PortfolioLineChart = ({
  lineChartFormat,
  selecteddate,
  graphDataLoading,
}) => {
  const [prices, setPrices] = useState([]);
  const [minMax, setMinMax] = useState([]);
  const appCtx = useSelector((state) => state.app);

  const [aspectRatio, setAspectRatio] = useState(1);

  const options = {
    series: [
      {
        data: lineChartFormat,
      },
    ],
    chart: {
      height: 300,
      type: "area",
      toolbar: {
        show: false,
      },
    },
    colors: ["#FF5722"],
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      width: 3,
    },
    legend: {
      show: false,
    },
    markers: {
      strokeWidth: 3,
      strokeColors: "#fff",
      hover: {
        size: 10,
      },
    },
    grid: {
      show: true,
      strokeDashArray: 6,
      borderColor: "#E0E0E0",
      xaxis: {
        lines: {
          show: true,
        },
      },
      yaxis: {
        lines: {
          show: true,
          numberOfTicks: 5,
        },
      },
    },
    yaxis: {
      show: true,
      labels: {
        style: {
          colors: "#333",
          fontSize: "12px",
          fontFamily: "Poppins",
          fontWeight: 400,
        },
        formatter: function (value) {
          return value?.toFixed(2);
        },
      },
      tickAmount: 5,
    },

    xaxis: {
      type: "datetime",
      tickAmount: 6,
      labels: {
        style: {
          colors: "#B5B5C3",
          fontSize: "12px",
          fontFamily: "Poppins",
          fontWeight: 400,
        },
        formatter: function (value) {
          return selecteddate === 0 || selecteddate === 1
            ? moment(value).format("h:mm A")
            : moment(value).format("D MMM");
          //  moment.unix(value).format("D MMM YYYY");
        },
      },
      axisBorder: {
        show: false,
      },
      tooltip: {
        enabled: false,
      },
    },
    tooltip: {
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        const dataPoint = w.config.series[seriesIndex].data[dataPointIndex];
        const date = moment(dataPoint.x).format("D MMM YYYY");
        const price = dataPoint.y.toFixed(2);

        return (
          '<div class="tooltip_box">' +
          '<div class="tooltip-data">' +
          '<span class="tooltip-date">' +
          date +
          "</span>" +
          '<span class="tooltip-price">' +
          price +
          " USD</span>" +
          "</div>" +
          "</div>"
        );
      },
    },
    fill: {
      type: "solid",
      opacity: 0,
    },
  };

  return (
    <div>
      {!graphDataLoading ? (
        <ReactApexChart
          options={options}
          series={options.series}
          type="area"
          height={options.chart.height}
        />
      ) : (
        <Stack className="w100 mx-auto">
          {/* For variant="text", adjust the height via font-size */}
          <Skeleton variant="text" sx={{ fontSize: "3rem" }} />
          <Skeleton variant="text" sx={{ fontSize: "3rem" }} />
          <Skeleton variant="text" sx={{ fontSize: "3rem" }} />
          <Skeleton variant="text" sx={{ fontSize: "3rem" }} />
          <Skeleton variant="text" sx={{ fontSize: "3rem" }} />
        </Stack>
      )}
    </div>
  );
};
export default PortfolioLineChart;
