import axios from "axios";
import { useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

export const useAirdrop = () => {
  const [airDropLoading, setAirDropLoading] = useState(false);
  const [airDropList, setAirDropList] = useState();
  const [openAddAirdropModal, setOpenAddAirdropModal] = useState(false);
  const [airdropSubscribed, setAirdropSubscribed] = useState(false);
  const [subloading, setSubloading] = useState(false);
  const [subscriberCount, setSubscriberCount] = useState(0);
  const appCtx = useSelector((state) => state.app);

  let customHeader = {};
  if (appCtx.authToken != null) {
    customHeader = {
      "Content-Type": "application/json",
      "X-Auth-Token": appCtx.authToken,
    };
  } else {
    customHeader = {
      "Content-Type": "application/json",
      "X-App-Token": process.env.REACT_APP_X_APP_TOKEN,
    };
  }

  // checking whether user is subscribed or not
  const isSubscribed = () => {
    setSubloading(true);

    let config = {
      method: "get",
      url: `${process.env.REACT_APP_URL_BLOCKCHAIN_SERVICE}/airdrop/isSubscribed`,
      headers: customHeader,
    };

    axios
      .request(config)
      .then((response) => {
        if (response.data.userId) {
          setAirdropSubscribed(true);
          setSubloading(false);
        } else {
          setAirdropSubscribed(false);
          setSubloading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setSubloading(false);
      });
  };
  // fetching airdrop

  const getAirDropList = () => {
    setAirDropLoading(true);

    let config = {
      method: "get",
      url: `${process.env.REACT_APP_URL_BLOCKCHAIN_SERVICE}/airdrop/list`,
      headers: customHeader,
    };

    axios
      .request(config)
      .then((response) => {
        setAirDropList(response.data.content);
        setAirDropLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setAirDropLoading(false);
      });
  };

  // get subscriber count

  const getSubscriberCount = () => {
    let config = {
      method: "get",
      url: `${process.env.REACT_APP_URL_BLOCKCHAIN_SERVICE}/airdrop/subscriber/count`,
      headers: customHeader,
    };

    axios
      .request(config)
      .then((response) => {
        setSubscriberCount(response.data?.count);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return {
    airDropLoading,
    airDropList,
    getAirDropList,

    openAddAirdropModal,
    setOpenAddAirdropModal,
    getSubscriberCount,
    subscriberCount,
    isSubscribed,
    airdropSubscribed,
    subloading,
  };
};
