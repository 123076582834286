import React from "react";
import { useSelector } from "react-redux";
import AirdropComponent from "./AirdropComponent";
import MainWrapper from "../wrapper/MainWrapper";

const Airdrop = () => {
  const appCtx = useSelector((state) => state.app);

  return (
    <div>
      {appCtx.isLoggedIn ? (
        <MainWrapper>
          <AirdropComponent />
        </MainWrapper>
      ) : (
        <>
          <AirdropComponent />
        </>
      )}
    </div>
  );
};

export default Airdrop;
