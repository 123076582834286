import { useEffect, useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useCryptobasket } from "./useCryptobasket";
import { CircularProgress } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleChevronDown, faX } from "@fortawesome/free-solid-svg-icons";

import styles from "./CreateBasketModal.module.css";
import Modal from "react-modal";
import { toast } from "react-toastify";
import { nativeToken } from "../../config";
import { useWalletView } from "../../hooks/useWalletView";
import "./RebalanceModal.module.css";
function RebalanceModal({
  openModal,
  setOpenModal,

  singleBasket,
}) {
  const [confirmClicked, setConfirmClicked] = useState(false);
  const [orderPlaced, setOrderPlaced] = useState(false);
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [token, setToken] = useState("");
  const [tokenPercent, setTokenPercent] = useState("");
  const [minAmount, setMinAmount] = useState("");
  const appCtx = useSelector((state) => state.app);
  const [blockchain, setBlockchain] = useState(appCtx.blockchain);

  const navigate = useNavigate();

  const { getAllCoin, allCoin, isCoinLoading } = useWalletView();

  const handleSubmit = async (event) => {
    event.preventDefault();

    // setLoading(true);
  };

  const customStyles = {
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.7)",
      zIndex: 9998,
    },
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      overflow: "hidden",
      backgroundColor: `${appCtx?.isDarkMode ? "#18254F" : "#FFFFFF"}`,
    },
  };
  function closeModal() {
    setOpenModal(false);
  }

  const [cryptoBasketTokens, setCryptoBasketTokens] = useState(
    singleBasket?.cryptoBasketTokens
  );

  const [editableTokenPercent, setEditableTokenPercent] = useState(null);

  const handleEditClick = (event, tokenId) => {
    event.preventDefault();
    setEditableTokenPercent(tokenId);
  };

  const handleTokenPercentChange = (e, tokenId) => {
    const newTokenPercent = parseFloat(e.target.value);
    const updatedTokens = cryptoBasketTokens?.map((token) => {
      if (token.tokenId === tokenId) {
        return { ...token, tokenPercent: newTokenPercent };
      }
      return token;
    });
    setCryptoBasketTokens(updatedTokens);
  };

  // console.log("cryptoBasketTokens", cryptoBasketTokens);
  return (
    <div>
      <Modal
        isOpen={openModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
        ariaHideApp={false}
        className={`${styles.popup}  `}
      >
        <div className="d-flex justify-content-end m-2 ">
          <button
            onClick={closeModal}
            className="btn btn-link position-absolute top-2 right-2"
          >
            <i
              className={`fa fa-times ${
                appCtx?.isDarkMode ? "text-white" : ""
              }`}
            ></i>
          </button>
        </div>
        <div className="px-3">
          <form className="">
            <table className="table">
              <thead>
                <tr>
                  <th></th>
                  <th>Token</th>
                  <th>Percentage</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {cryptoBasketTokens?.map((token) => (
                  <tr key={token.tokenId}>
                    <td>
                      <img
                        src={token?.icon}
                        alt=""
                        style={{ width: "36px", height: "36px" }}
                      />
                    </td>
                    <td>{token.token}</td>
                    <td style={{ width: "100px" }}>
                      {editableTokenPercent === token.tokenId ? (
                        <input
                          type="number"
                          className="form-control"
                          value={token.tokenPercent}
                          onChange={(e) =>
                            handleTokenPercentChange(e, token.tokenId)
                          }
                        />
                      ) : (
                        `${token.tokenPercent}%`
                      )}
                    </td>
                    <td>
                      <button
                        className="btn btn-link"
                        onClick={(event) =>
                          handleEditClick(event, token?.tokenId)
                        }
                      >
                        <i
                          className={`fa fa-edit ${
                            appCtx?.isDarkMode ? "text-white" : ""
                          }`}
                        ></i>
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="d-flex justify-content-center mb-3 ">
              <button
                className="btn btn-primary  "
                type="submit"
                onClick={handleSubmit}
              >
                Continue{" "}
                <span>
                  {loading && (
                    <CircularProgress size={20} className="ml-[5px]" />
                  )}
                </span>
              </button>
            </div>
          </form>
        </div>
      </Modal>
    </div>
  );
}

export default RebalanceModal;
