import React, { useState } from "react";
import { useSelector } from "react-redux";
var CryptoJS = require("crypto-js");

export const GetDecryptedData = () => {
  const appCtx = useSelector((state) => state.app);
  const oldMnemonic =
    appCtx.blockchain === "ALGORAND"
      ? appCtx.algomnemonic?.split(" ")
      : appCtx.blockchain === "SOLANA"
      ? appCtx.solanamnemonic?.split(" ")
      : appCtx.evmmnemonic?.split(" ");
  let decryptedData = null;

  if (appCtx.blockchain === "ALGORAND") {
    try {
      if (appCtx?.algoencrypt) {
        if (oldMnemonic?.length && oldMnemonic?.length > 1) {
          let ciphertext = CryptoJS?.AES?.encrypt(
            JSON.stringify(appCtx.algomnemonic),
            appCtx?.algoencrypt || ""
          ).toString();
          let bytes = CryptoJS?.AES?.decrypt(ciphertext, appCtx?.encrypt || "");
          decryptedData =
            bytes.toString(CryptoJS?.enc?.Utf8) &&
            JSON.parse(bytes.toString(CryptoJS?.enc?.Utf8));
        } else {
          var bytes = CryptoJS?.AES?.decrypt(
            appCtx.algomnemonic || '""',
            appCtx?.algoencrypt || ""
          );
          decryptedData =
            bytes.toString(CryptoJS?.enc?.Utf8) &&
            JSON.parse(bytes.toString(CryptoJS?.enc?.Utf8));
        }
      }
    } catch {}
  }
  if (appCtx.blockchain === "SOLANA") {
    try {
      if (appCtx?.solanaencrypt) {
        if (oldMnemonic?.length && oldMnemonic?.length > 1) {
          let ciphertext = CryptoJS?.AES?.encrypt(
            JSON.stringify(appCtx.solanamnemonic),
            appCtx?.solanaencrypt || ""
          ).toString();
          let bytes = CryptoJS?.AES?.decrypt(ciphertext, appCtx?.encrypt || "");
          decryptedData =
            bytes.toString(CryptoJS?.enc?.Utf8) &&
            JSON.parse(bytes.toString(CryptoJS?.enc?.Utf8));
        } else {
          var bytes = CryptoJS?.AES?.decrypt(
            appCtx.solanamnemonic || '""',
            appCtx?.solanaencrypt || ""
          );
          decryptedData =
            bytes.toString(CryptoJS?.enc?.Utf8) &&
            JSON.parse(bytes.toString(CryptoJS?.enc?.Utf8));
        }
      }
    } catch {}
  }

  if (appCtx.blockchain !== "ALGORAND" && appCtx.blockchain !== "SOLANA") {
    try {
      if (appCtx?.evmncrypt) {
        if (oldMnemonic?.length && oldMnemonic?.length > 1) {
          let ciphertext = CryptoJS?.AES?.encrypt(
            JSON.stringify(appCtx.evmmnemonic),
            appCtx?.evmncrypt || ""
          ).toString();
          let bytes = CryptoJS?.AES?.decrypt(ciphertext, appCtx?.encrypt || "");
          decryptedData =
            bytes.toString(CryptoJS?.enc?.Utf8) &&
            JSON.parse(bytes.toString(CryptoJS?.enc?.Utf8));
        } else {
          let bytes = CryptoJS?.AES?.decrypt(
            appCtx.evmmnemonic || '""',
            appCtx?.evmncrypt || ""
          );
          decryptedData =
            bytes.toString(CryptoJS?.enc?.Utf8) &&
            JSON.parse(bytes.toString(CryptoJS?.enc?.Utf8));
        }
      }
    } catch {}
  }

  return {
    decryptedData,
  };
};
