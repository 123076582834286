import React, { useState } from "react";
import { useEffect } from "react";
import Modal from "react-modal";
import { useSelector } from "react-redux";
import styles from "./Keysmodal.module.css";
import { ethers } from "ethers";
import algosdk from "algosdk";

let CryptoJS = require("crypto-js");

function KeysModal({ openModal, setOpenModal, privateKeyData }) {
  const [decryptedData, setDecryptedData] = useState("");
  const [evmWalletData, setEvmWalletData] = useState("");
  const [algoWalletData, setAlgoWalletData] = useState("");
  const appCtx = useSelector((state) => state.app);

  const customStyles = {
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.7)",
      zIndex: 9998,
    },
    content: {
      position: "fixed",
      top: "50%",
      left: "50%",
      zIndex: 9999,
      backgroundColor: `${appCtx?.isDarkMode ? "#09153F" : "#FFFFFF"}`,

      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      border: `${appCtx?.isDarkMode ? "#09153F" : "#FFFFFF"}`,
      borderRadius: "10px",
      transform: "translate(-50%, -50%)",
      overflow: "hidden",
      width: "500px",
      padding: "20px",
    },
  };

  function closeModal() {
    setOpenModal(false);
  }

  useEffect(() => {
    const oldMnemonic =
      appCtx.blockchain === "ALGORAND"
        ? appCtx.algomnemonic?.split(" ")
        : appCtx.evmmnemonic?.split(" ");
    let decryptedData = null;

    if (appCtx.blockchain === "ALGORAND") {
      try {
        if (appCtx?.algoencrypt) {
          if (oldMnemonic?.length && oldMnemonic?.length > 1) {
            let ciphertext = CryptoJS?.AES?.encrypt(
              JSON.stringify(appCtx.algomnemonic),
              appCtx?.algoencrypt || ""
            ).toString();
            let bytes = CryptoJS?.AES?.decrypt(
              ciphertext,
              appCtx?.encrypt || ""
            );
            decryptedData =
              bytes.toString(CryptoJS?.enc?.Utf8) &&
              JSON.parse(bytes.toString(CryptoJS?.enc?.Utf8));
            setDecryptedData(decryptedData);
          } else {
            let bytes = CryptoJS?.AES?.decrypt(
              appCtx.algomnemonic || '""',
              appCtx?.algoencrypt || ""
            );
            decryptedData =
              bytes.toString(CryptoJS?.enc?.Utf8) &&
              JSON.parse(bytes.toString(CryptoJS?.enc?.Utf8));
            setDecryptedData(decryptedData);
          }
        }
      } catch {}
    }
    if (appCtx.blockchain !== "ALGORAND") {
      try {
        if (appCtx?.evmncrypt) {
          if (oldMnemonic?.length && oldMnemonic?.length > 1) {
            let ciphertext = CryptoJS?.AES?.encrypt(
              JSON.stringify(appCtx.evmmnemonic),
              appCtx?.evmncrypt || ""
            ).toString();
            let bytes = CryptoJS?.AES?.decrypt(
              ciphertext,
              appCtx?.encrypt || ""
            );
            decryptedData =
              bytes.toString(CryptoJS?.enc?.Utf8) &&
              JSON.parse(bytes.toString(CryptoJS?.enc?.Utf8));
            setDecryptedData(decryptedData);
          } else {
            let bytes = CryptoJS?.AES?.decrypt(
              appCtx.evmmnemonic || '""',
              appCtx?.evmncrypt || ""
            );
            decryptedData =
              bytes.toString(CryptoJS?.enc?.Utf8) &&
              JSON.parse(bytes.toString(CryptoJS?.enc?.Utf8));
            setDecryptedData(decryptedData);
          }
        }
      } catch {}
    }
  }, [appCtx.blockchain]);

  const retrieveAlgoWallet = async () => {
    if (decryptedData) {
      // let mnemonicCode = decryptedData?.join(" ");
      const account = algosdk.mnemonicToSecretKey(decryptedData);

      setAlgoWalletData(account);
    }
  };

  const retrieveEvmWallet = async () => {
    if (decryptedData) {
      const wallet = await ethers.Wallet.fromMnemonic(decryptedData);
      console.log("wallet", wallet);
      setEvmWalletData(wallet);
    }
  };

  useEffect(() => {
    if (appCtx.blockchain === "ALGORAND") {
      retrieveAlgoWallet();
    } else {
      retrieveEvmWallet();
    }
  }, [decryptedData, openModal]);

  return (
    <div>
      <Modal
        isOpen={openModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
        ariaHideApp={false}
        className={`${styles.popup} w-[600px]`}
      >
        <div className="w-100 d-flex justify-content-end m-2 position-relative">
          <div
            onClick={closeModal}
            className="btn btn-link position-absolute top-0 right-2"
            style={{ cursor: "pointer" }}
          >
            <i className="fa fa-times" style={{ fontSize: "20px" }}></i>
          </div>
        </div>
        {appCtx.custodial === true && (
          <div className="pt-2">
            <div className="text-lg  mb-3">
              <p className="font-w600 mb-1">Algorand Key:</p>{" "}
              {privateKeyData?.algorandKey}
            </div>
            <div className="text-lg  mb-1">
              <p className="font-w600  mb-1">EVM Key: </p>{" "}
              <p className="overflow-scroll">
                {privateKeyData?.evmKey?.split("")?.reverse()?.join("")}
              </p>
            </div>
          </div>
        )}

        {appCtx.custodial !== true && appCtx.blockchain === "ALGORAND" && (
          <div className="pb-10 px-10">
            <div className="text-lg mb-3">
              <p className="font-w600 mb-1">Passphrase:</p>{" "}
              <p>{decryptedData}</p>
            </div>
            <div className="text-lg overflow-scroll mb-1">
              <p className="font-w600">Private Key:</p>{" "}
              <p> {algoWalletData?.sk?.split("")?.reverse()?.join("")}</p>
            </div>
          </div>
        )}
        {appCtx.custodial !== true && appCtx.blockchain !== "ALGORAND" && (
          <div className="pb-10 px-10">
            <div className="text-lg mb-3">
              <p className="font-w600 mb-1">Passphrase:</p>{" "}
              <p> {decryptedData}</p>
            </div>
            <div className="text-lg overflow-scroll mb-1">
              <p className="font-w600">Private Key:</p>{" "}
              <p>
                {" "}
                {evmWalletData?.privateKey?.split("")?.reverse()?.join("")}
              </p>
            </div>
          </div>
        )}
      </Modal>
    </div>
  );
}

export default KeysModal;
