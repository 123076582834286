import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const HomeBannerCard = ({ expert }) => {
  const appCtx = useSelector((state) => state.app);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  };
  let bannerData = [
    {
      id: "0",
      image: "/images/TCBasket.png",
      title: "basket",
      nav: "crypto-basket",
    },
    {
      id: "1",
      image: "/images/TCFund.png",
      title: "fund",
      nav: "crypto-fund",
    },
    {
      id: "0",
      image: "/images/TCRefer.png",
      title: "Reward Points",
      nav: "",
    },
  ];

  const width = window.screen.width;
  let isMobileDevice = width < 600;

  return (
    <>
      <Carousel
        swipeable={true}
        draggable={false}
        showDots={false}
        responsive={responsive}
        infinite={true}
        autoPlay={true}
        transitionDuration={500}
        arrows={false}
      >
        {bannerData.map((item) => (
          <div
            key={item?.id}
            onClick={() => navigate(item?.nav)}
            style={{
              cursor: "pointer",
            }}
            className="mb-3 mt-0 mt-md-2"
          >
            <img
              src={item?.image}
              alt=""
              style={{ width: isMobileDevice ? "100%" : "98%" }}
            />
          </div>
        ))}
      </Carousel>
    </>
  );
};

export default HomeBannerCard;
