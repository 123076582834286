import React, { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faArrowTrendUp,
  faCopy,
  faFile,
  faGlobe,
} from "@fortawesome/free-solid-svg-icons";
import {
  faFacebook,
  faGithub,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import { CircularProgress, Skeleton, Stack } from "@mui/material";
import MainWrapper from "../../component/wrapper/MainWrapper";
import CryptoTokenLineChart from "./CryptoTokenLineChart";
import { usehandleBuyTaleCoin } from "../../hooks/handleBuyTaleCoin";
import { useWalletView } from "../../hooks/useWalletView";
import { useTailCoin } from "../../hooks/useTailCoin";
import { useCirclePayment } from "../../component/paymnetgateways/CircleCli/useCirclePayment";
import SendAlgosModal from "../../component/BuyAndSellAlgos/sendAlgos/SendAgosModal";
import PaymentStatusLoader from "../../component/paymentStatusLoader/PaymentStatusLoader";
import ModalPaymentSuccess from "../../component/modal-payment-success/ModalPaymentSuccess";
import SwapModal from "../../component/swap/SwapModal";
import TaleWalletOffRampModal from "../../component/TaleWalletOffRamp/TaleWalletOffRampModal";
import ModalPaymentFailure from "../../component/modal-payment-failure/ModalPaymentFailure";
import { Nav } from "react-bootstrap";
import TransactionsTablesCard from "../../component/Transactions/TransactionsTablesCard";
import axios from "axios";
import { getBlockchainAddressUrl, getPriceInDecimal } from "../../config";
import SocialButtons from "./SocialButtons";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { useMetamask } from "../../hooks/useMetamask";
import MetamaskSwapSuccessModal from "../../component/metamaskSwapSuccess/MetamaskSwapSuccessModal";
import SwapSuccessModal from "../../component/orderbook/SwapSuccessModal";
import TaleWalletOnRampModal from "../../component/TaleWalletOnRamp/TaleWalletOnRampModal";

export const TokenDetailsPage = () => {
  const appCtx = useSelector((state) => state.app);
  const navigate = useNavigate();
  const [openSendAlgosModal, setOpenSendAlgosModal] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);
  const [selectedHistoryTab, setSelectedHistoryTab] = useState("y");
  const [openTalewalletOnrampModal, setOpenTalewalletOnrampModal] =
    useState(false);
  const [openOfframpModal, setOpenOfframpModal] = useState(false);
  const [transaction, setTransaction] = useState([]);
  const [newTransaction, setNewTransaction] = useState([]);
  const [transactionLoading, setTransactionLoading] = useState(false);
  const [isAddressCopied, setIsAddressCopied] = useState(false);
  const [openTransferSuccessModal, setOpenTransferSuccessModal] =
    useState(false);
  const [transactionData, setTransactionData] = useState(null);

  const [isMetamaskSwapPending, setIsMetamaskSwapPending] = useState(null);
  const [isMetamaskSwapConfirming, setIsMetamaskSwapConfirming] =
    useState(null);
  const [isMetamaskSwapConfirmed, setIsMetamaskSwapConfirmed] = useState(null);
  const [metmaskTransactionHash, setMetmaskTransactionHash] = useState(null);
  const [openMetamaskSwapSuccessModal, setOpenMetamaskSwapSuccessModal] =
    useState(false);
  const [sendTokenId, setSendTokenId] = useState("");
  const [receiveTokenId, setReceiveTokenId] = useState("");
  const [tokenPriceLoading, setTokenPriceLoading] = useState(false);

  const [tokenData, setTokenData] = useState({});

  const { tokenid, tokenname } = useParams();
  const { switchChainandOpenModal } = useMetamask();

  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  // const { handleSaleCoin } = usehandleSaleCoin(appCtx);
  const {
    taleAmount,
    showTaleData,
    // showAssets,
    // getAssetsById,
    allCoin,
    allCoinPrice,
    openSwapModal,
    setopenSwapModal,
    openSellModal,
    setopenSellModal,
    getAllCoin,
    getAllCoinPrice,
    getUsersToken,
    usersToken,
    isCoinLoading,
    isCoinPriceLOading,
    getSingleTokenBalance,
  } = useWalletView();
  const {
    tokenDetails,
    getTokenDetailsbyId,
    getAllcoinprice,
    // allCoinPrice,
    getDescriptionData,
    descriptionData,
  } = useTailCoin();

  useEffect(() => {
    if (appCtx.walletAddress[0]?.address) {
      getAllCoinPrice();
      getUsersToken(tokenid);
      getTokenDetailsbyId(tokenid);
      getSingleTokenBalance(
        address,
        tokenid,
        setTokenData,
        setTokenPriceLoading
      );

      getDescriptionData(tokenid);
      getAllCoin(0, 60);
    }
  }, [appCtx.walletAddress, appCtx.blockchain, tokenname]);

  const {
    openModalPaymentFailure,
    openModalPaymentSuccess,
    setOpenModalPaymentFailure,
    setOpenModalPaymentSuccess,
    paymentStatusLoader,
    handleCirclePayment,
    getUserCardList,
    handleCountryCode,
  } = useCirclePayment();

  useEffect(() => {
    showTaleData();
  }, []);

  const address = appCtx.walletAddress.find(
    (addr) => addr.blockchain === appCtx.blockchain
  )?.address;

  const Data = [
    {
      id: 0,
      value: "1H",
      range: "h",
    },
    {
      id: 1,
      value: "1D",
      range: "d",
    },
    {
      id: 2,
      value: "7D",
      range: "w",
    },
    {
      id: 3,
      value: "1M",
      range: "m",
    },
    {
      id: 4,
      value: "3M",
      range: "3m",
    },
    {
      id: 5,
      value: "6M",
      range: "6m",
    },
    {
      id: 6,
      value: "1Y",
      range: "y",
    },
  ];

  let usersBalance =
    usersToken.length > 0
      ? usersToken?.find((token) => token?.token == tokenname)?.balance
      : 0;
  console.log("usersBalance", usersBalance);
  const tranInfo = async () => {
    setTransactionLoading(true);
    const address = appCtx.walletAddress.find(
      (addr) => addr.blockchain === appCtx.blockchain
    )?.address;

    let config = {
      method: "get",
      url: `${
        process.env.REACT_APP_URL_BLOCKCHAIN_SERVICE
      }/address/${address}/transaction/history?blockchain=${appCtx.blockchain}${
        tokenname ? `&token=${tokenname}` : ""
      }`,
      headers: {
        "X-Auth-Token": appCtx.authToken,
      },
    };

    axios
      .request(config)
      .then((response) => {
        console.log();
        setTransaction(response.data.content || []);
        setNewTransaction(response.data.content?.slice(0, 10) || []);
        setTransactionLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setTransaction([]);
        setTransactionLoading(false);
      });
  };
  useEffect(() => {
    tranInfo();
  }, []);

  const truncatedAddress = `${tokenDetails?.contractAddress?.substring(
    0,
    6
  )}.....${tokenDetails?.contractAddress?.substring(
    tokenDetails?.contractAddress?.length - 6
  )}`;

  const handleCopyAddress = (value) => {
    setIsAddressCopied(!isAddressCopied);
    navigator.clipboard.writeText(value);
  };

  let myBalance = allCoinPrice[tokenname]?.price * tokenData?.balance;

  return (
    <MainWrapper>
      <div class={`${appCtx?.isDarkMode ? "text-white" : ""}`}>
        <div class="container-fluid ">
          {isCoinLoading || isCoinPriceLOading ? (
            <div className="pt-[5%] w-75 mx-auto">
              {Array.from({ length: 12 }).map((_, index) => (
                <Skeleton
                  key={index}
                  variant="text"
                  sx={{
                    fontSize: index === 11 ? "2rem" : "1rem",
                  }}
                  className={
                    index === 2 || index === 9 || index === 10 || index === 11
                      ? "w-[60%] mx-auto mt-3"
                      : index === 3
                      ? "w-[70%] mx-auto mt-5"
                      : "w-[80%] mx-auto"
                  }
                  height={index === 3 ? 300 : undefined}
                />
              ))}
            </div>
          ) : (
            <div class="row">
              <div class="col-xl-12">
                <button
                  className="btn btn-primary mb-2"
                  onClick={() => navigate(-1)}
                >
                  <FontAwesomeIcon
                    icon={faArrowLeft}
                    style={{ fontSize: "12px" }}
                  />
                </button>
                <div class="w-100">
                  <div class="card coin-content">
                    <div class="card-header border-0 flex-wrap pb-0">
                      <div class="mb-xl-0 d-flex flex-row gap-4">
                        <div className="d-flex flex-column gap-2">
                          <h4 class="fs-20 font-w600 mb-0">
                            {tokenDetails?.tokenName}
                          </h4>
                          <span class="fs-14">{tokenDetails?.symbol}</span>
                        </div>
                      </div>
                      <div className="d-flex justify-content-center align-items-end gap-2 card-footer  border-0 pt-0">
                        {appCtx.isLoggedIn ? (
                          <button
                            onClick={() => {
                              setOpenTalewalletOnrampModal(true);
                            }}
                            type="button"
                            className="btn btn-success d-flex align-items-center justify-content-between"
                            style={{ flex: "1" }}
                          >
                            Buy
                            <FontAwesomeIcon
                              icon={faArrowTrendUp}
                              style={{
                                transform: "rotate(90deg)",
                              }}
                            />
                          </button>
                        ) : (
                          <button
                            onClick={() => {
                              setOpenTalewalletOnrampModal(true);
                            }}
                            type="button"
                            className="btn btn-success d-flex align-items-center justify-content-between"
                            style={{ flex: "1" }}
                          >
                            Login to buy
                            <FontAwesomeIcon
                              icon={faArrowTrendUp}
                              style={{
                                transform: "rotate(90deg)",
                              }}
                            />
                          </button>
                        )}

                        <button
                          onClick={() => {
                            if (appCtx?.custodial) {
                              showTaleData();
                              allCoin &&
                                allCoinPrice &&
                                usersToken &&
                                setOpenSendAlgosModal(true);
                            } else {
                              showTaleData();
                              switchChainandOpenModal(setOpenSendAlgosModal);
                            }
                          }}
                          type="button"
                          className="btn btn-warning  d-flex align-items-center justify-content-between"
                          style={{ flex: "1" }}
                        >
                          Send
                          <FontAwesomeIcon icon={faArrowTrendUp} />
                        </button>

                        <button
                          onClick={() => {
                            setOpenOfframpModal(true);
                          }}
                          type="button"
                          className="btn btn-danger  d-flex align-items-center justify-content-between"
                          style={{ flex: "1" }}
                        >
                          Sell
                          <FontAwesomeIcon icon={faArrowTrendUp} />
                        </button>

                        <button
                          onClick={() => {
                            setopenSwapModal(true);
                          }}
                          type="button"
                          className="btn btn-info  d-flex align-items-center justify-content-between"
                          style={{ flex: "1" }}
                        >
                          Swap
                          <FontAwesomeIcon icon={faArrowTrendUp} />
                        </button>
                      </div>
                    </div>
                    <div class="card-body"></div>
                  </div>
                </div>
              </div>

              {/*data & chart  */}
              <div className="col-xl-9">
                <div class="card coin-content">
                  <div class="card-body">
                    {/* top part  */}
                    <div class="d-flex align-items-center justify-content-between flex-wrap">
                      <div class="price-content">
                        <h4 class=" mb-1">{tokenDetails?.symbol}</h4>
                      </div>
                      <div class="card-body pt-2 ">
                        <ul class="nav nav-pills d-flex justify-content-start justify-content-md-end">
                          {Data?.map((item) => (
                            <li class="" role="presentation" key={item?.id}>
                              <button
                                onClick={() => {
                                  setSelectedHistoryTab(item?.range);
                                }}
                                class={`nav-link   ${
                                  selectedHistoryTab === item?.range
                                    ? "active"
                                    : ""
                                }`}
                                role="tab"
                                aria-selected="true"
                              >
                                {item?.value}
                              </button>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                    <div class="d-flex align-items-end justify-content-between flex-wrap mt-2">
                      <div class="price-content">
                        <span class="fs-14 d-block mb-1">Price</span>
                        <h4 class="font-w600  mb-0">
                          {allCoinPrice[tokenname]?.price
                            ? getPriceInDecimal(allCoinPrice[tokenname]?.price)
                            : "--"}
                        </h4>
                      </div>
                      <div class="price-content">
                        <span class="fs-14 d-block mb-1">24h change</span>
                        <h4
                          class={`font-w600 
                        ${
                          allCoinPrice[tokenname]?.priceChange > 0
                            ? "text-success"
                            : "text-danger"
                        } mb-0 `}
                        >
                          {allCoinPrice[tokenname]?.priceChange
                            ? allCoinPrice[tokenname]?.priceChange?.toFixed(4)
                            : "--"}
                        </h4>
                      </div>
                      <div class="price-content">
                        <span class="fs-14 d-block mb-1">Volume</span>
                        <h4 class="font-w600  mb-0">{"--"}</h4>
                      </div>
                      <div class="price-content">
                        <span class="fs-14 d-block mb-1">MarketCap</span>
                        <h4 class="font-w600  mb-0">
                          {allCoinPrice[tokenname]?.marketCap
                            ? parseInt(allCoinPrice[tokenname]?.marketCap)
                            : "--"}
                        </h4>
                      </div>
                    </div>

                    {/* graph part  */}

                    <div id="marketChart">
                      <CryptoTokenLineChart
                        selecteddate={selectedHistoryTab}
                        tokenid={tokenid}
                      />
                    </div>
                  </div>
                </div>
              </div>

              {/* details card  */}
              <div class="col-xl-3 col-sm-12">
                <div class="card trade-history">
                  <div class="card-header border-0 pb-3">
                    <h4 class="card-title">Details</h4>
                  </div>
                  <div
                    class="card-body "
                    style={{
                      maxHeight: "600px",
                      overflow: "auto",
                    }}
                  >
                    <div class="text-center">
                      <div class="profile-photo">
                        {tokenDetails?.tokenIcon === null ? (
                          <img
                            src="/images/basketCardimg.png"
                            className=""
                            style={{
                              width: "45px",
                              height: "45px",
                              borderRadius: "50%",
                            }}
                            class="img-fluid rounded-circle"
                            alt=""
                          />
                        ) : (
                          <img
                            src={tokenDetails?.tokenIcon}
                            className=""
                            style={{
                              width: "45px",
                              height: "45px",
                              borderRadius: "50%",
                            }}
                            class="img-fluid rounded-circle"
                            alt=""
                          />
                        )}
                      </div>
                      <h5 class="mt-4 mb-1">{tokenDetails?.symbol}</h5>
                      <h5 class="">{tokenDetails?.tokenName}</h5>

                      <h4>
                        1 {tokenDetails?.symbol} =$
                        {getPriceInDecimal(allCoinPrice[tokenname]?.price)}
                      </h4>

                      <p className="fs-10 font-w400">
                        {tokenDetails?.description}
                      </p>
                    </div>
                    {tokenDetails?.contractAddress && (
                      <>
                        <h5 className="font-semibold mb-1"> Contracts:</h5>
                        <div className="d-flex align-items-center gap-3">
                          <div
                            onClick={() => {
                              const blockchain = appCtx?.blockchain;
                              const blockchainLink = getBlockchainAddressUrl(
                                blockchain,
                                tokenDetails?.contractAddress
                              );

                              if (blockchainLink !== "") {
                                window.open(blockchainLink);
                              }
                            }}
                            className={`${
                              appCtx?.isDarkMode ? "" : "text-primary"
                            }`}
                            style={{
                              cursor: "pointer",
                              marginLeft: "5px",
                            }}
                          >
                            {truncatedAddress}
                          </div>

                          <div
                            onClick={() =>
                              handleCopyAddress(tokenDetails?.contractAddress)
                            }
                            style={{ cursor: "pointer" }}
                          >
                            {isAddressCopied ? (
                              <FontAwesomeIcon
                                icon={faCheck}
                                className=""
                                style={{
                                  fontSize: "16px",
                                  color: `${
                                    appCtx?.isDarkMode ? "white" : "black"
                                  }`,
                                }}
                              />
                            ) : (
                              <FontAwesomeIcon
                                icon={faCopy}
                                className=""
                                style={{
                                  fontSize: "16px",
                                  color: `${
                                    appCtx?.isDarkMode ? "white" : "black"
                                  }`,
                                }}
                              />
                            )}
                          </div>
                        </div>
                      </>
                    )}

                    {/* Official Links */}
                    <h5 className="font-semibold mb-2 mt-2">Official Links</h5>
                    <div className="">
                      <div className="d-flex flex-wrap gap-2">
                        {tokenDetails && tokenDetails?.website !== null && (
                          <SocialButtons
                            icon={faGlobe}
                            name={"Website"}
                            url={tokenDetails.website}
                          />
                        )}
                        {tokenDetails && tokenDetails?.whitepaper !== null && (
                          <SocialButtons
                            icon={faFile}
                            name={"Whitepaper"}
                            url={tokenDetails.whitepaper}
                          />
                        )}
                        {tokenDetails && tokenDetails?.github !== null && (
                          <SocialButtons
                            icon={faGithub}
                            name={"Github"}
                            url={tokenDetails.github}
                          />
                        )}
                      </div>
                    </div>
                    {/* Official Links */}
                    <h5 className="font-semibold mb-2 mt-2">Social Links</h5>
                    <div className="">
                      <div className="d-flex flex-wrap gap-2">
                        {tokenDetails && tokenDetails?.twitter !== null && (
                          <SocialButtons
                            icon={faTwitter}
                            name={"Twitter"}
                            url={tokenDetails.twitter}
                          />
                        )}
                        {tokenDetails && tokenDetails?.facebook !== null && (
                          <SocialButtons
                            icon={faFacebook}
                            name={"Facebook"}
                            url={tokenDetails.facebook}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* profile card  */}
              <div className="col-xl-6">
                <div class="card pb-0">
                  <div class="card-body">
                    <div className="">
                      {tokenDetails && usersToken && allCoinPrice && (
                        <>
                          <div className="d-flex flex-column justify-content-between">
                            <div>
                              <div className="d-flex flex-column">
                                <div className="d-flex gap-4">
                                  <div className="price-content mb-5">
                                    <span className="fs-14 d-block">
                                      My Balance
                                    </span>
                                    <h4 className="font-w600 mb-0">
                                      {" "}
                                      {appCtx.blockchain !== "ALGORAND" &&
                                        `$${
                                          myBalance
                                            ? myBalance?.toFixed(4)
                                            : "0"
                                        }`}
                                    </h4>
                                  </div>
                                  <div className="price-content mb-5">
                                    <span className="fs-14 d-block">
                                      Quantity
                                    </span>
                                    <h4 className="font-w600 mb-0">
                                      {" "}
                                      {tokenData?.balance?.toFixed(4) ||
                                        "0.00"}{" "}
                                      {tokenData?.token}
                                    </h4>
                                  </div>
                                </div>
                                <div className="d-flex justify-content-between align-items-start flex-grow-1">
                                  <div className="price-content">
                                    <span className="fs-14 d-block">
                                      My Investment
                                    </span>
                                    <h4 className="font-w600 mb-0">
                                      {tokenData?.investmentCurrency}{" "}
                                      {tokenData?.investment?.toFixed(4)}
                                    </h4>
                                  </div>

                                  <div className="price-content">
                                    <span className="fs-14 d-block mb-0">
                                      My Returns
                                    </span>
                                    <h4 className="font-w600">
                                      {myBalance - tokenData?.investment}
                                    </h4>
                                  </div>
                                  <div className="price-content">
                                    <span className="fs-14 d-block mb-0">
                                      My CAGR
                                    </span>
                                    <h4 className="font-w600">--%</h4>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                  <div className="d-flex justify-content-center align-items-end gap-2 card-footer  border-0 pt-0">
                    {appCtx.isLoggedIn ? (
                      <button
                        onClick={() => {
                          setOpenTalewalletOnrampModal(true);
                        }}
                        type="button"
                        className="btn btn-success d-flex align-items-center justify-content-between"
                        style={{ flex: "1" }}
                      >
                        Buy
                        <FontAwesomeIcon
                          icon={faArrowTrendUp}
                          style={{
                            transform: "rotate(90deg)",
                          }}
                        />
                      </button>
                    ) : (
                      <button
                        onClick={() => {
                          setOpenTalewalletOnrampModal(true);
                        }}
                        type="button"
                        className="btn btn-success d-flex align-items-center justify-content-between"
                        style={{ flex: "1" }}
                      >
                        Login to buy
                        <FontAwesomeIcon
                          icon={faArrowTrendUp}
                          style={{
                            transform: "rotate(90deg)",
                          }}
                        />
                      </button>
                    )}

                    <button
                      onClick={() => {
                        setOpenOfframpModal(true);
                      }}
                      type="button"
                      className="btn btn-danger  d-flex align-items-center justify-content-between"
                      style={{ flex: "1" }}
                    >
                      Sell
                      <FontAwesomeIcon icon={faArrowTrendUp} />
                    </button>
                  </div>
                </div>
              </div>

              {/* Transaction card  */}

              <div class="col-xl-6  col-sm-12 ">
                <div class="card ">
                  <div class="card-header border-0 pb-3">
                    <h4 class="card-title">Transaction</h4>
                  </div>
                  <>
                    <div class="col-lg-12">
                      <div class="card transaction-table">
                        <div class="card-body p-0">
                          {transactionLoading ? (
                            <div class="d-flex justify-content-center align-items-center card crypto-chart">
                              <CircularProgress
                                style={{
                                  color: "#BBFF00",
                                  fontSize: "10px",
                                  width: "25px",
                                  height: "25px",
                                }}
                                className="py-10"
                                fontSize="small"
                              />
                            </div>
                          ) : (
                            <>
                              {newTransaction?.length === 0 ? (
                                <div
                                  class="alert alert-danger alert-dismissible fade show"
                                  style={{
                                    width: "60%",
                                    margin: "50px auto",
                                    textAlign: "center",
                                  }}
                                >
                                  <svg
                                    viewBox="0 0 24 24"
                                    width="24"
                                    height="24"
                                    stroke="currentColor"
                                    stroke-width="2"
                                    fill="none"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    class="me-2 alert-icon"
                                  >
                                    <polygon points="7.86 2 16.14 2 22 7.86 22 16.14 16.14 22 7.86 22 2 16.14 2 7.86 7.86 2"></polygon>
                                    <line x1="15" y1="9" x2="9" y2="15"></line>
                                    <line x1="9" y1="9" x2="15" y2="15"></line>
                                  </svg>
                                  No Transaction Found
                                </div>
                              ) : (
                                <div class="tab-content" id="myTabContent1">
                                  <div
                                    class="tab-pane fade show active"
                                    id="Week"
                                    role="tabpanel"
                                    aria-labelledby="Week-tab"
                                  >
                                    <div class="table-responsive">
                                      <table class="table table-responsive-md">
                                        <thead>
                                          <tr>
                                            <th>TransactionHash</th>
                                            <th>Type</th>
                                            <th>Status($)</th>
                                            <th>Chain</th>
                                            <th>From</th>
                                            <th>To</th>
                                            <th>Amount</th>
                                          </tr>
                                        </thead>

                                        <tbody>
                                          {newTransaction?.map(
                                            (tran, index) => {
                                              return (
                                                <TransactionsTablesCard
                                                  key={index}
                                                  tran={tran}
                                                />
                                              );
                                            }
                                          )}
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      {openTalewalletOnrampModal && (
        <TaleWalletOnRampModal
          openModal={openTalewalletOnrampModal}
          setOpenModal={setOpenTalewalletOnrampModal}
          // amount={amount}
          // basketId={basketId}
          // tokenPercentArray={tokenPercentArray}
          // tokenArray={tokenArray}
          // basketBlockchain={basketBlockchain}
          symbol={tokenDetails?.symbol}
          isTokenDetailsPage={true}
        />
      )}

      {openSendAlgosModal && (
        <SendAlgosModal
          openModal={openSendAlgosModal}
          setOpenModal={setOpenSendAlgosModal}
          allCoin={allCoin}
          allCoinPrice={allCoinPrice}
          usersToken={usersToken}
          isTokenDetailsPage={true}
          tokenname={tokenname}
        />
      )}

      {paymentStatusLoader && (
        <PaymentStatusLoader openModal={paymentStatusLoader} />
      )}

      {openModalPaymentFailure && (
        <ModalPaymentFailure
          openModalPaymentFailure={openModalPaymentFailure}
          setOpenModalPaymentFailure={setOpenModalPaymentFailure}
        />
      )}
      {openModalPaymentSuccess && (
        <ModalPaymentSuccess
          openModalPaymentSuccess={openModalPaymentSuccess}
          setOpenModalPaymentSuccess={setOpenModalPaymentSuccess}
        />
      )}
      {openSwapModal && (
        <SwapModal
          openModal={openSwapModal}
          setOpenModal={setopenSwapModal}
          allCoinPrice={allCoinPrice}
          usersToken={usersToken}
          allCoin={allCoin}
          symbol={tokenDetails?.symbol}
          isTokenDetailsPage={true}
          setTransactionData={setTransactionData}
          setOpenTransferSuccessModal={setOpenTransferSuccessModal}
          isMetamaskSwapPending={isMetamaskSwapPending}
          setIsMetamaskSwapPending={setIsMetamaskSwapPending}
          isMetamaskSwapConfirming={isMetamaskSwapConfirming}
          setIsMetamaskSwapConfirming={setIsMetamaskSwapConfirming}
          isMetamaskSwapConfirmed={isMetamaskSwapConfirmed}
          setIsMetamaskSwapConfirmed={setIsMetamaskSwapConfirmed}
          metmaskTransactionHash={metmaskTransactionHash}
          setMetmaskTransactionHash={setMetmaskTransactionHash}
          setOpenMetamaskSwapSuccessModal={setOpenMetamaskSwapSuccessModal}
          sendTokenId={sendTokenId}
          setSendTokenId={setSendTokenId}
          receiveTokenId={receiveTokenId}
          setReceiveTokenId={setReceiveTokenId}
        />
      )}
      {openOfframpModal && (
        <TaleWalletOffRampModal
          openModal={openOfframpModal}
          setOpenModal={setOpenOfframpModal}
          // closeBuyModal={closeModal}

          allCoin={allCoin}
          allCoinPrice={allCoinPrice}
          // amount={amount}
          // basketId={basketId}
          // tokenPercentArray={tokenPercentArray}
          // tokenArray={tokenArray}
          // basketBlockchain={basketBlockchain}
          symbol={tokenDetails?.symbol}
          isTokenDetailsPage={true}
        />
      )}
      {openTransferSuccessModal && (
        <SwapSuccessModal
          openModal={openTransferSuccessModal}
          setOpenModal={setOpenTransferSuccessModal}
          transactionData={transactionData}
          isPerformingSwap={true}
        />
      )}

      {(isMetamaskSwapPending ||
        isMetamaskSwapConfirming ||
        metmaskTransactionHash) && (
        <MetamaskSwapSuccessModal
          openModal={openMetamaskSwapSuccessModal}
          setOpenModal={setOpenMetamaskSwapSuccessModal}
          isMetamaskSwapPending={isMetamaskSwapPending}
          isMetamaskSwapConfirming={isMetamaskSwapConfirming}
          isMetamaskSwapConfirmed={isMetamaskSwapConfirmed}
          metmaskTransactionHash={metmaskTransactionHash}
          setopenSwapModal={setopenSwapModal}
          sendTokenId={sendTokenId}
          receiveTokenId={receiveTokenId}
        />
      )}
    </MainWrapper>
  );
};
