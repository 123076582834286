export const nativeToken = {
  ALGORAND: "ALGO",
  ETHEREUM: "ETH",
  POLYGON: "MATIC",
  BSC: "BNB",
  SOLANA: "SOL",
  BASE: "BASE",
  AVALANCHE: "AVAX",
  NORDEK: "NRK",
  ALL: "",
};

export const getImageUrl = (key) => {
  switch (key) {
    case "ALGORAND":
      return "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQk46xK0rqOLKpXI1ExMlc2bKa0uasNE-4GgvQEcLK7JA&s";
    case "ETHEREUM":
      return "https://talewallet-testnet.s3.ap-southeast-1.amazonaws.com/coin-icon/eth.png";
    case "POLYGON":
      return "https://talewallet-testnet.s3.ap-southeast-1.amazonaws.com/coin-icon/matic.png";
    case "BSC":
      return "https://talewallet-testnet.s3.ap-southeast-1.amazonaws.com/coin-icon/bnb.png";
    case "SOLANA":
      return "https://talewallet-assets.s3.ap-southeast-1.amazonaws.com/token-icon/Solana.png";
    case "BASE":
      return "https://images.mirror-media.xyz/publication-images/cgqxxPdUFBDjgKna_dDir.png";
    case "AVALANCHE":
      return "https://s2.coinmarketcap.com/static/img/coins/64x64/5805.png";
    case "NORDEK":
      return "https://s2.coinmarketcap.com/static/img/coins/64x64/24638.png";
    case "ALL":
      return "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQoaAqFzhkWggqDyTQK-CE_nawzAiB2MQku-A&s";
    default:
      return "";
  }
};
export const getChainId = (key) => {
  switch (key) {
    case "ALGORAND":
      return "";
    case "ETHEREUM":
      return 1;
    case "POLYGON":
      return 137;
    case "BSC":
      return 56;
    case "SOLANA":
      return 101;
    default:
      return "";
  }
};
export const nativeTokensTokenId = (key) => {
  switch (key) {
    case "ALGORAND":
      return "";
    case "ETHEREUM":
      return 1;
    case "POLYGON":
      return 137;
    case "BSC":
      return 56;
    case "SOLANA":
      return 101;
    default:
      return "";
  }
};

export function getPriceInDecimal(price) {
  let tokenprice;
  if (price >= 1000) {
    tokenprice = price?.toFixed(0);
  } else if (price >= 1) {
    tokenprice = price?.toFixed(2);
  } else if (price >= 0.1) {
    tokenprice = price?.toFixed(3);
  } else if (price >= 0.01) {
    tokenprice = price?.toFixed(4);
  } else if (price >= 0.001) {
    tokenprice = price?.toFixed(5);
  } else if (price >= 0.0001) {
    tokenprice = price?.toFixed(6);
  } else if (price >= 0.00001) {
    tokenprice = price?.toFixed(7);
  } else {
    tokenprice = price?.toFixed(8);
  }

  return tokenprice;
}
export function getBlockchainTransactionUrl(blockchain, transactionHash) {
  let url =
    blockchain === "FLOW"
      ? `${process.env.REACT_APP_GATEWAY_FLOWSCAN}${transactionHash}`
      : blockchain === "ALGORAND"
      ? `${process.env.REACT_APP_GATEWAY_ALGORAND}/tx/${transactionHash}`
      : blockchain === "ETHEREUM"
      ? `${process.env.REACT_APP_GATEWAY_ETHEREUM}/tx/${transactionHash}`
      : blockchain === "POLYGON"
      ? `${process.env.REACT_APP_GATEWAY_POLYGON}/tx/${transactionHash}`
      : blockchain === "BSC"
      ? `${process.env.REACT_APP_GATEWAY_BSC}/tx/${transactionHash}`
      : blockchain === "SOLANA"
      ? `${process.env.REACT_APP_GATEWAY_SOLANA}/${transactionHash}`
      : blockchain === "AVALANCHE"
      ? `${process.env.REACT_APP_GATEWAY_AVALANCHE}/blockchain/${blockchain}/tx/${transactionHash}`
      : blockchain === "NORDEK"
      ? `${process.env.REACT_APP_GATEWAY_NORDEK}/tx/${transactionHash}`
      : "";

  return url;
}
export function getBlockchainAddressUrl(blockchain, address) {
  let url =
    blockchain === "FLOW"
      ? `${process.env.REACT_APP_GATEWAY_FLOWSCAN}${address}`
      : blockchain === "ALGORAND"
      ? `${process.env.REACT_APP_GATEWAY_ALGORAND}/address/${address}`
      : blockchain === "ETHEREUM"
      ? `${process.env.REACT_APP_GATEWAY_ETHEREUM}/address/${address}`
      : blockchain === "POLYGON"
      ? `${process.env.REACT_APP_GATEWAY_POLYGON}/address/${address}`
      : blockchain === "BSC"
      ? `${process.env.REACT_APP_GATEWAY_BSC}/address/${address}`
      : blockchain === "SOLANA"
      ? `${process.env.REACT_APP_GATEWAY_SOLANA}/${address}`
      : blockchain === "AVALANCHE"
      ? `${process.env.REACT_APP_GATEWAY_AVALANCHE}/blockchain/${blockchain}/address/${address}`
      : blockchain === "NORDEK"
      ? `${process.env.REACT_APP_GATEWAY_NORDEK}/address/${address}`
      : "";

  return url;
}
