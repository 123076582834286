import React from "react";

const WeOffer = () => {
  return (
    <div class="row">
      <div class="col-xl-6  col-sm-12">
        <div class="card">
          <div class="card-header pb-0 border-0 flex-wrap">
            <div class="mb-0">
              <h4 class="card-title">What Do We Offer?</h4>
            </div>
          </div>
          <div class="card-body">
            <div>
              <p>Build expertise on crypto with help.</p>
              <p>
                Learn the fundamentals around blockchain technology and crypto
                industries with our tutorials.
              </p>
              <p>
                Get a deeper understanding of top 20 projects with our
                continuously evolving summary and updates on them. This covers
                the Whitepaper, Roadmap, Project deliverables, future timelines,
                Number of developers, Tokenomics, DeFi support, and more.
              </p>
              <p>Get weekly updates on the Macro and Crypto industry.</p>
              <p>
                Finally, keep yourself updated with the ever-updating technical
                analysis on the Industry and top projects.
              </p>
              <div className="d-flex">
                <div className="btn btn-primary px-12 py-3 text-sm font-medium active-text-primary hover-bg-transparent hover-text-primary focus-outline-none focus-ring">
                  <span className="text-xl font-weight-bold"> About Us</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-6  col-sm-12 ">
        <div className="row">
          <div class="col-xl-6  col-sm-12 ">
            <div class="card">
              <div class="card-header pb-0 border-0 flex-wrap">
                <div class="mb-0">
                  <h4 class="card-title">Crypto Fundamentals</h4>
                </div>
              </div>
              <div class="card-body">
                <div className="d-flex flex-md-row flex-wrap gap-y-2 justify-content-around w-100 md-w-60">
                  <div className="w-100 md-w-35 pt-15 md-pt-0">
                    <img
                      src="/images/cryptolens/offer1.jpg"
                      alt=""
                      className="w-100"
                    />
                    <p className="text-sm mt-3 font-light">
                      Our Crypto Fundamentals course makes understanding
                      Blockchain technology easy. You'll learn the basics of
                      Cryptography, Distributed Systems, and Consensus
                      Algorithms.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-6  col-sm-12 ">
            <div class="card">
              <div class="card-header pb-0 border-0 flex-wrap">
                <div class="mb-0">
                  <h4 class="card-title">Weekly Updates</h4>
                </div>
              </div>
              <div class="card-body">
                <div className="d-flex flex-md-row flex-wrap gap-y-2 justify-content-around w-100 md-w-60">
                  <div className="w-100">
                    <img
                      src="/images/cryptolens/offer2.jpg"
                      alt=""
                      className="w-100"
                    />
                    <p className="text-sm mt-3 font-light">
                      Get weekly updates on the Crypto Industry and Macro
                      Economics. Our team of experts will help you understand
                      the trends and current events that affect the Crypto
                      Assets market.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-6  col-sm-12 ">
            <div class="card">
              <div class="card-header pb-0 border-0 flex-wrap">
                <div class="mb-0">
                  <h4 class="card-title">Crypto Projects</h4>
                </div>
              </div>
              <div class="card-body">
                <div className="d-flex flex-md-row flex-wrap gap-y-2 justify-content-around w-100 md-w-60">
                  <div className="w-100">
                    <img
                      src="/images/cryptolens/offer3.jpg"
                      alt=""
                      className="w-100"
                    />
                    <p className="text-sm mt-3 font-light">
                      Stay up-to-date with project updates for top Crypto
                      Projects. Our courses cover projects like Bitcoin,
                      Ethereum, Ripple, and more.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-6  col-sm-12 ">
            <div class="card">
              <div class="card-header pb-0 border-0 flex-wrap">
                <div class="mb-0">
                  <h4 class="card-title">Technical Indicators</h4>
                </div>
              </div>
              <div class="card-body">
                <div className="d-flex flex-md-row flex-wrap gap-y-2 justify-content-around w-100 md-w-60">
                  <div className="w-100 md-w-35">
                    <img
                      src="/images/cryptolens/offer4.jpg"
                      alt=""
                      className="w-100"
                    />
                    <p className="text-sm mt-3 font-light">
                      Learn to interpret Technical Indicators and understand
                      what they say about the markets. Our team of experts will
                      provide regular commentary on this fast-evolving world of
                      Blockchain.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WeOffer;
