import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import moment from "moment";
import axios from "axios";
import { useSelector } from "react-redux";
import { Skeleton, Stack } from "@mui/material";

const CryptoFundAreaChart = ({ fundId, selectedRange }) => {
  const [chartData, setChartData] = useState([]);
  const [chartLoading, setChartLoading] = useState(false);
  const appCtx = useSelector((state) => state.app);

  useEffect(() => {
    setChartLoading(true);

    let config = {
      method: "get",
      url: `${process.env.REACT_APP_URL_BLOCKCHAIN_SERVICE}/crypto/fund/${fundId}/history?range=${selectedRange}`,
      headers: {
        ...(appCtx.authToken
          ? { "X-Auth-Token": appCtx.authToken }
          : { "X-App-Token": process.env.REACT_APP_X_APP_TOKEN }),
      },
    };

    axios
      .request(config)
      .then((response) => {
        const formattedData = response?.data
          ?.slice()
          .reverse()
          ?.map((item) => {
            if (item.createdDate !== null && item.tokenPrice !== null) {
              return {
                x: item.createdDate,
                y: item.tokenPrice,
              };
            }
          })
          .filter(Boolean);

        setChartData(formattedData);
        setChartLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setChartLoading(false);
      });
  }, [selectedRange]);
  console.log("chartdata", chartData);

  const options = {
    series: [
      {
        data: chartData,
      },
    ],
    chart: {
      height: 300,
      type: "area",
      toolbar: {
        show: false,
      },
    },
    colors: ["#7F00FF"],
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      width: 2,
    },
    legend: {
      show: false,
    },
    markers: {
      strokeWidth: 2,
      strokeColors: "#fff",
      hover: {
        size: 5,
      },
    },
    grid: {
      show: true,
      strokeDashArray: 6,
      borderColor: "#E0E0E0",
      xaxis: {
        lines: {
          show: true,
        },
      },
      yaxis: {
        lines: {
          show: true,
          numberOfTicks: 5,
        },
      },
    },
    yaxis: {
      show: true,
      labels: {
        style: {
          colors: "#333",
          fontSize: "12px",
          fontFamily: "Poppins",
          fontWeight: 400,
        },
        formatter: function (value) {
          return value?.toFixed(2);
        },
      },
      tickAmount: 5,
    },

    xaxis: {
      type: "datetime",
      tickAmount: 6,
      labels: {
        style: {
          colors: "#B5B5C3",
          fontSize: "12px",
          fontFamily: "Poppins",
          fontWeight: 400,
        },
        formatter: function (value) {
          return moment(value).format("D MMM");
          //  moment.unix(value).format("D MMM YYYY");
        },
      },
      axisBorder: {
        show: false,
      },
      tooltip: {
        enabled: false,
      },
    },
    tooltip: {
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        const dataPoint = w.config.series[seriesIndex].data[dataPointIndex];
        const date = moment(dataPoint.x).format("D MMM YYYY");
        const price = dataPoint.y.toFixed(2);

        return (
          '<div class="tooltip_box">' +
          '<div class="tooltip-data">' +
          '<span class="tooltip-date">' +
          date +
          "</span>" +
          '<span class="tooltip-price">' +
          price +
          " USD</span>" +
          "</div>" +
          "</div>"
        );
      },
    },
    fill: {
      type: "solid",
      opacity: 0,
    },
  };

  return (
    <div>
      {!chartLoading ? (
        <ReactApexChart
          options={options}
          series={options.series}
          type="area"
          height={options.chart.height}
        />
      ) : (
        <Stack className="w100 mx-auto">
          {/* For variant="text", adjust the height via font-size */}
          <Skeleton variant="text" sx={{ fontSize: "3rem" }} />
          <Skeleton variant="text" sx={{ fontSize: "3rem" }} />
          <Skeleton variant="text" sx={{ fontSize: "3rem" }} />
          <Skeleton variant="text" sx={{ fontSize: "3rem" }} />
          <Skeleton variant="text" sx={{ fontSize: "3rem" }} />
        </Stack>
      )}
    </div>
  );
};
export default CryptoFundAreaChart;
