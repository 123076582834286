import React, { useState } from "react";
import Modal from "react-modal";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import useAuthorizedHttp from "../../hooks/use-authorized-http";
import { toast } from "react-toastify";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { CircularProgress } from "@mui/material";

const SupportModal = ({ openModal, setOpenModal }) => {
  const appCtx = useSelector((state) => state.app);
  const navigate = useNavigate();
  const makeRequest = useAuthorizedHttp();
  const [email, setEmail] = useState("");
  const [query, setQuery] = useState("");
  const [userId, setUserId] = useState("");
  const [address, setAddress] = useState("");
  const [isQueryEmailSent, setIsQueryEmailSent] = useState(false);
  const [loading, setLoading] = useState(false);
  const sendEmail = () => {
    if (email && query && address) {
      if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
        setLoading(true);
        makeRequest(
          {
            url: `${process.env.REACT_APP_NFTVERSE_DEV_API}/internal/email/send`,
            data: {
              body: `${email} ${userId} ${address} ${query}`,
              email: "info@onnftverse.com",
              subject: "NFTVerse Info query Details",
            },
            method: "post",
            headers: {
              "X-App-Token": process.env.REACT_APP_X_APP_TOKEN,
              "Content-Type": "application/json",
            },
          },
          (data) => {
            setIsQueryEmailSent(true);
            setEmail("");
            setQuery("");
            setUserId("");
            setAddress("");
            toast("Your Query Mailed successfully!");
            setLoading(false);
          },
          (error) => {
            toast.error("Something went worng");
            setLoading(false);
          }
        );
      } else {
        toast.error("Incorrect Email Id");
      }
    } else {
      toast.error("Input field cannot be empty");
    }
  };
  const handleEmailChange = (emailId) => {
    setEmail(emailId);
  };
  const handleQueryChange = (queryText) => {
    setQuery(queryText);
  };
  const customStyles = {
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.7)",
      zIndex: 9998,
    },
    content: {
      position: "fixed",
      top: "50%",
      left: "50%",
      zIndex: 9999,
      backgroundColor: `${appCtx?.isDarkMode ? "#09153F" : "#FFFFFF"}`,

      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      border: `${appCtx?.isDarkMode ? "#09153F" : "#FFFFFF"}`,
      borderRadius: "10px",
      transform: "translate(-50%, -50%)",
      overflow: "hidden",
      width: "400px",
    },
  };
  function closeModal() {
    setOpenModal(false);
  }

  return (
    <Modal
      isOpen={openModal}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel="Example Modal"
      ariaHideApp={false}
    >
      <div className="">
        <Container className="d-flex flex-column justify-content-center align-items-center">
          <div className={`shadow-xl rounded-30px w-100`}>
            <div className="w-100">
              <img
                src="/images/Popup.svg"
                alt="logo"
                style={{ width: "100%" }}
              />
            </div>
            <div className="d-flex flex-column">
              <div className="d-flex flex-column ml-24 mr-24 mb-24">
                <div className="d-flex flex-column">
                  <div className="w-100 mb-9 text-left font-medium text-18px leading-8">
                    Your Email
                  </div>
                  <div className="mb-20">
                    <Form.Control
                      style={{
                        width: "100%",
                        height: "42px",
                        background: "rgba(187, 255, 0, 0.05)",
                        borderRadius: "15px",
                        paddingLeft: "20px",
                        color: "black",
                      }}
                      value={email}
                      placeholder="Enter your email here"
                      onChange={(e) => handleEmailChange(e.target.value)}
                    />
                  </div>
                </div>
                <div className="d-flex justify-between gap-3 w-100">
                  <div className="w-100">
                    <div className="w-100 mb-9 text-left font-medium text-18px leading-8">
                      Wallet Address
                    </div>
                    <div>
                      <Form.Control
                        style={{
                          width: "100%",
                          height: "42px",
                          background: "rgba(187, 255, 0, 0.05)",
                          borderRadius: "15px",
                          paddingLeft: "20px",
                          color: "black",
                        }}
                        placeholder="Enter wallet address"
                        onChange={(e) => {
                          setAddress(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div>
                  <div className="w-100 mb-9 text-left font-medium text-18px leading-8">
                    Your Query
                  </div>
                  <div className="mb-20">
                    <Form.Control
                      style={{
                        width: "100%",
                        height: "42px",
                        background: "rgba(187, 255, 0, 0.05)",
                        borderRadius: "15px",
                        paddingLeft: "20px",
                        color: "black",
                      }}
                      value={query}
                      onChange={(e) => handleQueryChange(e.target.value)}
                      placeholder="Enter your query here"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>

        <div class="w-100 d-flex justify-content-center">
          <div class="w-50 mt-2">
            {!isQueryEmailSent ? (
              <button
                onClick={sendEmail}
                class="mb-3 py-2 text-md font-weight-bold text-white bg-success border border-success rounded active:text-secondary focus:outline-none focus:ring w-100 d-flex align-items-center justify-content-center"
              >
                <p className="mb-0">Submit</p>
                {loading && (
                  <CircularProgress
                    style={{
                      color: "#BBFF00",
                      marginLeft: "5px",
                    }}
                    size={20}
                  />
                )}
              </button>
            ) : (
              <div className="d-flex">
                <CheckCircleOutlineIcon
                  style={{ color: "rgb(163, 202, 47)" }}
                />
                <div className="ml-10" style={{ color: "rgb(163, 202, 47)" }}>
                  We will notify you soon
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default SupportModal;
