import { faArrowDown, faArrowUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { appActions } from "../../context/app-slice";
import { red } from "@mui/material/colors";
import { useLocation } from "react-router-dom";
import { Line } from "peity-react";
import CryptoFundLineChart from "./CryptoFundLineChart";

const UsersCryptoFundCard = ({ item }) => {
  const appCtx = useSelector((state) => state.app);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  return (
    <div
      class="d-flex justify-content-between align-items-center gap-4 market-preview"
      style={{ cursor: "pointer" }}
      onClick={() => navigate(`/crypto-fund/${item?.fundId}`)}
    >
      <div class="w-100">
        <div class="row d-flex align-items-center">
          {/* left side  */}
          <div class="col-12 col-md-6">
            <div className="d-flex align-items-center justify-content-between py-4">
              <div class="d-flex align-items-center">
                <span>
                  <img
                    src={item?.icon}
                    alt=""
                    style={{
                      width: "36px",
                      height: "36px",
                      objectFit: "cover",
                      borderRadius: "50%",
                    }}
                  />
                </span>
                <div class="ms-3">
                  <h5 class="fs-14 font-w600 mb-0">
                    {item?.fundName && item?.fundName?.length > 25
                      ? `${item?.fundName.substring(0, 25)}...`
                      : item?.fundName}
                  </h5>
                  <h6 class="fs-12 font-w400">
                    by {item?.managedBy ? item?.managedBy : "--"}
                  </h6>
                </div>
              </div>
              <div class="d-flex  ">
                <div class="">
                  <p
                    className={`${
                      item?.cryptoFundStats?.cagr > 0
                        ? "text-success"
                        : "text-danger"
                    } mb-0 text-end fs-14 font-w600`}
                  >
                    {item?.cryptoFundStats?.cagr}%
                  </p>
                  <h5 class="fs-12 font-w400 mb-0  text-end">CAGR</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-6">
            {/* right side  */}
            <div className="d-flex align-items-center justify-content-between">
              <div className="col-xl-4 d-flex align-items-center justify-content-between">
                <div class="d-flex align-items-center gap-4">
                  <div
                    className="vertical-line-bg d-none d-md-block"
                    style={{
                      height: "40px",
                      width: "1px",
                    }}
                  ></div>
                  <div class="">
                    <h5
                      className={`fs-12 font-w400 mb-0 ${
                        item?.cryptoFundStats?.oneDayGrowth > 0
                          ? "text-success"
                          : "text-danger"
                      }  text-center fs-14 font-w600 `}
                    >
                      {item?.cryptoFundStats?.oneDayGrowth?.toFixed(2)}%
                    </h5>
                    <h6 class="fs-12 font-w400">One Day</h6>
                  </div>
                </div>
              </div>

              <div class="d-flex col-xl-4  ">
                <div class="w-100">
                  <p className={`text-center mb-0 fs-14 font-w600`}>
                    ${item?.totalInvestedAmount}
                  </p>
                  <h5 class="fs-12 font-w400 mb-0  text-center">
                    Total Invested
                  </h5>
                </div>
              </div>
              <div class="d-flex col-xl-4  ">
                <div class="w-100">
                  <p className={`text-center mb-0 fs-14 font-w600`}>
                    {item?.tokenQuantity?.toFixed(2)}
                  </p>
                  <h5 class="fs-12 font-w400 mb-0  text-center">
                    Token Quantity
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UsersCryptoFundCard;
