import axios from "axios";
import React, { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { nativeToken } from "../config";
import { appActions } from "../context/app-slice";
import AlgorandClient from "../component/services/algorandsdk";

export const useWalletView = () => {
  const [accountAsset, setAccountAsset] = useState([]);
  const [otheraccountAsset, setOtherAccountAsset] = useState([]);
  const [assetLoader, setAssetLoader] = useState(false);
  const [otherassetLoader, setOtherAssetLoader] = useState(false);
  const [amount, setAmount] = useState(0);
  const [assetUrl, setAssetUrl] = useState([]);
  const [optedIn, setOptIn] = useState(false);
  const [taleAmount, setTaleAmount] = useState(0);
  const [optInSuccessfull, setOptInSuccessfull] = useState(false);
  const [minBalance, setMinBalance] = useState(0);
  const [token, setToken] = useState(null);
  const [allCoin, setAllcoin] = useState([]);
  const [totalpage, setTotalpage] = useState(0);
  const [allCoinPrice, setAllcoinPrice] = useState({});
  const [usersToken, setUsersToken] = useState([]);
  const [isAssetLoading, setIsAssetLOading] = useState(false);
  const [isCoinLoading, setIsCoinLOading] = useState(false);
  const [isCoinPriceLOading, setIsCoinPriceLOading] = useState(false);
  const [singleTokenPriceLoading, setSingleTokenPriceLoading] = useState(false);
  const [singleTokenBalance, setSingleTokenBalance] = useState(null);
  const [openSwapModal, setopenSwapModal] = useState(false);
  const [openSellModal, setopenSellModal] = useState(false);
  const [managersBalance, setManagersBalance] = useState(null);
  const [listedToken, setListedToken] = useState([]);
  const [listedTokenLoading, setListedTokenLoading] = useState(false);

  const dispatch = useDispatch();

  const appCtx = useSelector((state) => state.app);

  const address = appCtx?.walletAddress?.find(
    (addr) => addr?.blockchain === appCtx?.blockchain
  )?.address;

  const defaultHeaders = {
    "X-App-Token": process.env.REACT_APP_X_APP_TOKEN,
    "Content-Type": "application/json",
  };

  if (appCtx.authToken !== "") {
    defaultHeaders["X-Auth-Token"] = appCtx.authToken;
  }

  const showAssets = async () => {
    setIsAssetLOading(true);
    const blockchainMap = nativeToken;
    const blockchaintoken = blockchainMap[appCtx.blockchain];
    let url;
    if (appCtx.blockchain === "SOLANA") {
      url = `${process.env.REACT_APP_URL_BLOCKCHAIN_SERVICE}/user/wallet/balance?blockchain=${appCtx.blockchain}&address=${address}`;
    } else {
      url = `${process.env.REACT_APP_URL_BLOCKCHAIN_SERVICE}/evm/wallet/${address}/token/${blockchaintoken}/balance?blockchain=${appCtx.blockchain}`;
    }

    // blockchaintoken && address;
    if (blockchaintoken && address) {
      // Axios request to get balance of a specific chain
      let config = {
        method: "get",
        url: url,
        headers: {
          "X-Auth-Token": appCtx.authToken,
          "Content-Type": "application/json",
        },
      };

      axios
        .request(config)
        .then((response) => {
          setAmount(response.data.balance);
          setMinBalance(response.data.minBalance);
          setToken(response.data.token);
        })
        .finally(() => setIsAssetLOading(false));
    }
  };

  const showTaleData = useCallback(async () => {
    if (appCtx.blockchain === "ALGORAND" && address) {
      let accountInfo = await AlgorandClient.accountInformation(address).do();
      setAmount(accountInfo.amount / 1000000);
      let array = [];

      // setAccountAsset(accountInfo["assets"]);
      if (accountInfo["assets"] === undefined) {
        // setAccountAsset([]);
        // document.getElementById('wallet_asset_div').innerHTML = '<div class="container" style="background-color:#f1f1f1; line-height: 2">No asset found</div>';
      } else {
        // console.log(accountInfo['assets'])
        var assetobj = accountInfo["assets"];
        let assetUrl = [];
        assetobj?.map((asset) => {
          array = [...array, { key: asset["asset-id"], amount: asset.amount }];
        });
        // console.log(array);
      }
      const isassetIdPresent = array?.filter((assets) => {
        return assets.key == process.env.REACT_APP_TAIL_COIN_TOKEN;
      });
      if (isassetIdPresent?.length > 0) {
        setTaleAmount(isassetIdPresent[0]?.amount / 100);
        // console.log(isassetIdPresent);
        setOptInSuccessfull(false);
        setOptIn(true);
      } else {
        setOptIn(false);
        // setOptInSuccessfull(false)
      }
    }
  }, [appCtx.walletAddress, optedIn, appCtx.blockchain]);
  const getAssetsById = () => {
    // for algorand chain
    if (appCtx.blockchain === "ALGORAND" && address) {
      setAssetLoader(true);
      let config = {
        method: "get",
        headers: {
          "Content-Type": "application/json",
          "X-Auth-Token": appCtx.authToken,
        },
        url: `${process.env.REACT_APP_URL_BLOCKCHAIN_SERVICE}/user/${address}/list`,
      };
      axios(config)
        .then((response) => {
          if (response.data?.content === undefined) {
            setAssetLoader(false);
            setAccountAsset([]);
            // document.getElementById('wallet_asset_div').innerHTML = '<div class="container" style="background-color:#f1f1f1; line-height: 2">No asset found</div>';
          } else {
            var c =
              '<div class="container" style="text-align: left;font-size:30px">Your Assets</div> <br />';
            // console.log(accountInfo['created-assets'])
            var assetobj = response.data?.content;
            let array = [];
            let assetUrl = [];
            for (const item in assetobj) {
              if (assetobj[item].params?.total == 1) {
                array = [
                  ...array,
                  {
                    key: assetobj[item].index,
                    value: assetobj[item].params?.url,
                    token: parseInt(
                      assetobj[item].params?.total?.length > 2 ? "10" : "1"
                    ),
                    name: assetobj[item].params?.name,
                    assetId: assetobj[item]?.assetId || "",
                    amount: assetobj[item]?.amount || 0,
                    params: assetobj[item]?.params,
                  },
                ];
              }
              assetUrl = [
                ...assetUrl,
                {
                  key: assetobj[item].index,
                  value: assetobj[item].params?.url,
                  token: parseInt(
                    assetobj[item].params?.total?.length > 2 ? "10" : "1"
                  ),
                  name: assetobj[item].params?.name,
                  assetId: assetobj[item]?.assetId || "",
                  amount: assetobj[item]?.amount || 0,
                  params: assetobj[item]?.params,
                },
              ];
            }
            setAccountAsset(array);
            setAssetUrl(assetUrl);
            setAssetLoader(false);
          }
        })
        .catch((error) => {
          setAssetLoader(false);
          if (error?.response?.status === 401) {
            dispatch(appActions.logout(undefined));
            dispatch(appActions.updateAuthToken(undefined));
            dispatch(appActions.setWalletAddress([{ address: "" }]));
            dispatch(
              appActions.updateUserDetails({
                userId: "",
                email: "",
                name: "",
              })
            );
            dispatch(appActions.setCountry(""));
            dispatch(appActions.setDocument(""));
            dispatch(appActions.setFrontSide(""));
            dispatch(appActions.setBackSide(""));
            dispatch(appActions.setPicture(""));
            dispatch(appActions.setVerified(false));

            dispatch(appActions.setPassword(""));
            dispatch(appActions.setRewardData({}));
            dispatch(appActions.setMetaData(""));
            dispatch(appActions.setCustodial(""));
            dispatch(appActions.setMnemonicCode('""'));
            dispatch(appActions.setAlgoMnemonicCode(""));
            dispatch(appActions.setEvmMnemonicCode(""));
            dispatch(appActions.setEncrypt(""));
            dispatch(appActions.setAlgoEncrypt(""));
            dispatch(appActions.setEvmEncrypt(""));
          }
        });
    }

    // for other chain asset
    if (appCtx.blockchain !== "ALGORAND") {
      let config = {
        method: "get",
        headers: {
          "Content-Type": "application/json",
          "X-Auth-Token": appCtx.authToken,
        },
        url: `${process.env.REACT_APP_URL_BLOCKCHAIN_SERVICE}/user/${appCtx.userDetails.userId}/nft/list?blockchain=${appCtx.blockchain}`,
      };
      axios(config).then((response) => {
        if (response.data?.content === undefined) {
          setOtherAssetLoader(false);
          setOtherAccountAsset([]);
        } else {
          setOtherAccountAsset(response.data?.content);
          setOtherAssetLoader(false);
        }
      });
    }
  };

  const handleRefresh = () => {
    setIsCoinLOading(true);
    let data = JSON.stringify({});

    let config = {
      method: "post",
      url: `${process.env.REACT_APP_URL_BLOCKCHAIN_SERVICE}/user/token/sync`,
      headers: {
        "X-Auth-Token": appCtx.authToken,
        "X-App-Token": process.env.REACT_APP_APP_TOKEN,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        setIsCoinLOading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getAllCoin = (
    page = 0,
    size = 60,
    blockchain = appCtx.blockchain || "BSC"
  ) => {
    setIsCoinLOading(true);
    let customHeader = {};
    if (appCtx.authToken != null) {
      customHeader = {
        "Content-Type": "application/json",
        "X-Auth-Token": appCtx.authToken,
      };
    } else {
      customHeader = {
        "Content-Type": "application/json",
        "X-App-Token": process.env.REACT_APP_X_APP_TOKEN,
      };
    }

    let config = {
      method: "get",
      url: `${process.env.REACT_APP_URL_BLOCKCHAIN_SERVICE}/token/list?blockchain=${blockchain}&page=${page}&size=${size}`,
      headers: customHeader,
    };
    axios
      .request(config)
      .then((response) => {
        const hasBlockchain = allCoin.some(
          (coin) => coin.blockchain === blockchain
        );
        if (!hasBlockchain) {
          setAllcoin([]);
        }

        setAllcoin((prevAllCoin) => [...prevAllCoin, ...response.data.content]);
        setTotalpage(response.data.totalPages);
        setIsCoinLOading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getAllCoinPrice = () => {
    setIsCoinPriceLOading(true);

    let customHeader = {};
    if (appCtx.authToken != null) {
      customHeader = {
        "Content-Type": "application/json",
        "X-Auth-Token": appCtx.authToken,
      };
    } else {
      customHeader = {
        "Content-Type": "application/json",
        "X-App-Token": process.env.REACT_APP_X_APP_TOKEN,
      };
    }

    let config = {
      method: "get",
      url: `${process.env.REACT_APP_URL_BLOCKCHAIN_SERVICE}/token/price?blockchain=${appCtx.blockchain}`,
      headers: customHeader,
    };
    axios
      .request(config)
      .then((response) => {
        setAllcoinPrice(response.data);
        setIsCoinPriceLOading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsCoinPriceLOading(false);
      });
  };
  const getUsersToken = () => {
    let config = {
      method: "get",
      url: `${process.env.REACT_APP_URL_BLOCKCHAIN_SERVICE}/user/token?blockchain=${appCtx.blockchain}`,
      headers: {
        "X-Auth-Token": appCtx.authToken,
      },
    };
    axios
      .request(config)
      .then((response) => {
        setUsersToken(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const showAllcoin = useCallback(async () => {
    getAllCoin();
    getAllCoinPrice();
    getUsersToken();
  }, [appCtx.walletAddress, appCtx.blockchain, optedIn]);

  const getFundManagersBalance = async () => {
    if (appCtx.blockchain === "ALGORAND" && address) {
      let accountInfo = await AlgorandClient.accountInformation(address).do();
      setManagersBalance(parseFloat(accountInfo.amount / 1000000));
    } else {
      const blockchainMap = nativeToken;
      const blockchaintoken = blockchainMap[appCtx.blockchain];
      let url;
      if (appCtx.blockchain === "SOLANA") {
        url = `${process.env.REACT_APP_URL_BLOCKCHAIN_SERVICE}/user/wallet/balance?blockchain=${appCtx.blockchain}&address=${address}`;
      } else {
        url = `${process.env.REACT_APP_URL_BLOCKCHAIN_SERVICE}/evm/wallet/${address}/token/${blockchaintoken}/balance?blockchain=${appCtx.blockchain}`;
      }

      // blockchaintoken && address;
      if (blockchaintoken && address) {
        // Axios request to get balance of a specific chain
        let config = {
          method: "get",
          url: url,
          headers: {
            "X-Auth-Token": appCtx.authToken,
            "Content-Type": "application/json",
          },
        };

        axios.request(config).then((response) => {
          setManagersBalance(response.data.balance);
          // setMinBalance(response.data.minBalance);
          // setToken(response.data.token);
        });
        // .finally(() => setIsAssetLOading(false));
      }
    }
  };

  const getListedTokens = () => {
    setListedTokenLoading(true);

    let config = {
      method: "get",
      url: `${process.env.REACT_APP_URL_BLOCKCHAIN_SERVICE}/listed/token/price?size=100`,
      headers: defaultHeaders,
    };

    axios
      .request(config)
      .then((response) => {
        if (response.data) {
          setListedToken(response.data);
        }
        setListedTokenLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setListedTokenLoading(false);
      });
  };
  const getSingleTokenBalance = (
    address,
    tokenId,
    setTokenData,
    setTokenPriceLoading
  ) => {
    setTokenPriceLoading(true);

    let config = {
      method: "get",
      url: `${process.env.REACT_APP_URL_BLOCKCHAIN_SERVICE}/wallet/${address}/token/${tokenId}/balance?blockchain=${appCtx?.blockchain}`,
      headers: defaultHeaders,
    };

    axios
      .request(config)
      .then((response) => {
        if (response.data) {
          setTokenData(response?.data);
        }
        setTokenPriceLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setTokenPriceLoading(false);
      });
  };
  const getSingleTokenBalanceBySymbol = (address, symbol) => {
    setSingleTokenPriceLoading(true);
    let url;
    if (appCtx?.blockchain === "SOLANA") {
      url = `${process.env.REACT_APP_URL_BLOCKCHAIN_SERVICE}/user/wallet/balance?blockchain=${appCtx?.blockchain}&address=${address}`;
    } else {
      url = `${process.env.REACT_APP_URL_BLOCKCHAIN_SERVICE}/evm/wallet/${address}/token/${symbol}/balance?blockchain=${appCtx?.blockchain}`;
    }

    let config = {
      method: "get",

      url: url,
      headers: defaultHeaders,
    };

    axios
      .request(config)
      .then((response) => {
        if (response.data) {
          setSingleTokenBalance(response?.data);
        }
        setSingleTokenPriceLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setSingleTokenPriceLoading(false);
      });
  };
   return {
     address,
     token,
     accountAsset,
     setAccountAsset,
     amount,
     setAmount,
     assetUrl,
     setAssetUrl,
     appCtx,
     // handleOptIn,
     showAssets,
     optedIn,
     taleAmount,
     showTaleData,
     setOptInSuccessfull,
     optInSuccessfull,
     // handleCustodialOptIn,
     minBalance,
     getAssetsById,
     assetLoader,
     showAllcoin,
     allCoin,
     allCoinPrice,
     usersToken,
     isAssetLoading,
     isCoinLoading,
     isCoinPriceLOading,
     otheraccountAsset,
     otherassetLoader,
     setIsAssetLOading,
     openSwapModal,
     setopenSwapModal,
     openSellModal,
     setopenSellModal,
     getAllCoin,
     getAllCoinPrice,
     getUsersToken,
     totalpage,
     handleRefresh,
     setAllcoinPrice,
     setIsCoinLOading,
     managersBalance,
     getFundManagersBalance,
     listedToken,
     listedTokenLoading,
     getListedTokens,
     singleTokenPriceLoading,
     singleTokenBalance,
     getSingleTokenBalance,
     getSingleTokenBalanceBySymbol,
   };
};
